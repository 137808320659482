import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		ping: '/ping',
		bootstrap: '/bootstrap',
		sso: '/login/${hash}'
	},
	post: {
		login: '/login',
		loginSecondary: '/login/secondary',
		loginDemo: '/demo/login',
		logout: '/logout',
		requestResetPassword: '/password/reset',
		resetPassword: '/password/reset/${hash}'
	}
}

export const authApi = buildApi('auth', routes)
