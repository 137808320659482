import Vue from 'vue'
import { without, flatten, compact } from 'lodash'
import { elementsApi } from '@/services/api/modules/clinician/elements'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { mapToMutation, merge, spread, setByKey, pushByKey, popByKey, unshiftByKey, toggleByKey, getKey, reset } from '@/store/helpers'
import { isStack, isExercise, parseNestedElements, copyPickers, copyToSetPickers, savePickers } from './helpers/elements'

const callApi = buildApiCaller(elementsApi)

/* State */

const state = () => ({
	...apiState,
	root: {},
	parameters: {},
	resources: {}
})

/* Getters */

const getters = {
	isStack: state => key => isStack(state.root[key].type),
	isExercise: state => key => isExercise(state.root[key].type),
	loading: state => key => state.root[key].apiPending,
	disabled: (state, getters, rootState, rootGetters) => key => {
		if (getters.isStack(key)) {
			if (state.root[key].type === 'linkedStack') return true
			if (state.root[key].type === 'teamStack' && rootState.profile.teams[rootState.exerciseBrowser.folderData[state.root[key].folderId].teamId].teamExercises !== 'readWrite') return true
			else if (state.root[key].type === 'orgStack' && rootState.profile.user.orgExercises !== 'readWrite') return true
			else return false
		} else if (getters.isExercise(key)) {
			if (
				state.root[key].type === 'linkedExercise' ||
				(state.root[key].type === 'stackExercise' && state.root[state.root[key].stackKey].type === 'linkedStack')
			) return true
			else if (
				state.root[key].type === 'teamExercise' &&
				rootState.profile.teams[rootState.exerciseBrowser.folderData[state.root[key].folderId].teamId].teamExercises !== 'readWrite'
			) return true
			else if (
				(state.root[key].type === 'stackExercise' && state.root[state.root[key].stackKey].type === 'teamStack') &&
				rootState.profile.teams[rootState.exerciseBrowser.folderData[state.root[state.root[key].stackKey].folderId].teamId].teamExercises !== 'readWrite'
			) return true				
			else if (
				(
					state.root[key].type === 'orgExercise' ||
					(state.root[key].type === 'stackExercise' && state.root[state.root[key].stackKey].type === 'orgStack')
				) &&
				rootState.profile.user.orgExercises !== 'readWrite'
			) return true
			else return false
		}
	},
	complete: state => keys => Array.isArray(keys) ? keys.every(key => state.root[key] && state.root[key].complete) : state.root[keys] && state.root[keys].complete,
	getParametersByExercise: (state, shallow = false) => key => state.root[key] && state.root[key].parameters ? state.root[key].parameters.map(parameterKey => shallow ? state.parameters[parameterKey] : Object.assign({}, state.parameters[parameterKey])): [],
	getResourcesByExercise: (state, shallow = false) => key => state.root[key] && state.root[key].resources ? state.root[key].resources.map(resourcesKey => shallow ? state.resources[resourcesKey] : Object.assign({}, state.resources[resourcesKey])) : [],
	getNestedExercise: (state, getters) => (key, pickers) => {
		const parameters = pickers.parameters(getters.getParametersByExercise(key))
		const resources = pickers.resources(getters.getResourcesByExercise(key))
		return Object.assign(pickers.root(state.root[key]), { parameters }, { resources })
	},
	getElementForSave: (state, getters) => key => {
		const type = state.root[key].type
		if (isExercise(type)) return getters.getNestedExercise(key, savePickers[type])
		else if (isStack(type)) return savePickers[type].root(state.root[key])
	},
	getElementsForCopy: (state, getters) => (keys, flattenStacks) => {
		let exercises = []
		const stacks = []
		const getType = key => state.root[key].type
		const getExercise = key => getters.getNestedExercise(key, copyPickers[getType(key)])
		const getStack = key => {
			const exercises = state.root[key].exercises.map(getExercise)
			return flattenStacks ? exercises : Object.assign(copyPickers[getType(key)].root(state.root[key]), { exercises })
		}
		const fn = key => {
			const type = getType(key)
			if (isExercise(type)) exercises.push(getExercise(key)) 
			else if (isStack(type) && flattenStacks) exercises = exercises.concat(getStack(key))
			else if (isStack(type)) stacks.push(getStack(key))
		}
		if (Array.isArray(keys)) keys.map(fn)
		else fn(keys)
		return { exercises, stacks }
	},
	getElementsForCopyToSet: (state, getters) => (keys) => {
		let exercises = []
		const getType = key => state.root[key].type
		const getExercise = key => getters.getNestedExercise(key, copyToSetPickers[getType(key)])
		const getStack = key => state.root[key].exercises.map(getExercise)
		const fn = key => {
			const type = getType(key)
			if (isExercise(type)) exercises.push(getExercise(key)) 
			else if (isStack(type)) exercises = exercises.concat(getStack(key))
		}
		if (Array.isArray(keys)) keys.map(fn)
		else fn(keys)
		return { exercises }
	},	
	getThumbnailUrl: (state, getters, rootState, rootGetters) => key => {
		let resourceKey
		if (state.root[key].thumbnailId) resourceKey = state.root[key].thumbnailId
		else if (state.root[key].resources && state.root[key].resources.length) resourceKey = state.resources[state.root[key].resources[0]].resourceId
		return resourceKey ? rootGetters['resources/getResource'](resourceKey) : null
	}
}

/* Mutations */

const mutations = {
	...apiMutations,

	spreadInRoot: spread('root'),
	spreadInResources: spread('resources'),
	spreadInParameters: spread('parameters'),

	mergeInRoot: merge('root'),

	setTitle: setByKey('root', 'title'),
	setInstructions: setByKey('root', 'instructions'),
	setThumbnail: setByKey('root', 'thumbnailId'),
	setFavourite: setByKey('root', 'favourite'),
	setExerciseParametersList: setByKey('root', 'parameters'),
	setExerciseResourcesList: setByKey('root', 'resources'),
	setStackExercisesList: setByKey('root', 'exercises'),
	prependStackExercisesList: unshiftByKey('root', 'exercises'),
	setFresh: setByKey('root', 'fresh'),
	toggleReflect: toggleByKey('root', 'reflect'),

	setParameter: setByKey('parameters'),
	setParameterTitle: setByKey('parameters', 'title'),
	setParameterValue: setByKey('parameters', 'value'),
	addParameterToExercise: pushByKey('root', 'parameters'),
	removeParameterFromExercise: popByKey('root', 'parameters'),

	setResource: setByKey('resources'),
	setResourceCaption: setByKey('resources', 'caption'),
	setResourceId: setByKey('resources', 'resourceId'),
	addResourceToExercise: pushByKey('root', 'resources'),
	removeResourceFromExercise: popByKey('root', 'resources'),

	reset: reset(state()),

	removeElements: (state, keys) => {
		const elementKeys = []
		let resourceKeys = []
		let parameterKeys = []
		const getExerciseKeys = key => {
			parameterKeys = parameterKeys.concat(state.root[key].parameters)
			resourceKeys = resourceKeys.concat(state.root[key].resources)
			elementKeys.push(key)
		}
		keys.forEach(key => {
			const type = state.root[key].type
			if (isExercise(type)) {
				getExerciseKeys(key)
				if (type === 'stackExercise') {
					const stackKey = state.root[key].stackKey
					state.root[stackKey].exercises = without(state.root[stackKey].exercises, key)
				}
			} else if (isStack(type)) {
				state.root[key].exercises.forEach(getExerciseKeys)
				elementKeys.push(key)
			}
		})
		parameterKeys.forEach(key => Vue.delete(state.parameters, key))
		resourceKeys.forEach(key => Vue.delete(state.resources, key))
		elementKeys.forEach(key => Vue.delete(state.root, key))
	},

	removeStackOrphans: state => {
		const stackExercises = compact(flatten(Object.values(state.root).map(element => element.exercises)))
		const orphanStackExercises = Object.keys(state.root).filter(key => state.root[key].type === 'stackExercise' && !stackExercises.includes(+key))
		orphanStackExercises.forEach(key => Vue.delete(state.root, key))
	},

	removeExerciseOrphans: state => {
		const parameters = compact(flatten(Object.values(state.root).map(element => element.parameters)))
		const resources = compact(flatten(Object.values(state.root).map(element => element.resources)))
		const orphanParameters = Object.keys(state.parameters).filter(key => !parameters.includes(key))
		const orphanResources = Object.keys(state.resources).filter(key => !resources.includes(key))
		orphanParameters.forEach(key => Vue.delete(state.parameters, key))
		orphanResources.forEach(key => Vue.delete(state.resources, key))
	},

	prune: (state, ignoreKey) => {
		const keys = Object.keys(state.root)
		keys.forEach(key => {
			if (+key === ignoreKey) return
			const element = state.root[key]
			if (isStack(element.type)) {
				if (ignoreKey && state.root[ignoreKey] && element.key === state.root[ignoreKey].stackKey) return
				Vue.delete(element, 'updated')
				if (element.exercises.length > 4) element.exercises.length = 4
				element.complete = false
			} else if (element.type === 'stackExercise') {
				if (element.stackKey === ignoreKey) return
				if (ignoreKey && state.root[ignoreKey] && element.stackKey === state.root[ignoreKey].stackKey) return
				Vue.delete(element, 'updated')
				Vue.delete(element, 'instructions')
				Vue.delete(element, 'parameters')
				if (element.resources.length > 1) element.resources.length = 1
				element.resources.forEach(resourceKey => Vue.delete(state.resources[resourceKey], 'caption'))
				element.complete = false
			}
		})
	},

	moveToFolder: (state, { ids, folder }) => {
		ids.forEach(id=> {
			state.root[id].folderId = folder.id 
			state.root[id].type = state.root[id].type.includes('Exercise') ? folder.type + 'Exercise' : folder.type + 'Stack'
			Vue.delete(state.root[id], 'stackId')
		})
	},

	moveToStack: (state, { ids, stackId }) => {
		ids.forEach(id=> {
			state.root[id].stackId = stackId
			state.root[id].type = 'stackExercise'
			Vue.delete(state.root[id], 'folderId')
		})
	},

	popStackExercisesList: (state, { stackId, exerciseIds }) => {
		const exercises = state.root[stackId].exercises
		state.root[stackId].exercises = without(exercises, ...exerciseIds)
	},

/*
	// aggressive pruning
	prune: (state, ignoreKey) => {
		const keys = Object.keys(state.root)
		keys.forEach(key => {
			if (+key === ignoreKey) return
			const element = state.root[key]
			if (isStack(element.type)) {
				if (ignoreKey && element.key === state.root[ignoreKey].stackKey) return
				Vue.delete(element, 'updated')
				if (element.exercises.length > 4) element.exercises.length = 4
			} else {
				if (element.type === 'stackExercise' && element.stackKey === ignoreKey) return
				if (element.type === 'stackExercise' && ignoreKey && element.stackKey === state.root[ignoreKey].stackKey) return
				Vue.delete(element, 'updated')
				Vue.delete(element, 'instructions')
				Vue.delete(element, 'parameters')
				element.resources.forEach(resourceKey => Vue.delete(state.resources[resourceKey], 'caption'))
				if (element.type === 'stackExercise' && element.resources.length > 1) element.resources.length = 1
			}
			element.complete = false
		})
	}
*/
}

/* Actions */

const actions = {

	parseElementsOver({ commit }, nestedElements) {
		const [elements, resources, parameters] = parseNestedElements(nestedElements)
		commit('spreadInRoot', elements)
		commit('spreadInResources', resources)
		commit('spreadInParameters', parameters)
		commit('removeExerciseOrphans')
		return Object.keys(elements)
	},

	parseElementsMerge({ commit }, nestedElements) {
		const [elements, resources, parameters] = parseNestedElements(nestedElements)
		commit('mergeInRoot', elements)
		commit('spreadInResources', resources)
		commit('spreadInParameters', parameters)
		commit('removeExerciseOrphans')
	},

	async save({ state, commit, getters, dispatch, rootState }, key) {
		if (state.root[key].type === 'stockExercise') {
			const { exercises } = getters.getElementsForCopy(key)
			const payload = [Object.assign(exercises.pop(), { type: 'customExercise', folderId: rootState.exerciseBrowser.userRootId })]
			let route
			if (state.root[key].favourite) {
				route = 'saveFavourites'
				dispatch('setFavourite', { key, value: false })
			} else {
				route = 'save'
			}
			const [err, result] = await callApi({ commit, route, payload })
			if (!err) {
				await dispatch('parseElementsOver', result[0])
//				dispatch('flash/showAction', 'copyPersonal', { root: true })
				return [null, result[0]]
			} else {
				return [err]
			}
		} else {
			const payload = getters.getElementForSave(key)
			const [err, result] = await callApi({ commit, route: 'update', payload, silent: ['userStack', 'teamStack'].includes(state.root[key].type) })
			if (!err) {
				commit('setFresh', { key, value: false })
				await dispatch('parseElementsMerge', result)
				return [null, result]
			} else {
				return [err]
			}
		}
	},

	async create({ commit, dispatch }, payload) {
		const [err, result] = await callApi({ commit, route: 'save', payload })
		if (!err) {
			await dispatch('parseElementsOver', result)
			return [null, result]
		} else {
			return [err]
		}
	},

	async createFavourites({ commit, dispatch }, payload) {
		const [err, result] = await callApi({ commit, route: 'saveFavourites', payload })
		if (!err) {
			await dispatch('parseElementsOver', result)
			return [null, result]
		} else {
			return [err]
		}
	},	

	async createInStack({ commit, dispatch }, { key, exercises }) {
		exercises.forEach(exercise => Object.assign(exercise, { type: 'customExercise', stackId: key }))
		const [err, result] = await dispatch('create', exercises)
		if (!err) {
			commit('prependStackExercisesList', { key, value: result.map(exercise => exercise.id) })
			return [null, result]
		} else {
			return [err]
		}
	},

	async destroy({ commit }, keys) {
		if (!Array.isArray(keys)) keys = [keys]
		commit('removeElements', keys)
		await callApi({ 
			commit, 
			route: 'destroy', 
			payload: { ids: keys.join(',') }, 
			silent: true 
		})
	},

	setFavourite({ commit }, { key, value }) {
		commit('setFavourite', { key, value })
		callApi({ 
			commit, 
			route: 'updateFavourite', 
			payload: { id: key, favourite: value }, 
			silent: true 
		})
	},

	moveStackExercise({ state, commit }, { stackKey, index }) {
		const list = state.root[stackKey].exercises
		const id = list[index]
		if (index === list.length - 1) {
			const targetId = list[index - 1]
			callApi({ 
				commit, 
				route: 'moveToRightOf', 
				payload: { id, targetId }, 
				silent: true 
			})
		} else {
			const targetId = list[index + 1]
			callApi({ 
				commit, 
				route: 'moveToLeftOf', 
				payload: { id, targetId }, 
				silent: true 
			})
		}
	},

	deleteParameter({ state, commit }, parameterKey) {
		const exerciseKey = state.parameters[parameterKey].exerciseKey
		commit('removeParameterFromExercise', {
			key: exerciseKey,
			value: parameterKey
		})
		commit('removeExerciseOrphans')
	},

	createParameter({ commit }, { exerciseKey, title, value }) {
		const key = getKey()
		const parameter = {
			key,
			exerciseKey,
			title: title || '',
			value: value || ''
		}
		commit('setParameter', {
			key,
			value: parameter
		})
		commit('addParameterToExercise', {
			key: exerciseKey,
			value: key
		})
	},

	deleteResource({ state, commit }, resourceKey) {
		const exerciseKey = state.resources[resourceKey].exerciseKey
		commit('removeResourceFromExercise', {
			key: exerciseKey,
			value: resourceKey
		})
		commit('removeExerciseOrphans')
	},

	createResource({ commit }, { exerciseKey, resourceId, caption }) {
		const key = getKey()
		const resource = {
			key,
			exerciseKey,
			resourceId,
			caption: caption || ''
		}
		commit('setResource', {
			key,
			value: resource
		})
		commit('addResourceToExercise', {
			key: exerciseKey,
			value: key
		})
		return resource
	},

	unsetThumbnail({ commit }, key) {
		commit('setThumbnail', {
			key,
			value: null
		})
	},

	setFresh({ state, commit }, key) {
		if (state.root[key] && !state.root[key].fresh) commit('setFresh', { key, value: true })
	},

	prune({ commit }, ignoreKey) {
		commit('prune', ignoreKey)
		commit('removeStackOrphans')
		commit('removeExerciseOrphans')
	},

	setTitle: mapToMutation('setTitle'),
	setInstructions: mapToMutation('setInstructions'),
	setExerciseParametersList: mapToMutation('setExerciseParametersList'),
	setExerciseResourcesList: mapToMutation('setExerciseResourcesList'),
	setStackExercisesList: mapToMutation('setStackExercisesList'),
	setParameterTitle: mapToMutation('setParameterTitle'),
	setParameterValue: mapToMutation('setParameterValue'),
	setResourceCaption: mapToMutation('setResourceCaption'),
	setResourceId: mapToMutation('setResourceId'),
	toggleReflect: mapToMutation('toggleReflect'),
	reset: mapToMutation('reset'),
}

export const elements = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})