import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/clients/outcomes/${id}',
		fetchResults: '/clients/outcomes/results/${id}',
		fetchHistory: '/clients/outcomes/${id}/history',
		stockExists: '/clients/${clientId}/outcomes/${outcomeType}/exists',
		customDefExists: '/clients/${clientId}/outcomes/custom/${defId}/exists'
	},
	post: {
		create: '/clients/outcomes/',
		createResult: '/clients/outcomes/${id}/result'
	},
	put: {
		update: '/clients/outcomes/${id}'
	},
	delete: {
		destroy: '/clients/outcomes/${id}'
	}
}

export const clientOutcomesApi = buildApi('clientOutcomes', routes, '/clinician')

