export const diff = (route, data) =>
	Object.keys(data)
		.filter(key => !route.includes('{' + key + '}'))
		.reduce((obj, key) => ({ ...obj, [key]: data[key] }), {})

export const toAsync = (axios, method, args) => 
	axios[method](...args)
		.then(apiResult => {
			const data = apiResult.data 
			return [null, data]
			/*
			try {
				return [null, JSON.parse(data)]
			} catch (err) {
				return [null, data]
			}
			*/
		})
		.catch(err => {
			if (err.response && err.response.status===503) window.location.href="https://simpleset.app/maintenance.html"
			if (err.message === 'Network Error') return [{ code: 'ERRCONNECTION' }, null]
			else if (err.message === 'Cancel Error') return [{ code: 'ERRCANCEL' }, null]
			else if (err.response && err.response.data) return [err.response.data, null]
			else return [err, null]
		})