import { pick } from 'lodash'
import { listState, listGetters, listMutations, listActions } from '@/store/modules/mixins/list'
import { set, setByKey, spread, merge, reset, mapToMutation, accumulate } from '@/store/helpers'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { clientsApi } from '@/services/api/modules/clinician/clients'
import { setsApi } from '@/services/api/modules/clinician/sets'
import { i18n } from '@/configuration'

const callClientsApi = buildApiCaller(clientsApi)
const callSetsApi = buildApiCaller(setsApi)

/* state */

const state = () => ({
	...listState,
	...apiState,
	sets: {},
	client: null,
	selected: null
})

/* getters */

const getters = { 
	...listGetters,
    topPageMeta: state => ({
        skip: 0,
        limit: state.limit,
        sort: state.sortColumn,
        sortDir: state.sortDirection,
        id: state.client
    }),
    nextPageMeta: state => ({
        skip: state.list.length,
        limit: state.limit,
        sort: state.sortColumn,
        sortDir: state.sortDirection,
        id: state.client
    }),
    title: state => key => { if (key) return state.sets[key].savename || state.sets[key].title || i18n.t('elements.labels.untitled') }
}

/* mutations */

const mutations = {
	...listMutations,
	...apiMutations,
	setClient: set('client'),
	spreadInSets: spread('sets'),
	mergeInSets: merge('sets'),
	setSavename: setByKey('sets', 'savename'),
	setEnabled: setByKey('sets', 'enabled'),
	toggleSelected: (state, key) => {
		if (state.selected === key) state.selected = null
		else state.selected = key
	},
	clearSelected: set('selected', null),
	resetSets: set('sets', {}),
	reset: reset(state())
}

/* actions */

const fetch = async (commit, payload) => {
	return await callClientsApi({ commit, route: 'fetchClientSetsByClient', payload, race: true })
}

const fetchTop = async ({ commit, dispatch }, payload) => {
	const [err, result] = await fetch(commit, payload)
	if (!err) {
		commit('resetList')
		const keys = await dispatch('parseNew', result.items)
		const meta = { total: result.total, search: state.search }
		return [null, { meta, keys }]
	} else {
		return [err]
	}
}

const fetchNext = async ({ commit, dispatch }, payload) => {
	const [err, result] = await fetch(commit, payload)
	if (!err) {
		const keys = await dispatch('parseNew', result.items)
		return [null, { keys }]
	} else {
		return [err]
	}
}

const actions = {
	...listActions(fetchTop, fetchNext),

	async rename({ commit }, { key, savename }) {
		const [err, result] = await callSetsApi({
			commit,
			route: 'updateSet',
			payload: { id: key, savename }
		})
		if (!err) {
			const { savename } = result
			commit('setSavename', { key, value: savename })
		}
	},

	async setEnabled({ state, commit }, { key, value }) {
		const payload = { state: value }
		let silent
		if (state.selected === key) {
			payload.ids = [state.selected]
			silent = false
		} else {
			payload.ids = [ key ]
			silent = true
		}
		const [err] = await callSetsApi({
			commit,
			route: 'enabled',
			payload,
			silent
		})
		if (!err) {
			const parsed = accumulate(payload.ids.map( id => ({ id, enabled: value })))
			commit('mergeInSets', parsed)
		}
	},

	parseNew({ commit }, sets) {
		const parsed = accumulate(sets)
		commit('spreadInSets', parsed)
		return sets.map(set => set.key)
	},
/*
	parseMerge({ commit }, { sets }) {
		const parsed = accumulate(sets)
		commit('mergeInSets', parsed)
		return sets.map(set => set.key)	
	},
*/
	setClient({ commit, dispatch }, key) {
		commit('setClient', key)
		dispatch('fetchTop')
	},

    setMeta: ({ commit }, meta) => {
        commit('setMeta', pick(meta, ['total', 'search', 'clientId']))
    },

	toggleSelected: mapToMutation('toggleSelected'),
	clearSelected: mapToMutation('clearSelected'),
	reset: mapToMutation('reset')
}

export const clientSets = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})