import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { setsApi } from '@/services/api/modules/clinician/sets'
import { set, reset, mapToMutation, accumulate } from '@/store/helpers'
import { parsePoints } from './helpers/setHistory'

const callApi = buildApiCaller(setsApi)

/* state */

const state = () => ({
    ...apiState,
    list: [],
    points: {},
    point: {},
    resources: {},
    selected: null
})

/* getters */

const getters = {
    point: state => {
        if (!state.point.elements) return false
        const elements = state.point.elements.map(element => {
            const parsed = { type: element.type, title: element.title, instructions: element.instructions }
            if (element.type === 'setExercise' || element.type==='setExerciseRef') {
                parsed.parameters = element.parameters.filter(parameter => parameter.title && parameter.value)
                parsed.resources = element.resources.map(resource => {
                    const url = resource.resourceId ? state.resources[resource.resourceId].url : null
                    return { caption: resource.caption, url }
                })
            }
            return parsed
        })
        return {
            id: state.point.id,
            created: state.point.created,
            title: state.point.title,
            instructions: state.point.instructions,
            elements
        }
    }
}

/* mutations */

const mutations = {
    ...apiMutations,
    setList: set('list'),
    setPoints: set('points'),
    setPoint: set('point'),
    setResources: set('resources'),
    setSelected: set('selected'),
    reset: reset(state())
}

/* actions */

const actions = {

    parseInPoints({ commit, dispatch }, result) {
        const points = parsePoints(result)
        const pointKeys = Object.keys(points)
        commit('setPoints', points)
        commit('setList', pointKeys)
        dispatch('select', pointKeys[0])
    },

    parseInPoint({ commit }, { items, resources }) {
        commit('setResources', accumulate(resources))
        commit('setPoint', items[0])
    },

    async fetchHistory({ rootState, commit, dispatch }, setKey) {
        const id = rootState.sets.root[setKey] ? rootState.sets.root[setKey].id : setKey // if clicking from designer or clients
        const payload = { id, resourceWidth: rootState.main.hiRes ? 300 : 150 }
        const [err, result] = await callApi({ commit, route: 'fetchHistory', payload })
        if (!err && result.saveHistory) {
            await dispatch('parseInPoints', result)
        //    if (result.saveHistory.length) dispatch('select', result.saveHistory[0].id)
        //    console.log(result)
        //    await dispatch('parseInPoint', result)
        }
    },

    async fetchHistoryPoint({ rootState, state, commit, dispatch }) {
        const payload = { id: state.points[state.selected].id, imgWidth: rootState.main.hiRes ? 300 : 150 }
        const [err, result] = await callApi({ commit, route: 'fetchHistoryPoint', payload })
        if (!err) {
            await dispatch('parseInPoint', result)
        }
    },

    async select({ dispatch }, key) {
        await dispatch('selectPoint', key)
        dispatch('fetchHistoryPoint')
    },

    selectPoint({ state, commit, dispatch }, key) {
        if (state.points[key].type === 'email') {
            dispatch('select', state.list[state.list.findIndex(val => val === key) + 1])
        } else if (state.selected !== key) {
            commit('setSelected', key)
        }
    },

    reset: mapToMutation('reset')
}

export const setHistory = () => ({
    namespaced: true,
    state: state(),
    getters,
    mutations,
    actions
})