<template>
<div class='resetPassword'>
	<transition name='fade'>
		<div v-if='success' :key='1'>{{$t('views.resetPassword.success')}}</div>
		<div v-else :key='2'>
			<TextInput v-model='token' ref='textInput' :placeholder='$t("views.resetPassword.phToken")' @submit='submit' />
			<div class='textButton primary' @click='submit'>{{$t('elements.buttons.reset')}}</div>
			<transition name='fade'><Loading v-show='apiPending' size='medium' /></transition>
		</div>
	</transition>
</div>
</template>

<script>
import { authApi } from '@/services/api/modules/auth'
import TextInput from '@/components/common/TextInput'
import Loading from '@/components/common/Loading'

export default {
	name: 'ResetPassword',
	components: { TextInput, Loading },
	data() {
		return {
			token: '',
			apiPending: false,
			shake: false,
			success: false
		}
	},
	methods: {
		async submit() {
			if (this.apiPending) return
			this.apiPending = true
			this.$refs.textInput.blur()
			const [err] = await authApi.touch('requestResetPassword', { token: this.token })	
			this.apiPending = false
			if (err) {
				this.token = ''
				this.$emit('err')
			} else {
				this.success = true
				setTimeout(() => this.$emit('success'), 3000)	
			}
		}
	},
	mounted() {
		this.$refs.textInput.focus()
	}
}
</script>

<style lang='scss'>
.resetPassword {
	width: 100%;
	height: 100%;
	position: relative;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		.textButton {
			position: relative;
			white-space: nowrap;
		}

		.loading {
			background: rgba($color-white, 0.7);
		}
	}
}
</style>