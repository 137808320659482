import Cookies from 'js-cookie'
import { pick } from 'lodash'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { headerFootersApi } from '@/services/api/modules/clinician/headerFooters'
import { set, setByKey, unset, reset, spread, mapToMutation, accumulate } from '@/store/helpers'
import { resourceDimensions } from '@/configuration/settings'

const callApi = buildApiCaller(headerFootersApi)

const state = () => ({
	...apiState,
	headerFooters: {},
	selected: null,
	editing: null,
	fetched: false
})

const getters = {
	list: state => Object.values(state.headerFooters).sort((a,b)=>a.index-b.index)
}

const mutations = {
	...apiMutations,
	set: set('headerFooters'),
	unset: unset('headerFooters'),
	spread: spread('headerFooters'),
	setHeaderLine1: setByKey('headerFooters', 'headerLine1'),
	setHeaderLine2: setByKey('headerFooters', 'headerLine2'),
	setHeaderLine3: setByKey('headerFooters', 'headerLine3'),
	setFooter: setByKey('headerFooters', 'footer'),
	setFresh: setByKey('headerFooters', 'fresh'),
	setLogo: setByKey('headerFooters', 'logo'),
	setSelected: set('selected'),
	setEditing: set('editing'),
	setFetched: set('fetched', true),
	reset: reset(state())
}

const actions = {
	
	async fetch({ state, rootState, commit, dispatch }) {
		const [err, result] = await callApi({
			commit,
			route: 'fetch',
			payload: { imgWidth: rootState.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small }
		})
		if (!err) {
			//console.log(result)
			result.items.forEach((item, index)=>item.index = index)
			commit('set', accumulate(result.items))
			commit('setFetched')
		}
	},

	async update({ state, commit }, key) {
		const [err, result] = await callApi({
			commit,
			route: 'update',
			payload: pick(state.headerFooters[key], ['id', 'headerLine1', 'headerLine2', 'headerLine3', 'footer'])
		})
		if (!err) {
			commit('setHeaderLine1', { key, value: result.headerLine1 })
			commit('setHeaderLine2', { key, value: result.headerLine2 })
			commit('setHeaderLine3', { key, value: result.headerLine3 })
			commit('setFooter', { key, value: result.footer })
			commit('setFresh', { key, value: false })
		}
	},

	async create({ commit }) {
		const [err, result] = await callApi({
			commit,
			route: 'create',
			payload: {}
		})
		if (!err) {
			commit('spread', accumulate(result))
			commit('setEditing', result.key)
		}
	},

	async destroy({ state, commit }, key) {
		const [err] = await callApi({
			commit,
			route: 'destroy',
			payload: { id: key }
		})
		if (!err) {
			if (state.selected === key) commit('setSelected', null)
			commit('unset', key)
		}
	},

	async unsetLogo({ commit }, key) {
		commit('setLogo', { key, value: null })
		await callApi({
			commit,
			route: 'unsetLogo',
			payload: { id: key },
			silent: true 
		})
	},

/*
	async create({ commit }) {

	}

	async update({ commit }, key) {

	}

	async destroy({ commit }, key) {
	
	}
*/
	setFresh({ commit }, key) {
		commit('setFresh', { key, value: true })
	},
	toggleSelected({ state, commit, rootState }, key) {
		commit('setSelected', state.selected === key ? null: key)
		Cookies.set(`${rootState.profile.user.id}-headerFooterId`, state.selected)
	},
	selectTeamHeader({ state, commit }) {
		const key = Object.values(state.headerFooters).find(headerFooter => headerFooter.type === 'teamHeaderFooter').key
		commit ('setSelected', key)
		//Cookies.set(`${rootState.profile.user.id}-headerFooterId`, state.selected)		
	},
	setHeaderLine1: mapToMutation('setHeaderLine1'),
	setHeaderLine2: mapToMutation('setHeaderLine2'),
	setHeaderLine3: mapToMutation('setHeaderLine3'),
	setFooter: mapToMutation('setFooter'),
	setLogo: mapToMutation('setLogo'),
	setEditing: mapToMutation('setEditing'),
	setSelected: mapToMutation('setSelected'),
	reset: mapToMutation('reset')
}

export const headerFooters = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})