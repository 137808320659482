import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/profile'
	},
	put: {
		save: '/profile/user',
		saveOrg: '/profile/org',
		changePassword: '/profile/user/password',

		close: '/clinician/profile/close',
		restartIntegration: '/clinician/profile/emr',
		integrate: '/clinician/profile/emr/${type}'
	},
	delete: {

		removeIntegration: '/clinician/profile/emr'

	}
}

export const profileApi = buildApi('profile', routes)

