import Cookies from 'js-cookie'
import { set, toggleList, reset, mapToMutation } from '@/store/helpers'
import { categoriesApi } from '@/services/api/modules/clinician/categories'

const state = (profileId) => ({
//    on: typeof Cookies.getJSON(`${profileId}-categoriesOn`) === 'boolean' ? Cookies.getJSON(`${profileId}-categoriesOn`) : true,
    selected: null,
    open: Cookies.getJSON(`${profileId}-categoriesOpen`) || [],
    tree: []
})

const getters = {
    open: state => key => state.open.includes(key),
    path: state => {
        const fn = (id, tree) => {
            if (tree.id === id) {
                let path = [{ id: tree.id, title: tree.title }]
                return path
            } else if (tree.subordinates) {
                for (let sub of tree.subordinates) {
                    let path = fn(id, sub)
                    if (Array.isArray(path)) {
                        if (tree.title) path.unshift({ id: tree.id, title: tree.title })
                        return path
                    }
                }
            }
        }
        return fn(state.selected, { subordinates: state.tree })
    }
}

const mutations = {
    setTree: (state, tree) => state.tree = tree,
    setOn: state => state.on = true,
    setOff: state => state.on = false,
    setSelected: set('selected'),
    clearSearch: set('selected', null),
    toggleOpen: toggleList('open'),
    reset: reset(state())
}

const actions = {
    async fetch({ commit }) {
        const [err, tree] = await categoriesApi.touch('fetch')
        if (!err) {
            commit('setTree', tree.subordinates)
        }
    },
    setOn({ commit, rootState }) {
        commit('setOn')
        Cookies.set(`${rootState.profile.user.id}-categoriesOn`, true)
    },
    setOff({ commit, dispatch, rootState }) {
        commit('setOff')
        dispatch('setSelected', null)
        Cookies.set(`${rootState.profile.user.id}-categoriesOn`, false)
    },
    toggleOpen({ state, commit, rootState }, value) {
        commit('toggleOpen', value)
        Cookies.set(`${rootState.profile.user.id}-categoriesOpen`, state.open)
    },
    setSelected({ state, commit }, payload) {
        if (state.selected !== payload) commit('setSelected', payload)
    },
    clearSearch: mapToMutation('clearSearch')
}

export const categories = (profileId) => ({
    namespaced: true,
    state: state(profileId),
    getters,
    mutations,
    actions
})