import createMutationsSharer from 'vuex-shared-mutations'

//const isClinician = type => ['professional', 'staff', 'student', 'demo'].includes(type)

export const crosstabs = createMutationsSharer({
	predicate: ({ type }) => 
		(
			(
				type.includes('main/') || 
				type.includes('auth/') 
			) && !type.includes('api')
		) 
})
