import Vue from 'vue'
import { flatten, compact } from 'lodash'
import { bulkParametersApi } from '@/services/api/modules/clinician/bulkParameters'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { parseNestedPresets } from './helpers/bulkParameterPresets'
import { set, unshift, spread, pop, reset, mapToMutation } from '@/store/helpers'

const callApi = buildApiCaller(bulkParametersApi)

const state = {
	...apiState,
	presets: {},
	parameters: {},
	list: []
}

/*
const state = parameters => {
	const [ presets, parameters ] = parseNestedPresets(parameters)
	return Object.assign({ ...apiState }, {
		list: Object.keys(presets),
		presets,
		parameters	
	})
}
*/

const getters = {
	getParametersForCopy: state => presetKey => {
		return state.presets[presetKey].parameters.map(key => {
			const { title, value } = state.parameters[key]
			return { title, value }
		})
	}
}

const mutations = {
	...apiMutations,

	setParameters: (state, parameters) => {
		const [ _presets, _parameters ] = parseNestedPresets(parameters)
		state.presets = _presets 
		state.parameters = _parameters
		state.list = Object.keys(_presets)
	},

	addParameters: spread('parameters'),
	addPresets: spread('presets'),
	unshiftList: unshift('list'),
	popList: pop('list'),
	setList: set('list'),
	reset: reset({}),

	removeOrphans: state => {
		// presets
		const orphanPresets = Object.keys(state.presets).filter(key => !state.list.includes(key))
		orphanPresets.forEach(key => Vue.delete(state.presets, key))
		// parameters
		const parameters = compact(flatten(Object.values(state.presets).map(preset => preset.parameters)))
		const orphanParameters = Object.keys(state.parameters).filter(key => !parameters.includes(key))
		orphanParameters.forEach(key => Vue.delete(state.parameters, key))
	}
}

const actions = {
    async fetch({ commit }) {
		const [err, result] = await callApi({ commit, route: 'fetch' })
        if (!err) {
            commit('setParameters', result)
        }
    },	
	async save({ commit }, parameters) {
		const [err, result] = await callApi({ commit, route: 'save', payload: { parameters } })
		if (!err) {
			const [ presets, parameters ] = parseNestedPresets([result])
			commit('addParameters', parameters)
			commit('addPresets', presets)
			commit('unshiftList', Object.keys(presets)[0])
		}
	},
	move({ state, commit }, index) {
		const id = state.presets[state.list[index]].id
		if (index === state.list.length - 1) {
			const targetId = state.presets[state.list[index - 1]].id
			callApi({
				commit,
				route: 'moveToRightOf',
				payload: { id, targetId },
				silent: true
			})
		} else {
			const targetId = state.presets[state.list[index + 1]].id
			callApi({
				commit,
				route: 'moveToLeftOf',
				payload: { id, targetId },
				silent: true
			})
		}
	},
	destroy({ state, commit }, presetKey) {
		const payload = { id: state.presets[presetKey].id }
		callApi({ route: 'destroy', payload })
		commit('popList', presetKey)
		commit('removeOrphans')
	},
	setList: mapToMutation('setList')
}

export const bulkParameterPresets = () => ({
	namespaced: true,
	state,
	getters,
	mutations,
	actions
})