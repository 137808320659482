import Vue from 'vue'

/* state */

export const apiState = {
	apiPending: false,
	apiError: null
}

/* mutations */

export const apiMutations = {
	apiStart(state, statePath) {
		const target = statePath || state
		Vue.set(target, 'apiPending', true)
		Vue.set(target, 'apiError', null)
	},
	apiSuccess(state, statePath) {
		const target = statePath || state
		Vue.set(target, 'apiPending', false)
		Vue.set(target, 'apiError', false)
	},
	apiError(state, { statePath, err }) {
		const target = statePath || state
		Vue.set(target, 'apiPending', false)
		Vue.set(target, 'apiError', {
			status: err.status || null,
			code: err.code || err.message || null,
			details: err.details || null
		})
	}
}

/* caller */

export const buildApiCaller = function(api) {
	return async function(options) {
		const { commit, route, payload, statePath, race, timeout, silent } = options
		if (!silent && commit) commit('apiStart', statePath)
		const [err, result] = await api.touch(route, payload, race, timeout, silent)
		if (!silent && err && err.code === 'ERRCANCEL') {
			return [err, result]
		} else if (!silent && commit && err) { 
			commit('apiError', { statePath, err })
		} else if (!silent && commit) {
			commit('apiSuccess', statePath)
		}
		return [err, result]
	}
}
