import Vue from 'vue'
import { flatten, compact, isEqual, throttle, cloneDeep } from 'lodash'
// import { i18n } from '@/configuration'
import { setsApi } from '@/services/api/modules/clinician/sets'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { setByKey, pushByKey, popByKey, spread, merge, reset, unset, mapToMutation, getKey, setKey, accumulate, toggleByKey } from '@/store/helpers'
import { parseNestedSets, parseNestedElements, parseFetchedDrafts, parseSetForSaveDraft, parseSetForSave, parseSavedDraft, anonymizeSet, markReadOnlyClientSets, copyPickers, savePickers, draftSavePickers, exportPickers, dupPickers, undoPickers } from './helpers/sets'
import { resourceDimensions } from '@/configuration/settings'

const callApi = buildApiCaller(setsApi)

/* State */

const state = () => ({
	...apiState,
	root: {},
	elements: {},
	parameters: {},
	resources: {},
	education: {},
	undos: {},
	redos: {}
})

/* Getters */

const getters = {

	getParametersByExercise: (state, shallow) => key => state.elements[key].parameters.map(parameterKey => shallow ? state.parameters[parameterKey] : Object.assign({}, state.parameters[parameterKey])),
	getResourcesByExercise: (state, shallow) => key => state.elements[key].resources.map(resourcesKey => shallow ? state.resources[resourcesKey] : Object.assign({}, state.resources[resourcesKey])),

	getExercise: (state, getters) => (key, pickers) => {
		const parameters = pickers && pickers.parameter ? pickers.parameter(getters.getParametersByExercise(key)) : getters.getParametersByExercise(key)
		const resources = pickers && pickers.resource ? pickers.resource(getters.getResourcesByExercise(key)) : getters.getResourcesByExercise(key)
		return pickers ? Object.assign(pickers.exercise(state.elements[key]), { parameters }, { resources }) : Object.assign({}, state.elements[key], { parameters }, { resources })
	},

	getDivider: state => (key, picker) => picker ? picker(state.elements[key]) : Object.assign({}, state.elements[key]),

	getElements: (state, getters) => (keys, pickers) => {
		return keys.map(key => {
			if (state.elements[key].type === 'setExercise') return getters.getExercise(key, pickers.exercise)
			else if (state.elements[key].type === 'setDivider') return getters.getDivider(key, pickers.divider)
		})
	},
	
	getElementsForCopy: (state, getters) => keys => {
		const elements = getters.getElements(keys, copyPickers)
		/*eslint-disable */
		const exercises = elements.filter(element => element.type === 'setExercise').map(({ type, ...keepAttrs }) => keepAttrs) 
		/*eslint-enable */
		return { elements, exercises }
	},

	getElementsForCopyToBrowser: (state, getters) => keys => {
		const elements = getters.getElements(keys, copyPickers)
		/*eslint-disable */
		const exercises = elements.filter(element => element.type === 'setExercise').map(({ type, stockId, customId, orig, ...keepAttrs }) => keepAttrs) 
		/*eslint-enable */
		return { elements, exercises }
	},	

	getElementsForUndo: (state, getters) => setKey => {
		return state.root[setKey].elements.map(key => {
			if (state.elements[key].type === 'setExercise') return getters.getExercise(key, undoPickers.exercise)
			else if (state.elements[key].type === 'setDivider') return getters.getDivider(key, undoPickers.divider)
		})
	},

	getSetForSaveDraft: (state, getters) => setKey => {
		const set = draftSavePickers.set(state.root[setKey])
		const education = (state.root[setKey].education || []).map(id => ({ id: state.education[id].id, type: state.education[id].type }))
		set.education = education		
		set.elements = getters.getElements(state.root[setKey].elements, draftSavePickers.element)
		parseSetForSaveDraft(set)
		return set
	},

	getSetForSave: (state, getters) => setKey => {
		const set = savePickers.set(state.root[setKey])
		const education = (state.root[setKey].education || []).map(id => ({ id: state.education[id].id, type: state.education[id].type }))
		set.education = education
		set.elements = getters.getElements(state.root[setKey].elements, savePickers.element)
		return set
	},

	getSetForExport: (state, getters) => (setKey, selected) => {
		const set = exportPickers.set(state.root[setKey])
		const education = (state.root[setKey].education || []).map(id => ({ id: state.education[id].id, type: state.education[id].type }))
		set.education = education
		if (selected) {
			set.elements = getters.getElements(state.root[setKey].elements.filter(e=>selected.includes(e)), exportPickers.element)
		} else {
			set.elements = getters.getElements(state.root[setKey].elements, exportPickers.element) 
		}
		return set
	},

	getSetForDup: (state, getters) => setKey => {
		const set = dupPickers.set(state.root[setKey])
		const education = (state.root[setKey].education || []).map(id => ({ id: state.education[id].id, type: state.education[id].type }))
		set.education = education
		set.elements = getters.getElements(state.root[setKey].elements, dupPickers.element)
		return set
	},	

	getThumbnailUrl: (state, getters, rootState, rootGetters) => key => {
		let resourceKey
		if (state.elements[key].thumbnailId) resourceKey = state.elements[key].thumbnailId
		else if (state.elements[key].resources.length) resourceKey = state.resources[state.elements[key].resources[0]].resourceId
		return resourceKey ? rootGetters['resources/getResource'](resourceKey) : null
	},

	getIds: state => compact(Object.values(state.root).map(set => set.id)),
	getDraftIds: state => compact(Object.values(state.root).map(set => set.draftId)),
	getKeyById: state => id => {
		const set = Object.values(state.root).find(set => set.id === id || set.draftId === id)
		return set ? set.key : false
	}
}

/* Mutations */

const mutations = {
	...apiMutations,

	/* undo */
	setUndoPoint: (state, { setKey, point }) => {
		if (!state.undos[setKey]) Vue.set(state.undos, setKey, [])
		if (state.undos[setKey].length >= 8) {
			Vue.set(state.undos, setKey, state.undos[setKey].slice(1).concat([point]))
		} else {
			Vue.set(state.undos, setKey, state.undos[setKey].concat([point]))
		}
	},
	setRedoPoint: (state, { setKey, point }) => {
		if (!state.redos[setKey]) Vue.set(state.redos, setKey, [])
		Vue.set(state.redos, setKey, state.redos[setKey].concat([point]))
	},
	popUndo: (state, setKey) => {
		Vue.set(state.undos, setKey, state.undos[setKey].slice(0, -1))
	},
	popRedo: (state, setKey) => {
		Vue.set(state.redos, setKey, state.redos[setKey].slice(0, -1))
	},
	clearRedos: (state, setKey) => {
		Vue.set(state.redos, setKey, [])
	},
	unsetUndos: unset('undos'),
	unsetRedos: unset('redos'),

	/* parse in */
	mergeInRoot: merge('root'),
	mergeInElements: merge('elements'),
/*
	mergeInElements: (state, obj) => {
		console.log(obj)
		Object.keys(obj).forEach(key => {
			Vue.set(state.elements, key, Object.assign({}, state.elements[key], obj[key]))
			if (!obj.stockId) Vue.delete(state.elements[key], 'stockId')
			if (!obj.customId) Vue.delete(state.elements[key], 'customId')
		})
	},
*/
	mergeInResources: merge('resources'),
	spreadInRoot: spread('root'),
	//spreadInElements: (state, obj) => Object.assign(state.elements, obj),
	//spreadInResources: (state, obj) => Object.assign(state.resources, obj),
	//spreadInParameters: (state, obj) => Object.assign(state.parameters, obj),

	spreadInEducation: (state, obj) => Object.assign(state.education, obj),

	spreadInElements: spread('elements'),
	spreadInResources: spread('resources'),
	spreadInParameters: spread('parameters'),

	/* root */
	setType: setByKey('root', 'type'),
	setSavename: setByKey('root', 'savename'),
	setFolderId: setByKey('root', 'folderId'),
	setSent: setByKey('root', 'sent'),
	setSynced: setByKey('root', 'synced'),
	setAlertSync: setByKey('root', 'alertSync'),
	setEducation: setByKey('root', 'education'),
	setTitle: setByKey('root', 'title'),
	setInstructions: setByKey('root', 'instructions'),
	setElementsList: setByKey('root', 'elements'),
	setDetail: setByKey('root', 'detail'),
	setFresh: setByKey('root', 'fresh'),
	setRestored: setByKey('root', 'restored'),
	setDraftSaving: setByKey('root', 'draftSaving'),
	setDraftStatus: setByKey('root', 'draftStatus'),
	pushElementsList: pushByKey('root', 'elements'),
	unsetRoot: unset('root'),
	removeElementsFromSet: popByKey('root', 'elements'),
	removeFolderId: (state, key) => Vue.delete(state.root[key], 'folderId'),
	removeClientId: (state, key) => {
		Vue.delete(state.root[key], 'clientId')
		Vue.delete(state.root[key], 'enabled')
	},
	removeDraftId: (state, key) => Vue.delete(state.root[key], 'draftId'),
	clearDetails: (state, ignore) => {
		Object.keys(state.root).forEach(key => {
			if (ignore !== key) Vue.set(state.root[key], 'detail', null)
		})
	},

	/* elements */
	setElementTitle: setByKey('elements', 'title'),
	setElementInstructions: setByKey('elements', 'instructions'),
	setExerciseParametersList: setByKey('elements', 'parameters'),
	addParameterToExercise: pushByKey('elements', 'parameters'),
	removeParameterFromExercise: popByKey('elements', 'parameters'),
	setExerciseResourcesList: setByKey('elements', 'resources'),
	addResourceToExercise: pushByKey('elements', 'resources'),
	removeResourceFromExercise: popByKey('elements', 'resources'),
	toggleReflect: toggleByKey('elements', 'reflect'),
	setThumbnail: setByKey('elements', 'thumbnailId'),

	/* parameters */
	setParameter: setByKey('parameters'),
	setParameterTitle: setByKey('parameters', 'title'),
	setParameterValue: setByKey('parameters', 'value'),

	/* resources */
	setResource: setByKey('resources'),
	setResourceCaption: setByKey('resources', 'caption'),
	setResourceId: setByKey('resources', 'resourceId'),

	/* reset */
	reset: reset(state()),

	resetDraft: (state, key) => {
		Vue.delete(state.root[key], 'draftId')
		state.root[key].elements.forEach(elementKey => {
			const element = state.elements[elementKey]
			Vue.delete(element, 'draftId')
			if (element.type === 'setExercise') element.resources.forEach(resourceKey => {
				Vue.delete(state.resources[resourceKey], 'draftId')
			})
		})
	},

	resetChildren: (state, key) => {
		state.root[key].elements.forEach(elementKey => {
			const element = state.elements[elementKey]
			Vue.delete(element, 'id')
			if (element.type === 'setExercise') element.resources.forEach(resourceKey => {
				Vue.delete(state.resources[resourceKey], 'id')
			})
		})
	},

	removeOrphans: state => {
		// elements
		const elements = compact(flatten(Object.values(state.root).map(rootSet => rootSet.elements)))
		const orphanElements = Object.keys(state.elements).filter(key => !elements.includes(key))
		orphanElements.forEach(key => Vue.delete(state.elements, key))
		// parameters
		const parameters = compact(flatten(Object.values(state.elements).map(element => element.parameters)))
		const orphanParameters = Object.keys(state.parameters).filter(key => !parameters.includes(key))
		orphanParameters.forEach(key => Vue.delete(state.parameters, key))
		// resources
		const resources = compact(flatten(Object.values(state.elements).map(element => element.resources)))
		const orphanResources = Object.keys(state.resources).filter(key => !resources.includes(key))
		orphanResources.forEach(key => Vue.delete(state.resources, key))
	}
}

/* Actions */

const saveDraftThrottles = {}

const saveDraft = (context, setKey) => {
	const fn = ({ state, getters, commit, dispatch }, setKey) => {
		if (
//			1 !== 1 && // disable for now
			state && 
			state.root &&
			state.root[setKey] && 
			state.root[setKey].fresh &&
			!state.root[setKey].draftSaving && 
			!state.root[setKey].apiPending //&& 
//			state.root[setKey].elements.length <= 50 // no biguns
		) {
			const draft = getters.getSetForSaveDraft(setKey)
			/* for v4 api strip cid from payload */
			const payload = cloneDeep(draft)
			if (!payload.id) payload.type = 'draftSet'
			delete payload.cid
			payload.elements.forEach(e => {
				delete e.cid 
				if (e.resources) e.resources.forEach(r => delete r.cid)
			})
			//console.log(JSON.parse(JSON.stringify(payload)))
			/* end */
//			parseSetForSave(payload)

/*
TO FUTURE TRAV
this is complicated
 
With the system in its current form
	If you add an exerciseRef to a program, then customize it, when the draft saves, it must then save the draft element as an exercise, not an exercise Ref
	But the original element is still an exerciseRef at this point, and has that id
	So we have to be careful with calculating what ids / types are being sent in the payload
	In addition to saving draftId, we also need to save the draftType, to calculate this
	eg { id (->refId), type: stockId / customId + orig = (aka exerciseRef), draftId (->exerciseId), draftType: exercise }
	so we parse the payload before sending, and on response, set a draftType on the element

In vue3 rewrite this problem will still exist, but since we will explicitly handling 3 types of elemnets (ref, exercise, divider) 
the code should be a little bit more clear about what it is doing

Still this is still not ideal
The real problem comes back to having to have ids on elements & resources (see issue on Linear re refactoring models to remove diffing)
If we can remove nested ids altogether then this becomes very simpler:
On a call to save or saveDraft, it just calculates the correct elements at that point in time and sends them -- there io no need to worry about what was previously sent.
This is the goal we should work towards.  

*/

			// these other functions are in helpers but at this point it's such a mess it may as well go here
			const getOrig = e => {
				const o = {
					thumbnailId: e.thumbnailId,
					title: e.title,
					instructions: e.instructions,
					resources: e.resources.map(r=>({ resourceId: r.resourceId, caption: r.caption }))
				}
				return JSON.stringify(o)
			}

			payload.elements.forEach(element => {
				if (
					element.type === 'setExercise' &&
					(element.draftType && element.draftType === 'exerciseRef') || // a previously draft saved exercise ref, may or not be a ref this save
					(!element.draftType && (element.stockId || element.customId)) // a previously not saved draft element , may or may not be a ref this save
				) {
					if (element.orig === getOrig(element)) {
						element.type = 'setExerciseRef'
						delete element.thumbnailId 
						delete element.title 
						delete element.instructions
						delete element.resources
						if (element.id) {
							delete element.stockId
							delete element.customId 				
						}
					} else { // was a ref, now an exercise
						delete element.id 
						delete element.stockId
						delete element.customId 					
					}
				} else if (element.type === 'setExercise') {
					delete element.stockId
					delete element.customId 
				}
				delete element.orig
				delete element.draftType
			})


			commit('setDraftSaving', { key: setKey, value: true })
			commit('setDraftStatus', { key: setKey, value: 'pending' })
			callApi({ route: payload.id ? 'updateSet' : 'createSet', payload, timeout: 1000 * 15, silent: true })
				.then(([err, result]) => {
					if (!state.root[setKey] || !state.root[setKey].draftSaving) return
					commit('setDraftSaving', { key: setKey, value: false })
					if (err) {
						if (err.code === 'ERROPERATION' || err.code === 'ERRPERMISSION') {
							// we should only get here if the draftIds or ids fall out of sync with the server
							// I *think* this can only happen if a user is doing stuff with the same login
							// across multiple windows / workstations
							// this resets all draftIds and the ids of all elements, which will cause everything to be overwritten on the next hit
							commit('resetDraft', setKey) 
							commit('resetChildren', setKey) 
						}
						commit('setDraftStatus', { key: setKey, value: 'error' })
					} else {
						/* for v4 api add cid to result from draft based on index */
						if (draft.cid) result.cid = draft.cid 
						result.elements.forEach((e,i) => {
							if (draft.elements[i].cid) e.cid = draft.elements[i].cid
							if (e.resources) e.resources.forEach((r,j)=> {
								if (draft.elements[i].resources[j].cid) r.cid = draft.elements[i].resources[j].cid
							})
						})
						//console.log('done')
						//console.log(JSON.parse(JSON.stringify(result)))
						/* end */
						commit('setDraftStatus', { key: setKey, value: 'success' })
						dispatch('parseSavedDraft', result)
					}
				})
		}
	}
	if (!saveDraftThrottles[setKey]) saveDraftThrottles[setKey] = throttle(fn, 1000 * 30, { leading: false, trailing: true })
	saveDraftThrottles[setKey](context, setKey)
}

const resourceWidth = rootState => rootState.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small

const actions = {

	parseSetsOver({ state, commit }, nestedSets) {
		if (!Array.isArray(nestedSets)) nestedSets = [nestedSets]
		/* begin parse out education -- ugly but it works for now */
		const education = {}
		nestedSets.forEach(item => {
			const ids = []
			if (item.education) {
				item.education.forEach(e => {
					let id
					if (e.type === 'stockEducation') {
						id = `stock-${e.id}`
					} else if (e.type === 'customEducationHistory') {
						id = `cust-${e.id}`
					}
					education[id] = { id: e.id, title: e.title || state.education[id].title, type: e.type }
					ids.push(id)				
				})
				item.education = ids
			} else {
				item.education = []
			}
		})
		commit('spreadInEducation', education)		
		/* end parse out education */	
		const [sets, elements, resources, parameters] = parseNestedSets(nestedSets)
		commit('spreadInRoot', sets)
		commit('spreadInElements', elements)
		commit('spreadInResources', resources)
		commit('spreadInParameters', parameters)
		return Object.keys(sets)
	},

	parseSetsMerge({ commit }, nestedSets) {
		if (!Array.isArray(nestedSets)) nestedSets = [nestedSets]
		const [sets, elements, resources, parameters] = parseNestedSets(nestedSets)
		commit('mergeInRoot', sets)
		commit('spreadInElements', elements)
		commit('spreadInResources', resources)
		commit('spreadInParameters', parameters)
	},

	parseElementsOver({ commit }, { nestedElements, setKey }) {
		const [elements, resources, parameters] = parseNestedElements(nestedElements, setKey)
		commit('spreadInElements', elements)
		commit('spreadInResources', resources)
		commit('spreadInParameters', parameters)
		return Object.keys(elements)		
	},

	parseSavedDraft({ commit }, draft) {
		//console.log(draft)
		const [sets, elements, resources] = parseSavedDraft(draft)
		//console.log(elements)
		//console.log('zah', elements)
		commit('mergeInRoot', sets)
		commit('mergeInElements', elements)
		commit('mergeInResources', resources)
	},
/*
	parseEducation({ commit }, items) {
		const education = {}
		items.forEach(item => {
			const ids = []
			item.education.forEach(e => {
				let id
				if (e.type === 'stockEducation') {
					id = `stock-${e.id}`
				} else if (e.type === 'customEducationHistory') {
					id = `cust-${e.id}`
				}
				education[id] = { id: e.id, title: e.title, type: e.type }
				ids.push(id)				
			})
			item.education = ids
		})
		commit('spreadInEducation', education)
	},
*/
	/* Api calls */

	async fetch({ commit, dispatch, rootState }, ids) {
		const payload = { ids, imgWidth: resourceWidth(rootState) }
		const [err, result] = await callApi({ commit, route: 'fetchIds', payload })
		if (!err) {
			const { items, resources, folders, clients } = result
			parseFetchedDrafts(items)
			//await dispatch('parseEducation', items)
			await dispatch('resources/parseResourcePreviews', resources, { root: true })
			if (folders && folders.length) {
				items.forEach(i=>{
					if (i.folderId) {
						const folder = folders.find(f=>f.id===i.folderId)
						if (
							folder.type==='linkedFolder' || 
							(folder.type==='orgFolder' && rootState.profile.user.orgTemplates!=='readWrite') ||
							(folder.type==='teamFolder' && rootState.profile.teams[folder.teamId].teamTemplates!=='readWrite')
						) i.readOnly = true 
					}
				})
			}
			if (clients && clients.length) {
				await dispatch('clients/parseMerge', clients, { root: true })
				markReadOnlyClientSets(clients, items)
			}
			const readOnly = !!items.find(set => set.readOnly)
			if (readOnly) dispatch('flash/showAlert', 'readOnlySet', { root: true })
			return [null, await dispatch('parseSetsOver', items)]
		} else {
			return [err, null]
		}
	},

	async fetchHistory({ commit, dispatch, rootState }, id ) {
		const payload = { id, imgWidth: resourceWidth(rootState) }
		const [err, result] = await callApi({ commit, route: 'fetchHistoryPoint', payload })
		if (!err) {
			const { items, resources } = result
			const item = items[0]
			await dispatch('resources/parseResourcePreviews', resources, { root: true })
			item.readOnly = true
			dispatch('flash/showAlert', 'historyPoint', { root: true })
			return [null, await dispatch('parseSetsOver', item)]
		} else {
			return [err, null]
		}
	},

	async save({ rootState, state, commit, dispatch }, { setKey, payload }) {
		const detail = state.root[setKey].detail
		/* for v4 api strip cid from payload */
		const preSave = cloneDeep(payload)
		delete payload.cid
		payload.elements.forEach(e => {
			delete e.cid 
			if (e.resources) e.resources.forEach(r => delete r.cid)
		})
		//console.log(JSON.parse(JSON.stringify(payload)))
		/* end */
		parseSetForSave(payload)
		let [err, result] = await callApi({ commit, route: payload.id ? 'updateSet' : 'createSet', payload, statePath: state.root[setKey] })
		//result = result[0]
		/* for v4 api add cid to result from preSave based on index */
		if (preSave.cid) result.cid = preSave.cid 
		result.elements.forEach((e,i) => {
			if (preSave.elements[i].cid) e.cid = preSave.elements[i].cid
			if (e.resources) e.resources.forEach((r,j)=> {
				if (preSave.elements[i].resources[j].cid) r.cid = preSave.elements[i].resources[j].cid
			})
		})

		//console.log('done')
		//console.log(JSON.parse(JSON.stringify(result)))
		/* end */		
		if (!err) {
			const draftId = state.root[setKey].draftId
			if (rootState.manageTemplates.selectedIds.includes(draftId)) await dispatch('manageTemplates/toggleSelectedId', draftId, { root: true })
			await dispatch('manageClientSets/clearSelected', null, { root: true })
			result.restored = false
			await dispatch('parseSetsMerge', [result])
			commit('resetDraft', setKey)
			if (detail) commit('setDetail', { key: setKey, value: detail })
			commit('removeOrphans')
			dispatch('flash/showAction', 'saved', { root: true })
			if (state.root[setKey].type === 'clientSet') dispatch('clients/updateClinician', state.root[setKey].clientId, { root: true })
			return [null, true]
		} else {
			return [err, null]
		}
	},

	async saveOldSet({ getters, dispatch }, setKey) {
		const payload = getters.getSetForSave(setKey)
		return await dispatch('save', { setKey, payload })
	},

	async copyToEmr({ state, commit }, setKey) {
		return await callApi({ commit, route: 'copyToEmr', payload: { id: state.root[setKey].id }, statePath: state.root[setKey] })
	},	

	async saveNewFolderSet({ state, getters, commit, dispatch }, { setKey, savename, folderId }) {
		const draftId = state.root[setKey].draftId
		const isSaved = !!state.root[setKey].id
		const payload = getters.getSetForSave(setKey)
		if (isSaved) anonymizeSet(payload)		
		payload.savename = savename
		payload.folderId = folderId
		payload.type = 'folderSet'
		const [err, result] = await dispatch('save', { setKey, payload })
		if (!err && isSaved) {
			commit('removeClientId', setKey)
			if (draftId) callApi({ // manually destroy the old draft if there is one
				route: 'destroy', 
				payload: { ids: draftId }
			})
		}
		return [err, result]		
	},

	async saveNewClientSet({ state, getters, commit, dispatch }, { setKey, savename, clientId, enabled }) {
		const draftId = state.root[setKey].draftId
		const isSaved = !!state.root[setKey].id
		const payload = getters.getSetForSave(setKey)
		if (isSaved) anonymizeSet(payload)
		payload.savename = savename
		payload.clientId = clientId
		payload.enabled = enabled
		payload.type = 'clientSet'
		const [err, result] = await dispatch('save', { setKey, payload })
		if (!err && isSaved) {
			commit('removeFolderId', setKey)
			if (draftId) callApi({ // manually destroy the old draft if there is one
				route: 'destroy', 
				payload: { ids: draftId }
			})
		}
		return [err, result]
	},

	async email({ state, commit, dispatch, rootState }, { setKey, email }) {
		const payload = Object.assign({
			id: state.root[setKey].id
		}, email)
		//if (rootState.headerFooters.selected) payload.headerFooterId = rootState.headerFooters.selected
		const [err, result] = await callApi({ commit, route: 'email', payload, statePath: state.root[setKey] })
		if (!err) {
			dispatch('flash/showAction', { 
				code: 'emailSent', 
				vars: { email: rootState.clients[state.root[setKey].clientId].email }
			}, { root: true })
			dispatch('clients/updateClinician', state.root[setKey].clientId, { root: true })
		} else if (err.code === 'ERRPERMISSION' && err.details === 'Daily max') {
			dispatch('flash/showAlert', 'dailyMax', { root: true })
		}
		return [err, result]
	},

	/* Reset All Drafts */

	resetDrafts({ state, commit }) {
		Object.keys(state.root).forEach(key => commit('resetDraft', key))
	},

	/* Undo-Redo */

	setUndoPoint({ state, commit, getters }, setKey) {
		const point = {
			list: [...state.root[setKey].elements],
			detail: state.root[setKey].detail,
			elements: getters.getElementsForUndo(setKey)
		}
		if (state.undos[setKey] && state.undos[setKey].length) {
			const lastPoint = state.undos[setKey].slice(-1)[0]
			if (!isEqual(lastPoint, point)) commit('setUndoPoint', { setKey, point })
		} else {
			commit('setUndoPoint', { setKey, point })
		}	
	},

	setRedoPoint({ state, commit, getters }, setKey ) {
		const point = {
			list: [...state.root[setKey].elements],
			detail: state.root[setKey].detail,
			elements: getters.getElementsForUndo(setKey)
		}
		commit('setRedoPoint', { setKey, point })
	},

	saveUndoPoint({ commit, dispatch }, setKey) {
		commit('clearRedos', setKey)
		return dispatch('setUndoPoint', setKey)
	},

	undo({ state, commit, dispatch }, setKey) {
		if (!state.undos[setKey] || !state.undos[setKey].length) return
		const point = state.undos[setKey].slice(-1)[0]
		dispatch('setRedoPoint', setKey)
			.then(() => dispatch('parseElementsOver', { nestedElements: point.elements, setKey }))
			.then(() => {
				commit('setElementsList', { key: setKey, value: point.list })
				commit('setDetail', { key: setKey, value: point.detail })
				commit('removeOrphans')
				commit('popUndo', setKey)
			})
	},

	redo({ state, commit, dispatch }, setKey) {
		if (!state.redos[setKey] || !state.redos[setKey].length) return
		const point = state.redos[setKey].slice(-1)[0]
		dispatch('setUndoPoint', setKey)
			.then(() => dispatch('parseElementsOver', { nestedElements: point.elements, setKey }))
			.then(() => {
				commit('setElementsList', { key: setKey, value: point.list })
				commit('setDetail', { key: setKey, value: point.detail })
				commit('removeOrphans')
				commit('popRedo', setKey)
			})
	},

	/* Root */

	createSet({ dispatch }) {
		return dispatch('parseSetsOver', {
			savename: '',
			title: '',
			instructions: '',
			elements: [],
			fresh: false
		}).then(keys => keys[0])
	},

	duplicateSet({ getters, dispatch, commit, state }, setKey) {
		const payload = getters.getSetForDup(setKey)
		anonymizeSet(payload)
		payload.savename = state.root[setKey].savename
		//payload.savename = '('+ i18n.t('elements.labels.copied') +') ' + (payload.savename || payload.title || '')
		return dispatch(
			'parseSetsOver', 
			Object.assign({}, payload)
		).then(keys => {
			const key =keys[0]
			commit('setFresh', { key, value: false })
			return key
		}) 
	},

	removeSet({ commit }, setKey) {
		commit('unsetRoot', setKey)
		commit('unsetUndos', setKey)
		commit('unsetRedos', setKey)
		commit('removeOrphans')
	},

	sortElements({ dispatch }, list) {
		dispatch('saveUndoPoint', list.key)
			.then(() => dispatch('setElementsList', list))
	},

	setFresh(context, key) {
		const { rootState, state, commit } = context
		if (state.root[key] && !state.root[key].fresh) {
			commit('setFresh', { key, value: true })
			commit('setRestored', { key, value: false })
		}
		if (
			rootState.profile.user.allowPHI && 
			rootState.profile.user.clinicianType !== 'demo' && 
			rootState.profile.user.clinicianType !== 'student'
		) saveDraft(context, key)
	},

	setSavename: mapToMutation('setSavename'),
	setTitle: mapToMutation('setTitle'),
	setDetail: mapToMutation('setDetail'),
	setInstructions: mapToMutation('setInstructions'),
	setElementsList: mapToMutation('setElementsList'),
	clearDetails: mapToMutation('clearDetails'),

	/* Elements */

	copyElements({ dispatch, commit }, { elements, exercises, setKey, index }) {
		const nestedElements = elements ? elements : exercises.map(exercise => ({ type: 'setExercise', ...exercise }))
		return dispatch('saveUndoPoint', setKey)
			.then(() => dispatch('parseElementsOver', { nestedElements, setKey }))
			.then(elementKeys => {
				commit('pushElementsList', { key: setKey, value: elementKeys, index: index !== -1 ? index: null })
				return elementKeys
			})
	},

	removeElements({ state, commit, dispatch }, { elementKeys }) {
		const setKey = state.elements[elementKeys[0]].setKey // we can assume all elements are in the same set
		dispatch('saveUndoPoint', setKey)
			.then(() => {
				commit('removeElementsFromSet', {
					key: setKey, 
					value: elementKeys
				})
				commit('removeOrphans')
			})
	},

	createExercise({ dispatch }, setKey) {
		const exercise = {
			type: 'setExercise',
			title: '',
			instructions: '',
			parameters: [],
			resources: [],
			reflect: false
		}
		return dispatch('copyElements', { elements: [exercise], setKey })
	},

	createDivider({ dispatch }, setKey) {
		const divider = {
			type: 'setDivider',
			title: '',
			instructions: ''
		}
		return dispatch('copyElements', { elements: [divider], setKey })
	},

	toggleDetail({ state, commit }, elementKey) {
		if (!state.elements[elementKey]) return
		const setKey = state.elements[elementKey].setKey
		if (state.root[setKey].detail === elementKey) commit('setDetail', { key: setKey, value: null })
		else commit('setDetail', { key: setKey, value: elementKey })
	},

	unsetThumbnail({ state, commit, dispatch }, exerciseKey) {
		dispatch('saveUndoPoint', state.elements[exerciseKey].setKey)
			.then(() => {
				commit('setThumbnail', {
					key: exerciseKey,
					value: null
				})
			})
	},

	setExerciseParametersList({ state, commit, dispatch }, list) {
		const setKey = state.elements[list.key].setKey
		dispatch('saveUndoPoint', setKey)
			.then(() => commit('setExerciseParametersList', list))
	},

	setExerciseResourcesList({ state, commit, dispatch }, list) {
		const setKey = state.elements[list.key].setKey
		dispatch('saveUndoPoint', setKey)
			.then(() => commit('setExerciseResourcesList', list))
	},

	toggleReflect({ state, commit, dispatch }, exerciseKey) {
		const setKey = state.elements[exerciseKey].setKey
		dispatch('saveUndoPoint', setKey)
			.then(() => commit('toggleReflect', exerciseKey))		
	},

	applyParameters({ state, commit, dispatch }, { exerciseKeys, parameters }) {
		const key = state.elements[exerciseKeys[0]].setKey
		dispatch('saveUndoPoint', key)
			.then(() => {
				exerciseKeys.forEach(exerciseKey => {
					if (state.elements[exerciseKey].type === 'setExercise') {
						const parsed = accumulate(setKey(parameters.map(parameter => Object.assign({ exerciseKey }, parameter))))
						commit('spreadInParameters', parsed)
						commit('setExerciseParametersList', { key: exerciseKey, value: Object.keys(parsed) })
					}
				})
				commit('removeOrphans')
			})
	},

	setElementTitle: mapToMutation('setElementTitle'),
	setElementInstructions: mapToMutation('setElementInstructions'),

	/* parameters */
	deleteParameter({ state, commit, dispatch }, parameterKey) {
		const exerciseKey = state.parameters[parameterKey].exerciseKey
		dispatch('saveUndoPoint', state.elements[exerciseKey].setKey)
			.then(() => {
				commit('removeParameterFromExercise', {
					key: exerciseKey,
					value: parameterKey
				})
				commit('removeOrphans')
			})
	},

	createParameter({ state, commit, dispatch }, { exerciseKey, title, value }) {
		dispatch('saveUndoPoint', state.elements[exerciseKey].setKey)
			.then(() => {
				const key = getKey()
				const parameter = {
					key,
					exerciseKey,
					title: title || '',
					value: value || ''
				}
				commit('setParameter', {
					key,
					value: parameter
				})
				commit('addParameterToExercise', {
					key: exerciseKey,
					value: key
				})
			})
	},

	setParameterTitle: mapToMutation('setParameterTitle'),
	setParameterValue: mapToMutation('setParameterValue'),

	/* resources */

	deleteResource({ state, commit, dispatch }, resourceKey) {
		const exerciseKey = state.resources[resourceKey].exerciseKey
		dispatch('saveUndoPoint', state.elements[exerciseKey].setKey)
			.then(() => {
				commit('removeResourceFromExercise', {
					key: exerciseKey,
					value: resourceKey
				})
				commit('removeOrphans')
			})
	},

	createResource({ state, commit, dispatch }, { exerciseKey, resourceId, caption }) {
		return dispatch('saveUndoPoint', state.elements[exerciseKey].setKey)
			.then(() => {
				const key = getKey()
				const resource = {
					key,
					exerciseKey,
					resourceId,
					caption: caption || ''
				}
				commit('setResource', {
					key,
					value: resource
				})
				commit('addResourceToExercise', {
					key: exerciseKey,
					value: key
				})
				return resource
			})
	},

	setResourceId({ state, commit, dispatch }, payload) {
		const exerciseKey = state.resources[payload.key].exerciseKey
		const setKey = state.elements[exerciseKey].setKey
		dispatch('saveUndoPoint', setKey)
			.then(() => commit('setResourceId', payload))
	},

	setResourceCaption: mapToMutation('setResourceCaption'),

}

export const sets = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})