'use strict'

import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import '@/registerServiceWorker'
import { i18n } from './configuration'
//import { EventBus } from './store/helpers'
import './registerServiceWorker'

//EventBus.$on('storeReady', () => 
	new Vue({
		store,
		router,
		i18n,
		render: h => h(App)
	}).$mount('#app')
//)
