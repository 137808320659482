import Cookies from 'js-cookie'
import { set, push, pop, reset, mapToMutation } from '@/store/helpers'

/* state */

const state = profileId => ({
	cvr: Cookies.get(`${profileId}-pdfCover`) || 'std',
	edu: Cookies.get(`${profileId}-pdfEducation`) || 'std',
	sum: Cookies.get(`${profileId}-pdfSummary`) || 'std',
	cmp: Cookies.get(`${profileId}-pdfComprehensive`) || 'std', 
	imgs: Cookies.get(`${profileId}-pdfImages`) || 'std', 
	trck: Cookies.getJSON(`${profileId}-pdfTracking`) || 7, 
	selected: Cookies.getJSON(`${profileId}-pdfSelected`) || ['sum']
})

/* getters */

const getters = {
	templates: state => {
		const templates = {}
		state.selected.forEach(t => {
			if (t === 'txt') templates.txt = true
			else templates[t] = state[t]
		})
		return templates
	}
}

/* mutations */

const mutations = profileId => ({
	setCover: set('cvr'),
	setEducation: set('edu'),
	setSummary: set('sum'),
	setComprehensive: set('cmp'),
	setImages: set('imgs'),
	setTracking: set('trck'),
	pushSelected: push('selected'),
	popSelected: pop('selected'),
	reset: reset(state(profileId))
})

/* actions */

const toggle = ({ state, commit, rootState }, value) => {
	if (state.selected.includes(value)) commit('popSelected', value)
	else commit('pushSelected', value)
	Cookies.set(`${rootState.profile.user.id}-pdfSelected`, state.selected)
}

const actions = {
	toggleCover(ctx) { toggle(ctx, 'cvr') },
	toggleEducation(ctx) { toggle(ctx, 'edu') },
	toggleSummary(ctx) { toggle(ctx, 'sum') },
	toggleComprehensive(ctx) { toggle(ctx, 'cmp') },
	toggleImages(ctx) { toggle(ctx, 'imgs') },
	toggleTracking(ctx) { toggle(ctx, 'trck') },
	toggleText(ctx) { toggle(ctx, 'txt') },

	setCover: ({ commit, rootState }, value) => {
		commit('setCover', value)
		Cookies.set(`${rootState.profile.user.id}-pdfCover`, value)
	},
	setEducation: ({ commit, rootState }, value) => {
		commit('setEducation', value)
		Cookies.set(`${rootState.profile.user.id}-pdfEducation`, value)
	},	
	setSummary: ({ commit, rootState }, value) => {
		commit('setSummary', value)
		Cookies.set(`${rootState.profile.user.id}-pdfSummary`, value)
	},
	setComprehensive: ({ commit, rootState }, value) => {
		commit('setComprehensive', value)
		Cookies.set(`${rootState.profile.user.id}-pdfComprehensive`, value)
	},
	setImages: ({ commit, rootState }, value) => {
		commit('setImages', value)
		Cookies.set(`${rootState.profile.user.id}-pdfImages`, value)
	},
	setTracking: ({ commit, rootState }, value) => {
		commit('setTracking', value)
		Cookies.set(`${rootState.profile.user.id}-pdfTracking`, value)
	},

	reset: mapToMutation('reset')
}

export const setPdf = profileId => ({
	namespaced: true,
	state: state(profileId),
	getters,
	mutations: mutations(profileId),
	actions
})