import { reset } from '@/store/helpers'
import { i18n } from '@/configuration'
import { fill } from '@/utilities'

const state = () => ({
	mode: null,
	message: null
})

const getters = {}

const mutations = {
	showError(state, message) {
		state.mode = 'error'
		state.message = message
	},
	showAlert(state, message) {
		state.mode = 'alert'
		state.message = message
	},
	showAction(state, message) {
		state.mode = 'action'
		state.message = message
	},
	showLogin(state, message) {
		state.mode = 'login'
		state.message = message
	},
	reset: reset(state())
}

const getStr = payload => {
	let str
	if (typeof payload === 'object') str = fill(i18n.t('views.flash.'+ payload.code), payload.vars)
	else str = i18n.t('views.flash.'+ payload)	
	if (str==='views.flash.'+ payload) str = payload
	return str
}

const actions = {
	showError({ commit }, payload) {
		commit('showError', getStr(payload))
	},
	showAlert({ commit }, payload) {
		commit('showAlert', getStr(payload))
	},
	showAction({ commit }, payload) {
		commit('showAction', getStr(payload))
	},
	showLogin({ commit }, payload) {
		commit('showLogin', getStr(payload))
	},
	reset({ commit }) {
		commit('reset')
	}
}

export const flash = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})