import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/',
		fetchInTeam: '/?teamId=${teamId}',
		fetchTotalClients: '${id}/clients/total'
	},
	post: {
		invite: '/pending',
		checkEmails: '/pending/emails',
		joinStaffTeam: '/${staffId}/team/${teamId}'			
	},
	put: {
		saveStaff: '/${staffId}',		
		saveStaffTeam: '/${staffId}/team/${teamId}',		
		changeTeam: '/${staffId}/team/${teamId}'
	},
	delete: {
		leaveStaffTeam: '/${staffId}/team/${teamId}'		
	}
}

export const staffApi = buildApi('staff', routes, '/manager/staff')

