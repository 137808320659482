<template>
<div v-if='disableTab' class='singleTab'><div>
	<header>SimpleSet is active in another tab.</header>
	<div class='textButton primary' @click='reload'>Reload in this Tab</div>
</div></div>
</template>

<script>
import { BroadcastChannel } from 'broadcast-channel'

export default {
	name: 'SingleTab',
	data() { return {
		channel: null,
		disableTab: false
	}},
	methods: {
		reload() {
			window.location.replace(window.location.pathname)
		}
	},
	mounted() {
		this.channel = new BroadcastChannel('simpleset')
		this.channel.postMessage('started')
		this.channel.onmessage = msg => {
			if (msg === 'started') this.disableTab = true
		}
	}
}
</script>

<style lang='scss'>
.singleTab {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;
	background: rgba(#000, 0.9);
	display: flex;
	align-items: center;
	justify-content: center;

	header { 
		color: $color-white;
		font-size: $size-font-large;
		font-weight: bold;
		text-shadow: 0 0 40px rgba(#000, 0.8);
	}

	.textButton {
		height: $size-control-height * 2;
		font-size: $size-font-large;
		margin-top: $size-gutter * 4;
		box-shadow: 0 0 40px rgba(#000, 0.3);
	}
}
</style>