import { pick } from 'lodash'
import { listState, listGetters, listMutations, listActions } from '@/store/modules/mixins/list'
import { set, setByKey, spread, reset, mapToMutation, accumulate } from '@/store/helpers'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { clientsApi } from '@/services/api/modules/clinician/clients'
import { clientOutcomesApi } from '@/services/api/modules/clinician/clientOutcomes'
import { i18n } from '@/configuration'

const callClientsApi = buildApiCaller(clientsApi)
const callOutcomesApi = buildApiCaller(clientOutcomesApi)

/* state */

const state = () => ({
	...listState,
	...apiState,
	outcomes: {},
	latest: [],
	client: null
})

/* getters */

const getters = { 
	...listGetters,
    topPageMeta: state => ({
        skip: 0,
        limit: state.limit,
        sort: state.sortColumn,
        sortDir: state.sortDirection,
        id: state.client
    }),
    nextPageMeta: state => ({
        skip: state.list.length,
        limit: state.limit,
        sort: state.sortColumn,
        sortDir: state.sortDirection,
        id: state.client
    }),
    title: state => key => i18n.t(`elements.labels.outcomes.${state.outcomes[key].type}`)
}

/* mutations */

const mutations = {
	...listMutations,
	...apiMutations,
	setClient: set('client'),
	setLatest: set('latest'),
	spreadInOutcomes: spread('outcomes'),
	setEnabled: setByKey('outcomes', 'enabled'),
	setClientStopped: setByKey('outcomes', 'clientStopped'),
	resetOutcomes: set('outcomes', {}),
	resetLatest: set('latest', []),
	reset: reset(state()),
	popLatest: (state, index) => state.latest.splice(index, 1)
}

/* actions */

const fetch = async (commit, payload) => {
	return await callClientsApi({ commit, route: 'fetchClientOutcomesByClient', payload, race: true })
}

const fetchTop = async ({ commit, dispatch }, payload) => {
	const [err, result] = await fetch(commit, payload)
	//console.log(result)
	if (!err) {
		commit('resetList')
		commit('resetLatest')
		if (result.latest) await commit('setLatest', result.latest)
		const keys = await dispatch('parseNew', result.items)
		const meta = { total: result.total }
		return [null, { meta, keys }]
	} else {
		return [err]
	}
}

const fetchNext = async ({ commit, dispatch }, payload) => {
	const [err, result] = await fetch(commit, payload)
	if (!err) {
		const keys = await dispatch('parseNew', result.items)
		return [null, { keys }]
	} else {
		return [err]
	}
}

const actions = {
	...listActions(fetchTop, fetchNext),

	async setEnabled({ commit }, { key, value }) {
		const [err] = await callOutcomesApi({
			commit, 
			route: 'update', 
			payload: { id: key, enabled: value } 
		})
		if (!err) {
			commit('setEnabled', { key, value })
			commit('setClientStopped', { key, value: false })
		}
	},

	async destroy({ state, commit }, key) {
		const [err] = await callOutcomesApi({
			commit, 
			route: 'destroy', 
			payload: { id: key } 
		})
		if (!err) {
			commit('popList', key)
			commit('decrementTotal', 1)
			const index = state.latest.findIndex(obj => obj.id === key)
			if (index !== -1) commit('popLatest', index)
		}
	},

	parseNew({ commit }, outcomes) {
		const parsed = accumulate(outcomes)
		commit('spreadInOutcomes', parsed)
		return outcomes.map(outcome => outcome.key)
	},

	setClient({ commit, dispatch }, key) {
		commit('setClient', key)
		dispatch('fetchTop')
	},

    setMeta: ({ commit }, meta) => {
        commit('setMeta', pick(meta, ['total', 'search', 'clientId']))
    },

	reset: mapToMutation('reset')
}

export const clientOutcomes = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})