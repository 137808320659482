<template>
<div id='app' @dragover.prevent @drop.prevent>
	<router-view />
	<transition name='fade'>
		<CookieConsent v-if='showCookieConsent' @close='showCookieConsent = false' />
	</transition>
	<SingleTab />
	<div id='serviceWorkerUpdate'><div>
		<header><div class='logo'><img src='/favicon.png' /></div><div class='text'>SimpleSet has been updated.<br />To continue, please refresh.</div></header>
		<div class='textButton primary' @click='reload'>Refresh</div>
	</div></div>
</div>
</template> 

<script>
import { isMobile } from '@/utilities'
import Cookies from 'js-cookie'
import CookieConsent from '@/components/common/CookieConsent'
import SingleTab from '@/components/common/SingleTab'

export default {
	name: 'App',
	components: { CookieConsent, SingleTab },
	data: () => ({
		showCookieConsent: !Cookies.getJSON('cookieConsent'),
		mobile: isMobile()
	}),
	methods: {
		reload() {
			this.$store.dispatch('auth/logout')
				.finally(()=>window.location.replace(window.location.pathname))
		}
	},
	mounted() {
		document.body.classList.add(this.mobile ? 'mobile' : 'desktop')
		document.addEventListener('contextmenu', e => {
			if (!e.target.closest('.textInput, .searchInput, .plainText, .richText, .emailsInput')) {
				e.preventDefault()
				return false
			}
		})
	}
}
</script>

<style lang='scss'>
@import '/assets/scss/base';

#app {
	@include fill;
	color: $color-black;
	/*
	* { touch-action: none; }
	*/

	#serviceWorkerUpdate {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 99999;
		background: rgba(#000, 0.9);
		align-items: center;
		justify-content: center;

		header { 
			display: flex;
			align-items: center;
			justify-content: center;			
			color: $color-white;
			font-size: $size-font-large;
			line-height: $size-lineHeight-large;
			font-weight: bold;
			text-shadow: 0 0 40px rgba(#000, 0.8);
			text-align: center;

			.logo {
				flex: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 50px;
				height: 50px;
				flex-basis: 50px;
				border-radius: 50%;
				background: $color-white;
				img { width: 24px; }
			}

			.text {
				padding-left: 20px;
			}			
		}

		.textButton {
			height: $size-control-height * 2;
			font-size: $size-font-large;
			margin-top: $size-gutter * 4;
			box-shadow: 0 0 40px rgba(#000, 0.3);
			border-radius: 3px;
		}

	}	
}

</style>