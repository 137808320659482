
// all
import { bus } from '@/store/modules/dynamic/bus'
import { flash } from '@/store/modules/dynamic/flash'

//clincian
import { exerciseBrowser } from '@/store/modules/dynamic/clinician/exerciseBrowser'
import { elements } from '@/store/modules/dynamic/clinician/elements'
import { setBuilder } from '@/store/modules/dynamic/clinician/setBuilder'
import { sets } from '@/store/modules/dynamic/clinician/sets'
import { bulkParameters } from '@/store/modules/dynamic/clinician/bulkParameters'
import { bulkParameterPresets } from '@/store/modules/dynamic/clinician/bulkParameterPresets'
import { categories } from '@/store/modules/dynamic/clinician/categories'
import { resources } from '@/store/modules/dynamic/resources'
import { clients } from '@/store/modules/dynamic/clinician/clients'
import { manageClients } from '@/store/modules/dynamic/clinician/manageClients'
import { saveClients } from '@/store/modules/dynamic/clinician/saveClients'
import { clientSets } from '@/store/modules/dynamic/clinician/clientSets'
import { clientOutcomes } from '@/store/modules/dynamic/clinician/clientOutcomes'
import { setsPreview } from '@/store/modules/dynamic/clinician/setsPreview'
import { setHistory } from '@/store/modules/dynamic/clinician/setHistory'
import { setPdf } from '@/store/modules/dynamic/clinician/setPdf'
import { headerFooters } from '@/store/modules/dynamic/clinician/headerFooters'
import { manageTemplates } from '@/store/modules/dynamic/clinician/manageTemplates'
import { manageEducation } from '@/store/modules/dynamic/clinician/manageEducation'
import { manageOutcomes } from '@/store/modules/dynamic/clinician/manageOutcomes'
import { notifications } from '@/store/modules/dynamic/clinician/notifications'

// manager
import { teams } from '@/store/modules/dynamic/manager/teams'
import { staff } from '@/store/modules/dynamic/manager/staff'

export const mountModules = store => {

	store.registerModule('bus', bus())
	store.registerModule('flash', flash())

//	if (store.state.profile.user.clinician) {
		store.registerModule('categories', categories(store.state.profile.user.id))
		store.registerModule('bulkParameterPresets', bulkParameterPresets())
		store.registerModule('bulkParameters', bulkParameters(store.state.profile.user.id))	
		store.registerModule('resources', resources())
		store.registerModule('elements', elements())
		store.registerModule('exerciseBrowser', exerciseBrowser())
		store.registerModule('sets', sets())
		store.registerModule('setBuilder', setBuilder(store.state.profile.user.id))
		store.registerModule('manageTemplates', manageTemplates())	
		store.registerModule('saveTemplates', manageTemplates())	
		store.registerModule('clients', clients())
		store.registerModule('manageClients', manageClients())
		store.registerModule('manageClientSets', clientSets())
		store.registerModule('manageClientOutcomes', clientOutcomes())
		store.registerModule('saveClients', saveClients())
		store.registerModule('saveClientSets', clientSets())
		store.registerModule('clientSetsPreview', setsPreview())
		store.registerModule('manageEducation', manageEducation())	
		store.registerModule('attachEducation', manageEducation())	
		store.registerModule('manageOutcomes', manageOutcomes())		
		store.registerModule('setHistory', setHistory())
		store.registerModule('setPdf', setPdf(store.state.profile.user.id))
		store.registerModule('headerFooters', headerFooters())
		store.registerModule('notifications', notifications())
//	}

//	if (store.state.profile.user.manager) {
		store.registerModule('teams', teams())
		store.registerModule('staff', staff())
//	}
}

export const unmountModules = store => {
	store.commit('bus/reset')
	store.unregisterModule('bus')
	store.commit('flash/reset')
	store.unregisterModule('flash')

//	if (store.state.profile.user.clinician) {
		store.commit('resources/reset')
		store.unregisterModule('resources')
		store.commit('bulkParameterPresets/reset')
		store.unregisterModule('bulkParameterPresets')
		store.commit('bulkParameters/reset')
		store.unregisterModule('bulkParameters')
		store.commit('exerciseBrowser/reset')
		store.unregisterModule('exerciseBrowser')
		store.commit('setBuilder/reset')
		store.unregisterModule('setBuilder')
		store.commit('elements/reset')
		store.unregisterModule('elements')
		store.commit('sets/reset')
		store.unregisterModule('sets')
		store.commit('categories/reset')
		store.unregisterModule('categories')
		store.commit('manageClients/reset')
		store.unregisterModule('manageClients')
		store.commit('manageClientSets/reset')
		store.unregisterModule('manageClientSets')
		store.commit('manageClientOutcomes/reset')
		store.unregisterModule('manageClientOutcomes')
		store.commit('saveClients/reset')
		store.unregisterModule('saveClients')
		store.commit('saveClientSets/reset')
		store.unregisterModule('saveClientSets')
		store.commit('clientSetsPreview/reset')
		store.unregisterModule('clientSetsPreview')
		store.commit('clients/reset')
		store.unregisterModule('clients')
		store.commit('manageTemplates/reset')
		store.unregisterModule('manageTemplates')
		store.commit('saveTemplates/reset')
		store.unregisterModule('saveTemplates')
		store.commit('manageEducation/reset')
		store.unregisterModule('manageEducation')
		store.commit('attachEducation/reset')
		store.unregisterModule('attachEducation')
		store.commit('manageOutcomes/reset')
		store.unregisterModule('manageOutcomes')
		store.commit('setHistory/reset')
		store.unregisterModule('setHistory')	
		store.commit('setPdf/reset')
		store.unregisterModule('setPdf')	
		store.commit('headerFooters/reset')
		store.unregisterModule('headerFooters')	
		store.commit('notifications/reset')
		store.unregisterModule('notifications')	
//	}

//	if (store.state.profile.user.manager) {
		store.commit('teams/reset')
		store.unregisterModule('teams')
		store.commit('staff/reset')
		store.unregisterModule('staff')		
//	}

	store.commit('profile/reset')

}


/*

import { mountClinician, unmountClinician } from './modules/clinician'
import { mountManager, unmountManager } from './modules/manager'
import { mountAdmin, unmountAdmin } from './modules/admin'

let unmountMounted = false

const getMounter = store => {
	if (store.getters['auth/isClinician']) return [mountClinician, unmountClinician]
	else if (store.getters['auth/isManager']) return [mountManager, unmountManager]
	else if (store.getters['auth/isAdmin']) return [mountAdmin, unmountAdmin]
	else return [false, false]
}

export const mountModules = store => {
	const [mount, unmount] = getMounter(store)
	if (!mount || unmountMounted) return false
	mount(store)
	unmountMounted = unmount
}

export const unmountModules = store => {
	unmountMounted(store)
	unmountMounted = false
}

*/