import Vue from 'vue'
import { pick } from 'lodash'
import { profileApi } from '@/services/api/modules/profile'

const state = { 
	user: {},
	teams: {},
	organization: {},
	emr: {}
}

const getters = {}

const mutations = {
	initUser: (state, val) => state.user = val,
	initTeams: (state, val) => {
		state.teams = val.reduce((acc, curr)=>{
			acc[curr.id]=curr
			return acc
		}, {})
	},
	initOrg: (state, val) => state.organization = val,
	initEmr: (state, val) => state.emr = val,
	setUserAttr: (state, {attr, val}) => Vue.set(state.user, attr, val),
	setOrgAttr: (state, {attr, val}) => Vue.set(state.organization, attr, val),
	removeIntegration: state => state.emr = {},
	restartIntegration: state => Vue.set(state.emr, 'enabled', true),
	decOrgInvitations: (state, amt) => state.organization.teamInvitations-=amt,
	incOrgInvitations: (state, amt) => state.organization.teamInvitations+=amt,
	reset: state => {
		state.user = {}
		state.teams = {}
		state.organization = {}
		state.emr = {}
	}
}

const actions = {
	init({ commit }, profile) {
		commit('reset')
		commit('initUser', profile.user)
		if (profile.teams) commit('initTeams', profile.teams)
		if (profile.organization) commit('initOrg', profile.organization)
		if (profile.emr) commit('initEmr', profile.emr)		
	},	
	async fetch({ dispatch }) {
		const [err, profile] = await profileApi.touch('fetch')
		if (!err) {
			await dispatch('init', profile)
			return profile
		}
	},
	async save({ commit }, attrs) {
		const payload = pick(attrs, ['lang','timeout','secondaryAuth','username','firstname','lastname','email','headerId','clientEmailBody','clientEmailSig','clientEmailCC'])
		const [err, user] = await profileApi.touch('save', payload)
		if (!err) {
			commit('initUser', user)
		}
		return [err, user]
	},
	async saveOrg({ commit }, attrs) {
		const payload = pick(attrs, ['title','phone'])
		const [err, org] = await profileApi.touch('saveOrg', payload)
		if (!err) {
			commit('initOrg', org)
		}
		return [err, org]
	},	
	async changePassword({ commit }, payload) {
		return await profileApi.touch('changePassword', payload)
	},
	async setLang({ commit, dispatch }, lang) {
		await dispatch('save', { lang })
		dispatch('flash/showAction', 'langChange', { root: true })
	},
	async integrate({ commit }, payload) {
		const [err, emr] = await profileApi.touch('integrate', payload)
		if (!err) {
			commit('initEmr', { title: payload.type, enabled: true })
		}
		return [err, emr]
	},
	async removeIntegration({ commit }) {
		const [err] = await profileApi.touch('removeIntegration')
		if (!err) {
			commit('initEmr', {})
		}
	},
	async restartIntegration({ commit }) {
		const [err] = await profileApi.touch('restartIntegration')		
		if (!err) {
			commit('restartIntegration')
		}
	},
}

export const profile = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}