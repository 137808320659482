import { set, reset, mapToMutation } from '@/store/helpers'

/* state */

const state = () => ({
	tab: 'all',
	folderId: null,
	userRootId: null,
	teamRootId: null,
	orgRootId: null,
	selectedId: null,
	search: '',
	searched: '',
	sortColumn: null,
	sortDirection: null,
})

/* getters */

const getters = {}

/* mutations */

const mutations = {
	setTab: set('tab'),
	setFolderId: set('folderId'),
	setUserRootId: set('userRootId'),
	setTeamRootId: set('teamRootId'),
	setOrgRootId: set('orgRootId'),
	setSelectedId: set('selectedId'),
	setSearch: set('search'),
	setSearched: set('searched'),
    setSortColumn: set('sortColumn'),
    setSortDirection: set('sortDirection'),	
	reset: reset(state())
}

/* actions */

const actions = {
	setTab: mapToMutation('setTab'),
	setFolderId: mapToMutation('setFolderId'),
	setUserRootId: mapToMutation('setUserRootId'),
	setTeamRootId: mapToMutation('setTeamRootId'),
	setOrgRootId: mapToMutation('setOrgRootId'),
	setSelectedId: mapToMutation('setSelectedId'),
	setSearch: mapToMutation('setSearch'),
	setSearched: mapToMutation('setSearched'),
	reset: mapToMutation('reset'),
    toggleColumn({ state, commit }, column) {
        if (state.sortColumn !== column) {
            commit('setSortColumn', column)
            commit('setSortDirection', 'asc')
        } else if (state.sortColumn === column && state.sortDirection === 'asc') {
            commit('setSortDirection', 'desc')
        } else if (state.sortColumn === column && state.sortDirection === 'desc') {
            commit('setSortColumn', null)
            commit('setSortDirection', null)
        }
    },
}

export const manageOutcomes = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})