import { mountModules, unmountModules } from './mountModules'
import { mountWatchers, unmountWatchers } from './mountWatchers'
import { EventBus, resetSimpleState } from '@/store/helpers'

export default (store, router, i18n) => {

	store.watch(
		(state) => state.auth.authenticated,
		(authenticated) => {
			if (authenticated) {
				mountModules(store)
				mountWatchers(store)
			} else {
				unmountWatchers(store)
				unmountModules(store)
				resetSimpleState()
				store.dispatch('main/reset')
					.then(() => EventBus.$emit('reset'))				
				
			}
			if (router.currentRoute.query.redirect) window.location.replace(router.currentRoute.query.redirect)
			else router.replace({ name: 'getLanding', replace: true }).catch(err => err)
		}
	)

	store.watch(
		(state, getters) => getters['main/activeLang'],
		lang => i18n.locale = lang 
	)

	store.watch(
		state => state.main.apiError,
		err => {
			if (
				err && 
				err.status === 401 &&
				store.state.main.apiCall !== 'team-swap'
			) store.dispatch('auth/logout', false) // don't call logout on the server
		}
	)

}