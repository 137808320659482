import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		upload: '/upload/${type}?imgWidth=${resourceWidth}',
		fetch: '/${ids}?imgWidth=${resourceWidth}'
	}
}

export const resourcesApi = buildApi('resources', routes, '/clinician/resources')

