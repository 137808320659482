import { pick, xor } from 'lodash'
import { listState, listGetters, listMutations, listActions } from '@/store/modules/mixins/list'
import { selectState, selectGetters, selectMutations, selectActions } from '@/store/modules/mixins/select'
import { set, reset, toggle, mapToMutation } from '@/store/helpers'
import { resourceDimensions } from '@/configuration/settings'
import { elementsApi } from '@/services/api/modules/clinician/elements'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'

const callApi = buildApiCaller(elementsApi)

const validateTab = (tab) => {
	return ['stock', 'starred', 'custom'].includes(tab)

//	if (['stock', 'starred', 'stock', 'personal'].includes(tab)) return true
//	else if (tab === 'team' && getters['profile/teamExercises']) return true
//	else return false
}

const getRoute = rootState => {
	const routes = {
		stock: 'fetchStock', //: 'fetchAll',
//		stock: rootState.categories.selected ? 'fetchStock' : 'fetchAll',
		starred: 'fetchFavourite',
		custom: 'fetchCustom'
//		stock: 'fetchStock',
//		personal: 'fetchUser',
//		team: 'fetchTeam'
	}
	return routes[rootState.exerciseBrowser.tab] || false
}

/* state */

const state = () => ({
	...listState,
	...selectState,
	...apiState,
	tab: 'stock',
	detail: null,
	animateDetail: false,
	fetchingById: [],
	filterExercises: true,
	filterStacks: true,
	perPage: 100,
	page: 1,

	folderData: {},
	folderId: null,
	userRootId: null,

	fetchWorkaround: false
})

/* getters */

const getters = { 
	...listGetters,
	...selectGetters,
	totalPages: state => Math.ceil(state.meta.total / 100) || null,
    lastPage: state => state.list.length >= state.perPage || (state.list.length + ((state.page-1)*state.perPage)) === state.meta.total,
	detail: (state, getters, rootState) => key => {
		if (!state.detail) return false
		else if (state.detail === key) return true
		const element = rootState.elements.root[state.detail]
		if (element && element.type === 'stackExercise' && element.stackKey === key) return true
		else return false
	},
	showCreate: (state, getters, rootState) => {
		if (
			state.tab === 'custom' && 
			(
				!state.folderId || 
				(
					state.folderId && state.folderData[state.folderId] && 
					(
						state.folderData[state.folderId].type === 'user' ||
						(state.folderData[state.folderId].type === 'team' && rootState.profile.teams[state.folderData[state.folderId].teamId].teamExercises==='readWrite') ||
						(state.folderData[state.folderId].type === 'org' && rootState.profile.user.orgExercises==='readWrite')
					)
				)
			)
		) return true
		else return false
	},
	topPageMeta: (state, getters, rootState) => 
		Object.assign({
			skip: 0 + (((state.page-1) * state.perPage) || 0),
			limit: Math.min(100, state.limit - (getters.showCreate ? 1 : 0)),
			search: state.search,
			imgWidth: rootState.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small
		}, 
		rootState.categories.selected && state.tab==='stock' ? { categoryId: rootState.categories.selected } : null,
		state.folderId && state.tab==='custom' ? { folderId: state.folderId } : null,
	//	state.tab === 'custom' ? { exercises: state.filterExercises, stacks: state.filterStacks } : null
	),
	nextPageMeta: (state, getters, rootState) => {
		const length = state.list.length + (getters.showCreate ? 1 : 0)
		const difference = (state.limit - (length % state.limit)) % state.limit
		return Object.assign(
			{
				skip: state.list.length + (((state.page-1) * state.perPage) || 0),
				limit: Math.min(100, state.limit + difference),
				search: state.search,
				imgWidth: rootState.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small
			}, 
			rootState.categories.selected && state.tab==='stock' ? { categoryId: rootState.categories.selected } : null,
			state.folderId && state.tab==='custom' ? { folderId: state.folderId } : null,
//			state.tab === 'custom' ? { exercises: state.filterExercises, stacks: state.filterStacks } : null
		)
	},
	/*
	unsavedChanges: (state, getters, rootState, rootGetters) => {
		return !!(
			state.detail &&
			rootGetters['elements/isExercise'](state.detail) &&
			rootState.elements.root[state.detail].fresh
		)
	}
	*/
}

/* mutations */

const mutations = {
	...listMutations,
	...selectMutations,
	...apiMutations,
	setTab: set('tab'),
	setPage: set('page'),
	setDetail: set('detail'),
	setFetchWorkaround: set('fetchWorkaround'),
	setAnimateDetail: set('animateDetail'),
	resetFetchingById: set('fetchingById', []),
	reset: reset(state()),
	toggleExercises: toggle('filterExercises'),
	toggleStacks: toggle('filterStacks'),
	setFetchingById: (state, ids) => {
		if (typeof ids === 'string') {
			state.fetchingById = ids.split(',').map(id => +id)
		} else {
			state.fetchingById = [ids]
		}
	},
	setSelected: (state, selected) => {
		if (xor(state.selected, selected).length) {
			state.selected = selected.length >= 50 ? selected.slice(0,50) : selected
		}
	},
	setSelecting: (state, selecting) => {
		if (xor(state.selecting, selecting).length) {
			state.selecting = selecting.length >= 50 ? selecting.slice(0,50) : selecting
		}
	},
	toggleSelected: (state, key) => {
		if (state.selected.includes(key) || state.selected.length < 50) state.selected = xor(state.selected, [key]) 
	},
	setFolderId: (state, folderId) => {
		//if (state.folderData[folderId]) state.folderId = folderId
		state.folderId = folderId
	},
	setUserRootId: set('userRootId'),
	setFolderData: set('folderData')
}

/* actions */

const fetch = async ({ rootState, commit, dispatch, state }, payload, callback) => {
	const route = getRoute(rootState)
	const [err, result] = await callApi({ commit, route, payload, race: true })
	if (!err) {
		if (callback) await callback()
		const { total, items, resources, categories } = result
		if (resources) await dispatch('resources/parseResourcePreviews', resources, { root: true })
		await dispatch('elements/parseElementsOver', items, { root: true })
		const meta = { total: total, search: rootState.exerciseBrowser.search, categories, id: payload.categoryId, folderId: payload.folderId }
		const keys = items.map(element => element.id)
		return [null, { meta, keys }]
	} else {
		return [err]
	}
}

const fetchTop = async ({ state, rootState, commit, dispatch }, payload) => {
	const _payload = Object.assign({}, payload)
	let searching = false
//	console.log('here', rootState.categories.selected, state.meta.id)
	if (
		state.search !== state.meta.search || 
		(state.tab==='stock' && (rootState.categories.selected || undefined) !== state.meta.id) ||
		(state.tab==='custom' && (state.folderId || undefined) !== state.meta.folderId )
	//	!state.folderId
	) {
		_payload.skip = 0
		searching = true
	}
	return await fetch({ rootState, commit, dispatch }, _payload, async () => {
		if (searching) commit('setPage', 1)
		commit('resetList')
		commit('clearSelected')
		commit('setDetail', null)		
		if (!rootState.bus.bussing) await dispatch('elements/reset', null, { root: true })	
	})
}

const setTarget = ({ exercises, stacks }, tab, folderId, folders, rootState) => {
	let exerciseTarget, stackTarget
	if (tab === 'stock') {
		exerciseTarget = { type: 'customExercise', folderId: rootState.exerciseBrowser.userRootId }
		stackTarget = { type: 'stack', folderId: rootState.exerciseBrowser.userRootId }	
	} else if (tab === 'starred') {
		exerciseTarget = { type: 'customExercise', folderId: rootState.exerciseBrowser.userRootId }
		stackTarget = { type: 'stack', folderId: rootState.exerciseBrowser.userRootId }	
	} else if (tab === 'custom') {
		if (
			(folderId && folders && folders[folderId].type === 'user') ||
			(folderId && folders && folders[folderId].type === 'team' && rootState.profile.teams[folders[folderId].teamId].teamExercises==='readWrite') ||
			(folderId && folders && folders[folderId].type === 'org' && rootState.profile.user.orgExercises==='readWrite')
		) {
			exerciseTarget = { folderId, type: 'customExercise' }
			stackTarget = { folderId, type: 'stack' }					
		} else {
			exerciseTarget = { type: 'customExercise', folderId: rootState.exerciseBrowser.userRootId }
			stackTarget = { type: 'stack', folderId: rootState.exerciseBrowser.userRootId }			
		}
	}
	/*
	} else if (tab === 'team') {
		exerciseTarget = { type: 'teamExercise' }
		stackTarget = { type: 'teamStack' }
	*/
	if (!exercises) exercises = []
	if (!stacks) stacks = []
	exercises.forEach(exercise => Object.assign(exercise, exerciseTarget))
	stacks.forEach(stack => Object.assign(stack, stackTarget))
	return stacks.concat(exercises)
}

const fetchByIds = async ({commit, dispatch, rootState}, ids, silent) => {
	const payload = { ids, imgWidth: rootState.main.hiRes ? resourceDimensions.small * 2 : resourceDimensions.small }
	const [err, result] = await callApi({ commit, route: 'fetchIds', payload, silent, race: true })
	if (!err) {
		const { items, resources } = result
		await dispatch('resources/parseResourcePreviews', resources, { root: true })
		await dispatch('elements/parseElementsOver', items, { root: true })
		commit('resetFetchingById')
		return true
	} else {
		if (err.code !== 'ERRCANCEL') commit('resetFetchingById')
		return false
	}
}

const actions = {
	...selectActions,
	...listActions(fetchTop, fetch),

	async fetchWorkaround({ state, dispatch, commit }, o) { // how we shold have done this from the start
		commit('setFetchWorkaround', true)
		if (o.tab && state.tab !== o.tab) {
			commit('setTab', o.tab)
			commit('setPage', 1)
			commit('resetList')
			commit('clearSelected')
			commit('resetMeta')    		
		}
		if (o.folderId && state.folderId !== o.folderId) {
			commit('setFolderId', o.folderId)
		}
		await dispatch('fetchTop')
		commit('setFetchWorkaround', false)
	},

	async fetchByIds(context, ids) {
		const { state, commit, dispatch } = context
		await dispatch('elements/prune', state.detail, { root: true })
		commit('setFetchingById', ids)
		const success = await fetchByIds(context, ids, true)
		return success
	},

	async fetchByIdsForCopy(context, ids) {
		const { state, dispatch } = context
		await dispatch('elements/prune', state.detail, { root: true })
		return await fetchByIds(context, ids)
	},

	moveElement({ state, commit }, index) {
		const id = state.list[index]
		if (index === state.list.length - 1) {
			const targetId = state.list[index - 1]
			callApi({
				commit,
				route: state.tab === 'starred' ? 'moveFavouriteToRightOf' : 'moveToRightOf',
				payload: { id, targetId },
				silent: true
			})
		} else {
			const targetId = state.list[index + 1]
			callApi({
				commit,
				route: state.tab === 'starred' ? 'moveFavouriteToLeftOf' : 'moveToLeftOf',
				payload: { id, targetId },
				silent: true
			})
		}
	},

	moveElements({ state, commit }, payload) {
		return callApi({
			commit,
			route: 'moveElementsToFolder',
			payload
		})
	},

	moveElementsIntoStack({ state, commit }, payload) {
		return callApi({
			commit,
			route: 'moveElementsToStack',
			payload
		})
	},

	destroy({ state, commit, dispatch, rootState }, keys) {
		if (!Array.isArray(keys)) keys = [keys]
		commit('popList', keys)
		commit('clearSelected')
		if (rootState.elements.root[state.detail].type !== 'stackExercise') commit('decrementTotal', keys.length)
		if (keys.includes(state.detail) && rootState.elements.root[state.detail].type === 'stackExercise') commit('setDetail', rootState.elements.root[state.detail].stackKey)
		dispatch('elements/destroy', keys, { root: true })
	},

	setFavourite({ state, dispatch }, { key, value }) {
//		if (!value && state.tab === 'starred') dispatch('removeElements', key)
		dispatch('elements/setFavourite', { key, value }, { root: true })
	},

	async save({ commit, dispatch }, key) {
		const [err, result] = await dispatch('elements/save', key, { root: true })
		if (!err && result.id !== key) {
			const sourceKey = key
			const targetKey = result.id
			setTimeout(()=>{ // so events in component can propagate before the component gets rerendered
				commit('replaceListKey', { sourceKey, targetKey })
				commit('setDetail', targetKey)
			}, 0)
		}
	},

	async copy({ state, rootState, commit, dispatch, getters }, { exercises, stacks, tab, folders }) {
//		console.log(exercises)
/*
		if (!getters.showCreate) {
			if (state.tab !== 'custom') {
				await dispatch('setTab', 'custom')
			}
			commit('setFolderId', null)
			await dispatch('fetchTop')
		}
*/
		const [err, result] = await dispatch((tab || state.tab) === 'starred' ? 'elements/createFavourites' : 'elements/create', setTarget({ exercises, stacks }, tab || state.tab, state.folderId, state.folderData, rootState), { root: true })
		if (!err) {
			const keys = result.map(element => element.id)			
//			if (tab) {
//				await dispatch('fetchTop')				
//			} else if (state.tab==='stock') {
//				commit('setFolderId', null)
//				await dispatch('setTab', 'custom')
//				await dispatch('setSelectedMain', keys)
//			} else {
			if (getters.showCreate) {
				commit('prependList', keys)
				commit('incrementTotal', keys.length)
				await dispatch('setSelectedMain', keys)
			}
//			}
			return keys

			/*
			const msg = tab === 'personal' ? 'copyPersonal' :
				tab === 'team' ? 'copyTeam' :
				tab === 'starred' ? 'copyStarred' : 
				'copied'
			dispatch('flash/showAction', msg, { root: true })
			*/
		}	
	},

	async copyToStack({ dispatch }, { key, exercises }) {
		if (!exercises.length) return
		const [err, result] = await dispatch('elements/createInStack', { key, exercises }, { root: true })
		if (!err) {
			const keys = result.map(element => element.id)
			dispatch('setSelectedSub', keys)
			dispatch('flash/showAction', 'copied', { root: true })
			return keys
		}
	},

	async createStack({ dispatch }, exercises = []) {
		return await dispatch('copy', {
			stacks: [{ title: '', exercises }]
		})
	},

	async createExercise({ dispatch }) {
		return await dispatch('copy', {
			exercises: [{
				title: '',
				instructions: '',
				reflect: false,
				thumbnailId: null,
				parameters: [],
				resources: []				
			}]
		})
	},

	async createStackExercise({ dispatch }, key) {
		return await dispatch('copyToStack', {
			key,
			exercises: [{
				title: '',
				instructions: '',
				reflect: false,
				thumbnailId: null,
				parameters: [],
				resources: []				
			}]
		})
	},

	async toggleDetail({ state, commit, rootState, dispatch }, key) {
		const element = rootState.elements.root[state.detail]
		if (element && element.type === 'stackExercise' && state.detail === key) {
			commit('setDetail', element.stackKey)
		} else if (element && element.type === 'stackExercise' && key === element.stackKey) {
			commit('setDetail', null)
		} else if (state.detail === key) {
			commit('setDetail', null)
		} else {
			if (!rootState.elements.root[key].complete) {
				if (await dispatch('fetchByIds', key)) commit('setDetail', key)
			} else {
				commit('setDetail', key)
			}
		}
		commit('clearSelected')
	},

	setMeta: ({ commit, getters, rootGetters }, meta) => {
		commit('setMeta', Object.assign(
			pick(meta, ['total', 'search', 'categories', 'id', 'folderId']), 
			{ showCreate: getters.showCreate }, 
			rootGetters['categories/path'] ? { categoryPath: rootGetters['categories/path'] } : null
		))
	},

	setTab({ state, commit, dispatch, rootGetters }, tab) { 
		return new Promise((resolve, reject) => {
			if (!validateTab(tab, rootGetters)) return reject('Invalid tab')
			if (tab !== state.tab) {
				commit('setTab', tab)
				commit('setPage', 1)
				commit('resetList')
				commit('clearSelected')
				commit('resetMeta')
				//commit('resetSearch')
				dispatch('fetchTop').then(()=>resolve(true))
			} else {
				resolve(true)
			}
			/*
			if (folderId && tab === 'stock') {
				commit('categores/setSelected', folderId, { root: true })
			} else if (folderId && tab === 'custom') {
				commit('setFolderId', folderId)
			}
			*/
		})
	},

	clearSelectedMain({ state, rootState, commit }) {
		const keys = state.selected.filter(key => rootState.elements.root[key].type === 'stackExercise')
		commit('setSelected', keys)
	},
	clearSelectedSub({ state, rootState, commit }) {
		const keys = state.selected.filter(key => rootState.elements.root[key].type !== 'stackExercise')
		commit('setSelected', keys)
	},
	setSelectedMain({ state, commit, dispatch }, keys) {
		if (keys.every(k=>state.list.includes(k))) {
			commit('setSelected', keys)
			if (keys.length) {
				dispatch('clearSelectedSub')
			}
		}
	},
	setSelectedSub({ commit, dispatch }, keys) {
		commit('setSelected', keys)
		if (keys.length) {
			dispatch('clearSelectedMain')
		}
	},
	toggleSelectedMain({ state, commit, dispatch }, key) {
		commit('toggleSelected', key)
		if (state.selected.length) {
			dispatch('clearSelectedSub')
		}
	},
	toggleSelectedSub({ state, commit, dispatch }, key) {
		commit('toggleSelected', key)
		if (state.selected.length) {
			dispatch('clearSelectedMain')
		}
	},
	toggleExercises({ state, commit, dispatch }) {
		commit('toggleExercises')
		if (!state.filterExercises && !state.filterStacks) return dispatch('toggleStacks')
		dispatch('fetchTop')
	},
	toggleStacks({ state, commit, dispatch }) {
		commit('toggleStacks')
		if (!state.filterExercises && !state.filterStacks) return dispatch('toggleExercises')
		dispatch('fetchTop')
	},
	swapStockExercise({ commit, dispatch }, { sourceKey, targetKey }) {
		commit('replaceListKey', { sourceKey, targetKey })
		dispatch('toggleDetail', targetKey)
	},
	removeElements({ commit }, keys) {
		commit('popList', keys)
		commit('decrementTotal', Array.isArray(keys) ? keys.length : 1)
	},
	setPage({ commit, dispatch }, page) {
		commit('setPage', page)
		dispatch('fetchTop')
	},	
	setAnimateDetail: mapToMutation('setAnimateDetail'),
	reset: mapToMutation('reset'),
	setFolderId: mapToMutation('setFolderId'),
	setUserRootId: mapToMutation('setUserRootId')
}

export const exerciseBrowser = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})