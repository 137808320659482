import { set, reset, simpleState } from '@/store/helpers'

const state = () => ({
	bus: null,
	bussing: false,
	contentType: null
})

const getters = {
	busLoaded: state => !!state.bus
}

const mutations = {
	setBus: set('bus'),
	setBussing: set('bussing'),
	setContentType: set('contentType'),
	reset: reset(state())
}

const actions = {
	loadBus({ commit }, { data, contentType, el, startX, startY, screenX, screenY, originX, originY }) {
		simpleState.busEl = el || null
		simpleState.startX = startX || null
		simpleState.startY = startY || null
		simpleState.screenX = screenX || null
		simpleState.screenY = screenY || null
		simpleState.originX = originX || null
		simpleState.originY = originY || null		
		if (contentType) commit('setContentType', contentType)
		commit('setBus', data)
		commit('setBussing', true)
	},
	unloadBus({ commit }) {
		setTimeout(() => {
			simpleState.busEl = null
			commit('setBus', null)
			commit('setBussing', false)
		}, 0)
	}
}

export const bus = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})