import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/'
	},
	put: {
		markRead: '/markViewed',
	},
}

export const notificationsApi = buildApi('notifications', routes, '/clinician/notifications')

