import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchIds: '/sets/${ids}',
		fetchHistory: '/sets/${id}/history',
		fetchHistoryPoint: '/sets/history/${id}',
		fetchReports: '/sets/${id}/reports',
		fetchDrafts: '/sets/drafts',
		fetchTemplates: '/templates'
	},
	post: {
		createSet: '/sets/',
		save: '/sets/',
		saveDraft: '/sets/draft',
		export: '/sets/pdf'
	},
	put: {
		updateSet: '/sets/${id}',
		copyToEmr: '/sets/${id}/emr',	
		email: '/sets/${id}/email',
		enabled: '/sets/${ids}/enabled/?value=${state}',
		move: '/sets/${ids}/folder/${folderId}'
	},
	delete: {
		destroy: '/sets/${ids}',
		destroyDrafts: '/sets/drafts'
	}
}

export const setsApi = buildApi('sets', routes, '/clinician')

