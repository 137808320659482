import Vue from 'vue'
import { pick, debounce } from 'lodash'
import { set, push, pop, unshift, mapToMutation } from '@/store/helpers'

/* state */

export const listState = {
    limit: 50,
    search: '',
    sortColumn: null,
    sortDirection: null,
    list: [],
    meta: { total: 0 },
    loaded: false
}

/* getters */

export const listGetters = {
    loaded: state => !!state.list.length,
    lastPage: state => state.list.length === state.meta.total,
    topPageMeta: state => ({
        skip: 0,
        limit: state.limit,
        search: state.search,
        sortColumn: state.sortColumn,
        sortDirection: state.sortDirection
    }),
    nextPageMeta: state => ({
        skip: state.list.length,
        limit: state.limit,
        search: state.search,
        sortColumn: state.sortColumn,
        sortDirection: state.sortDirection
    })
}

/* mutations */

export const listMutations = {
    setLimit: set('limit'),
    setSearch: set('search'),
    setMeta: set('meta'),
    resetMeta: set('meta', {}),
    setSortColumn: set('sortColumn'),
    setSortDirection: set('sortDirection'),
    setLoaded: set('loaded', true),
    incrementTotal: (state, value) => Vue.set(state.meta, 'total', state.meta.total + value),
    decrementTotal: (state, value) => Vue.set(state.meta, 'total', state.meta.total - value),
    setList: set('list'),
    addList: push('list'),
    popList: pop('list'),
    resetList: set('list', []),
    prependList: unshift('list'),
    resetSearch: state => {
        state.search = ''
        state.sortColumn = null
        state.sortDirection = null
    },
    replaceListKey: (state, { sourceKey, targetKey }) => {
        const index = state.list.indexOf(sourceKey)
        state.list.splice(index, 1, targetKey)
    }
}

/* actions */

const fetchTop = async (context, fetch) => {
    const { commit, getters, dispatch } = context
    const [err, result] = await fetch(context, getters.topPageMeta)
    if (!err) {
        const { meta, keys } = result
        dispatch('setMeta', meta)
        commit('setList', keys)
    }
}

const debouncedFetchTop = debounce(fetchTop, 1000)

export const listActions = (fetchTopCall, fetchNextCall) => ({
    async fetchTop(context, wait) {
        if (wait) {
            await debouncedFetchTop(context, fetchTopCall)
        } else {
            debouncedFetchTop.cancel()
            await fetchTop(context, fetchTopCall)
        }
        if (!context.state.loaded) context.commit('setLoaded')
    },
    async fetchNext(context) {
        const { getters, commit } = context
        if (getters.lastPage) return false
        const [err, result] = await fetchNextCall(context, getters.nextPageMeta)
        if (!err) {
            const { keys } = result
            commit('addList', keys)
        }
    },
    setSearch({ state, commit, dispatch }, search) {
        if (state.search === search) return
        commit('setSearch', search)
        dispatch('fetchTop', true)
    },
    setMeta({ commit }, meta) {
        commit('setMeta', pick(meta, ['total', 'search']))
    },
    toggleColumn({ state, commit, dispatch }, column) {
        if (state.sortColumn !== column) {
            commit('setSortColumn', column)
            commit('setSortDirection', 'asc')
        } else if (state.sortColumn === column && state.sortDirection === 'asc') {
            commit('setSortDirection', 'desc')
        } else if (state.sortColumn === column && state.sortDirection === 'desc') {
            commit('setSortColumn', null)
            commit('setSortDirection', null)
        }
        dispatch('fetchTop')
    },
    clearList({ commit }) {
        commit('resetSearch')
        commit('resetMeta')
        commit('resetList')
    },
    resetSearch: mapToMutation('resetSearch'),
    setLimit: mapToMutation('setLimit'),
    setList: mapToMutation('setList')
})