import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/',
		uploadLogo: '/${id}/upload/logo?imgWidth=${resourceWidth}'
	},
	post: {
		create: '/'
	},
	put: {
		update: '/${id}'
	},
	delete: {
		destroy: '/${id}',
		unsetLogo: '/${id}/logo'
	}
}

export const headerFootersApi = buildApi('headerFooters', routes, '/clinician/headerFooters')

