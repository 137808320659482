import { uniq } from 'lodash'
import { reset } from '@/store/helpers'
import { resourcesApi } from '@/services/api/modules/clinician/resources'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { resourceDimensions } from '@/configuration/settings'

const callApi = buildApiCaller(resourcesApi)

const state = () => ({
	...apiState,
	blank: 'data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==',
	resources: {}
})

const getters = {
	getResource: state => (key, preview = true) => {
		if (!key) return state.blank
		else if (!state.resources[key]) return null
		else if (state.resources[key].type === 'thumbnail') {
			return state.resources[key].url
		} else if (['stockImage', 'userImage', 'logo'].includes(state.resources[key].type)) {
			if (preview) return state.resources[key].smallUrl || state.resources[key].largeUrl
			else return state.resources[key].largeUrl
		} else if (['stockVideo', 'userVideo'].includes(state.resources[key].type)) {
			if (preview === 'poster') return state.resources[key].posterUrl
			else if (preview) return state.resources[key].imageUrl
			else return state.resources[key].videoUrl
		} else if (['stockAttachment','userAttachment'].includes(state.resources[key].type)) {
			return state.resources[key].url
		}
	},
	isImage: state => key => !key || state.resources[key] && ['thumbnail','stockImage','userImage'].includes(state.resources[key].type),
	isVideo: state => key => state.resources[key] && ['stockVideo','userVideo'].includes(state.resources[key].type),
	isAttachment: state => key => state.resources[key] && ['stockAttachment','userAttachment'].includes(state.resources[key].type)	
}

const mutations = {
	...apiMutations,
	//setResources: spread(),
	setResources: (state, payload) => state.resources = payload,
	reset: reset(state())
}

const actions = {

	parseResourcePreviews({ state, commit }, resources) {
		if (!Array.isArray(resources)) resources = [resources]
		const payload = Object.assign({}, state.resources)
		resources.forEach(resource => {
			if (!payload[resource.id]) payload[resource.id] = { type: resource.type }
			if (resource.type === 'thumbnail') payload[resource.id].url = resource.url
			if (resource.type === 'stockImage') payload[resource.id].smallUrl = resource.url
			if (resource.type === 'userImage') payload[resource.id].smallUrl = resource.url
			if (resource.type === 'stockVideo') payload[resource.id].imageUrl = resource.url
			if (resource.type === 'userVideo') payload[resource.id].imageUrl = resource.url
			if (resource.type === 'stockAttachment' || resource.type === 'userAttachment') payload[resource.id].url = resource.url
		})
		commit('setResources', Object.freeze(payload))
	},

	parseResources({ state, commit }, resources) {
		if (!Array.isArray(resources)) resources = [resources]
		const payload = Object.assign({}, state.resources)
		resources.forEach(resource => {
			if (!payload[resource.id]) payload[resource.id] = { type: resource.type }
			if (resource.type === 'stockImage') payload[resource.id].largeUrl = resource.url
			if (resource.type === 'userImage') payload[resource.id].largeUrl = resource.url
			if (resource.type === 'stockVideo') {
				payload[resource.id].videoUrl = resource.url
				payload[resource.id].posterUrl = resource.posterUrl
			}
			if (resource.type === 'userVideo') {
				payload[resource.id].videoUrl = resource.url
				payload[resource.id].posterUrl = resource.posterUrl
			}
			if (resource.type === 'stockAttachment' || resource.type === 'userAttachment') payload[resource.id].url = resource.url
		})
		commit('setResources', Object.freeze(payload))
	},

	async fetch({ getters, commit, dispatch }, keys ) {
		const ids = uniq(keys.filter(key => !getters.getResource(key, false)))
		if (!ids.length) return
		const [err, result] = await callApi({
			commit,
			route: 'fetch',
			payload: { ids, resourceWidth: resourceDimensions.large }
		})
		if (!err) await dispatch('parseResources', result.items)
	}
}

export const resources = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})