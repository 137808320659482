<template>
<div class='cookieConsent'>
	<header>SimpleSet uses cookies to ensure you get the best experience.</header>
	<div>
		<div class='learnMore textButton'>
			<a href='https://simpleset.net/priv.pdf' target='_blank'><span class='label'>Learn More...</span></a>
		</div>
		<div class='gotIt textButton primary' @click='submit'>
			<span class='label'>Got It!</span>
		</div>
	</div>
</div>
</template>

<script>
import Cookies from 'js-cookie'

export default {
	name: 'CookieConsent',
	methods: {
		submit() {
			Cookies.set('cookieConsent', true, { expires: 365 })
			this.$emit('close')
		}
	}
}
</script>

<style lang='scss'>
.cookieConsent {
	width: 360px;
	padding: $size-gutter * 2;
	background: white;
	position: fixed;
	z-index: 99999;
	right: 0;
	bottom:0;
	border-left: 1px solid $color-neutral-shadow;
	border-top: 1px solid $color-neutral-shadow;
	border-top-left-radius: 3px;
	box-shadow: 0 40px 60px -2px rgba(#000000, 0.2);			

	header {
		font-size: $size-font-large;
		line-height: $size-lineHeight-large;
		color: $color-primary-accent;
		font-weight: bold;
		text-align: center;
		margin-bottom: $size-gutter * 4;
		margin-top: $size-gutter * 2;
		padding: 0 $size-gutter * 2;
	}

	.gotIt { border-radius: 3px; }

	> div {
		display: flex;
		> div { flex: 1; }
	}

	a { color: $color-black; text-decoration: none; }
}
</style>