import { pick, clone } from 'lodash'
import { set, toggle, reset, mapToMutation } from '@/store/helpers'
import { listState, listGetters, listMutations, listActions } from '@/store/modules/mixins/list'
import { selectState, selectGetters, selectMutations, selectActions } from '@/store/modules/mixins/select'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { clientsApi } from '@/services/api/modules/clinician/clients'

const callClientsApi = buildApiCaller(clientsApi)

/* state */

const state = () => ({
	...listState,
	...selectState,
	...apiState,
	max: 0,
	detail: null,
	clientView: 'sets',
	feedback: true,
	shared: false,
	archived: false,
	teamId: null,
})

/* getters */

const getters = {
	...listGetters,
	...selectGetters,
	topPageMeta: (state, getters, rootState) => {
		const o = {
			skip: 0,
			limit: state.limit,
			search: state.search,
			sort: state.sortColumn,
			sortDir: state.sortDirection,
			archived: state.archived,
			feedback: state.feedback,
			//teamIds: state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients) ? Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",") : undefined
			//teamIds: state.teamId || undefined //state.teamId.length ? state.teamIds.join(',') : undefined
		}
		if (state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients)) {
			const teamIds = Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",")
			if (teamIds) o.teamIds = teamIds
		}
		return o
	},
	nextPageMeta: (state, getters, rootState) => {
		const o = {
			skip: state.list.length,
			limit: state.limit,
			search: state.search,
			sort: state.sortColumn,
			sortDir: state.sortDirection,
			archived: state.archived,
			feedback: state.feedback,
		//	teamIds: state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients) ? Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",") : undefined
		}
		if (state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients)) {
			const teamIds = Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",")
			if (teamIds) o.teamIds = teamIds
		}
		return o
	},
	detail: (state, getters, rootState) => rootState.clients[state.detail],
	selected: state => key => state.selected.includes(key) || state.selecting.includes(key)
}

/* mutations */

const mutations = {
	...listMutations,
	...selectMutations,
	...apiMutations,
	setDetail: set('detail'),
	setClientView: set('clientView'),
	setFeedback: set('feedback'),
	setShared: set('shared'),
	toggleArchived: toggle('archived'),
	setTeamId: set('teamId'),	
	toggleTeamId: (state, teamId) => {
		if (state.teamId === teamId) state.teamId = null
		else state.teamId = teamId
		/*
		const i = state.teamIds.indexOf(teamId)
		if (i===-1) {
			state.teamIds.push(teamId)
		} else {
			state.teamIds.splice(i, 1)
		}
		*/
	},

	setSelected: (state, value) => value.length > 50 ? state.selected = value.slice(0,50) : state.selected = value, //set('selectedIds'),
	setMax: (state, value) => state.max = value,

	reset: state => {
		Object.assign(state, {
			...listState,
			...selectState,
			...apiState,
			detail: null,
			clientView: 'sets',
			feedback: true,
			shared: false,
			archived: false,
			teamId: null,
		})
	}
}

/* actions */

const fetch = async (state, commit, payload) => await callClientsApi({ commit, route: 'fetchClients', payload, race: true })

const fetchTop = async ({ state, commit, dispatch }, payload) => {
	const [err, result] = await fetch(state, commit, payload)
	if (!err) {
		commit('resetList')
		commit('setMax', result.max)
		const keys = await dispatch('clients/parseOver', result.items, { root: true })
		const meta = { total: result.total, search: state.search }
		return [null, { meta, keys }]
	} else {
		return [err]
	}
}

const fetchNext = async ({ state, commit, dispatch }, payload) => {
	const [err, result] = await fetch(state, commit, payload)
	if (!err) {
		const keys = await dispatch('clients/parseOver', result.items, { root: true })
		return [null, { keys }]
	} else {
		return [err]
	}
}

const actions = {
	...selectActions,
	...listActions(fetchTop, fetchNext),

	async fetchClient({ commit, dispatch }, id) {
		const [err, result] = await callClientsApi({ commit, route: 'fetchClient', payload: { id }})
		if (!err) {
			await dispatch('clients/parseOver', result.items, { root: true })
		}
	},

	async archive({ state, commit, dispatch }, { archived, key }) {
		const payload = { state: archived }
		const ids = key ? [key] : clone(state.selected)
		payload.ids = ids
		const [err] = await callClientsApi({ commit, route: 'archive', payload })
		if (!err) {
			commit('popList', state.selected)
			commit('decrementTotal', state.selected.length)
			commit('clearSelected')
			const clients = ids.map(id => ({ id, archived }))
			dispatch('clients/parseMerge', clients, { root: true })
			if (archived) dispatch('flash/showAction', 'clientsArchived', { root: true })
			else dispatch('flash/showAction', 'clientsUnarchived', { root: true })
		}
	},

	async destroy({ state, commit, dispatch }) {
		const [err] = await callClientsApi({
			commit, 
			route: 'destroy', 
			payload: { ids: state.selected } 
		})
		if (!err) {
			commit('popList', state.selected)
			commit('decrementTotal', state.selected.length)
			dispatch('clients/remove', clone(state.selected), { root: true })
			commit('clearSelected')
		}
	},

	setMeta({ commit }, meta) {
		commit('setMeta', pick(meta, ['total', 'search', 'archived']))
	},

	setArchived({ state, commit, dispatch }) {
		if (!state.archived) {
			commit('toggleArchived')
			dispatch('fetchTop')
		}
	},

	setActive({ state, commit, dispatch }) {
		if (state.archived) {
			commit('toggleArchived')
			dispatch('fetchTop')
		}
	},

	setSetsView({ state, commit }) {
		if (state.clientView !== 'sets') {
			commit('setClientView', 'sets')
		}
	},

	setOutcomesView({ state, commit }) {
		if (state.clientView !== 'outcomes') {
			commit('setClientView', 'outcomes')
		}
	},

	setLogsView({ state, commit }) {
		if (state.clientView !== 'logs') {
			commit('setClientView', 'logs')
		}
	},

	setSelected({ state, commit }, keys) {
		commit('setSelected', Array.isArray(keys) ? keys : [keys])
		if (state.selected.length === 1) commit('setDetail', state.selected[0])
	},

	toggleSelected({ state, commit }, key) {
		commit('toggleSelected', key)
		if (state.selected.length === 1) commit('setDetail', state.selected[0])
	},	

	setFeedback: mapToMutation('setFeedback'),
	setShared: mapToMutation('setShared'),
	reset: mapToMutation('reset')
}

export const manageClients = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})