<template>
	<div class='loading'>
		<div class='spinner'>
			<div class='blockA block' />
			<div class='blockB block' />
			<div class='blockC block' />
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loading',
	props: ['size'],
	computed: {
		spinnerSize() {
			if (this.size === 'small') return '16px'
			else if (this.size === 'medium') return '24px'
			else return '38px'
		}
	}
}
</script>

<style lang='scss'>
.loading {
	z-index: 10;
	position: absolute !important; 
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	/*
	background: rgba(255,255,255,0.3);
	*/

	.spinner {
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-primary-accent;
		z-index: 10;
		position: relative;
		width: 36px;
		height: 36px; 
		position: relative;
		box-sizing: content-box;
		/*
		background: radial-gradient(circle at center, rgba(255,255,255,.6) 0, rgba(255,255,255,0) 60%);
*/
	/*	animation: rotate 4.8s infinite linear; */
	/*	animation: sk-rotateplane 1.2s infinite ease-in-out; */
	}

	.block {
		position: absolute;
		width: 18px;
		height: 18px;
		&:after {
			position: absolute;
			top: 1px;
			left: 1px;
			width: 16px;
			height: 16px;
			border-radius: 2px;
			content: '';
			background: $button-gradient-primary;			
		}
	}

	.blockA {
		top: 0; left: 0;
		animation: blockA 2.4s infinite ease-in-out;
	}

	.blockB {
		top: 18px; left: 0;
		animation: blockB 2.4s infinite ease-in-out;
		animation-delay: .2s;
	}	

	.blockC {
		top: 18px; left: 18px;
		animation: blockC 2.4s infinite ease-in-out;		
		animation-delay: .4s;		
	}		
/*
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -100px;
		margin-left: -100px;
		height: 200px;
		width: 200px;
		border-radius: 50%;
		background: radial-gradient(rgba(#000, 1), rgba(#000, 0) 50%);
		content: '';
		opacity: 0.05;
	}
*/
}

@keyframes rotate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}


@keyframes blockA {
	0% { transform: translate(0, 0); }
	8.33% { transform: translate(100%, 0); }
	25% { transform: translate(100%, 0); }
	33.33% { transform: translate(100%, 100%); }
	50% { transform: translate(100%, 100%); }
	58.33% { transform: translate(0, 100%); }
	75% { transform: translate(0, 100%); }
	83.33% { transform: translate(0, 0); }
	100% { transform: translate(0, 0); }
}

@keyframes blockB {
	0% { transform: translate(0, 0); }
	8.33% { transform: translate(0, -100%); }
	25% { transform: translate(0, -100%); }
	33.33% { transform: translate(100%, -100%); }
	50% { transform: translate(100%, -100%); }
	58.33% { transform: translate(100%, 0); }
	75% { transform: translate(100%, 0); }
	83.33% { transform: translate(0, 0); }
	100% { transform: translate(0, 0); }
}

@keyframes blockC {
	0% { transform: translate(0, 0); }
	8.33% { transform: translate(-100%, 0); }
	25% { transform: translate(-100%, 0); }
	33.33% { transform: translate(-100%, -100%); }
	50% { transform: translate(-100%, -100%); }
	58.33% { transform: translate(0, -100%); }
	75% { transform: translate(0, -100%); }
	83.33% { transform: translate(0, 0); }
	100% { transform: translate(0, 0); }
}




@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}

	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}

	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}
</style>