import Vue from 'vue'
import Vuex from 'vuex'
//import { persist } from './plugins/persist'
import { crosstabs } from './plugins/crosstabs'
import { auth, initializeAuth } from './modules/static/auth'
import { main } from './modules/static/main'
import { profile } from './modules/static/profile'
import { EventBus } from './helpers'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [crosstabs],
//    plugins: [persist.plugin, crosstabs],
//    plugins: [persist.plugin],
    modules: {
        main,
        auth,
        profile
    }
})

EventBus.$on('stateRestored', () => {
	store.dispatch('main/setHiRes', window.devicePixelRatio > 1)
	const ready = () => EventBus.$emit('storeReady')
	const resetRequired = initializeAuth()
	if (resetRequired) EventBus.$on('reset', () => {
		EventBus.$off('reset')
		ready()
	})
	else ready()
})

export default store