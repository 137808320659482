import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
import mediator from '@/store/mediator'
import router from '@/router'
import VModal from 'vue-js-modal'
//import Vue2TouchEvents from 'vue2-touch-events'
import { tap } from '@/components/common/directives/tap'
import { touchHold } from '@/components/common/directives/touchHold'

/* Vue */

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.use(VModal)
//Vue.use(Vue2TouchEvents, { disableClick: true, tapTolerance: 8, touchHoldTolerance: 250 })

Vue.directive('tap', tap)
Vue.directive('touchHold', touchHold)

/* i18n */

Vue.use(VueI18n)

const loadLocaleMessages = () => {
	const locales = require.context('./languages', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages = {}
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i)
		if (matched && matched.length > 1) {
			const locale = matched[1]
			messages[locale] = locales(key)
		}
	})
	return messages
}

export const i18n = new VueI18n({
	locale: store.getters['main/activeLang'],
	fallbackLocale: 'en',
	messages: loadLocaleMessages()
})

/* Vuex mediator */

mediator(store, router, i18n)
