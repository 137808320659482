import { xor, intersection } from 'lodash'
import { pop, push, mapToMutation } from '@/store/helpers'

const max = 100

/* state */

export const selectState = {
	selected: [],
	selecting: []
}

/* getters */

export const selectGetters = {
	selected: state => key => state.selected.includes(key),
	selecting: state => key => state.selecting.includes(key),
}

/* mutations */

export const selectMutations = {
	setSelected: (state, selected) => {
		if (selected.length <= max && xor(state.selected, selected).length) state.selected = selected
	},
	setSelecting: (state, selecting) => {
		if (xor(state.selecting, selecting).length) state.selecting = selecting
	},
	toggleSelected: (state, key) => {
		if (state.selected.includes(key) || state.selected.length < max) state.selected = xor(state.selected, [key]) 
	},
	popSelected: pop('selected'),
	pushSelected: push('selected'),
	clearSelected: state => state.selected = [],
	clearSelecting: state => state.selecting = [],
	selectUpTo: (state, { key }) => {
		if (state.selected.length) {
			//console.log(list, state.selected)
		} else {
			state.selected.push(key)
		}
	}
}

/* actions */

export const selectActions = {
	setSelected: mapToMutation('setSelected'),
	setSelecting: mapToMutation('setSelecting'),
	popSelected: mapToMutation('popSelected'),
	toggleSelected: mapToMutation('toggleSelected'),
	selectUpTo: async ({ state, commit, dispatch }, { key, list }) => {
		await dispatch('toggleSelected', key)
		if (intersection(list, state.selected).length > 1) {
			const index = list.indexOf(key)
			if (index < list.length - 1) {
				const after = []
				for (let i=index+1; i<list.length; i++) {
					after.push(list[i])
					if (state.selected.includes(list[i])) {
						commit('pushSelected', after)
						break
					}
				}
			}
			if (index > 0) {
				const before = []
				for (let i=index-1; i>=0; i--) {
					before.push(list[i])
					if (state.selected.includes(list[i])) {
						commit('pushSelected', before)
						break
					}
				}
			}
		}
	},
	clearSelected({ commit }) {
		commit('clearSelected')
	},
	clearSelecting({ commit }) {
		commit('clearSelecting')
	}
}