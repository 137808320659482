<template>
<div class='loginWrapper' v-if='!$store.state.auth.swapping'>

	<div class='loginPanel' :class='{ shake: shakeLogin }'><div>

		<div class='logoWrapper'>
			<img v-if="scolibalance" class="scolibalance" src="/img/scolibalance.png">
			<AnimatedLogo v-else />
		</div>

			<div class='inputsWrapper' :class='{ scolibalance }'>
				<transition name='rtl'>

					<div :key='1' v-if='secondaryAuth' class='secondaryAuth'>
						
						<div class='secondaryAuthText'>
							<div v-html="fill($t('views.login.secondaryAuth.p1'), { username: secondaryAuthUsername })" />
							<div v-html="fill($t('views.login.secondaryAuth.p2'), { email: secondaryAuthEmail })" />
							<div>{{$t('views.login.secondaryAuth.p3')}}</div>
						</div>
						<div v-if='loading'><Loading /></div>	

						<form v-else class='creds' action='' onsubmit='return false'>
							<TextInput 
								ref='authCode'
								v-model='authCode'
								@input='onCodeInput'
								maxlength='6'
								:numeric='true'
								placeholder='××××××'
							/>																								
						</form>	

					</div>
					<div :key='2' v-else class='primaryAuth'>
							
						<form class='creds' action='' onsubmit='return false'>
							<TextInput v-model='token' :placeholder='$t("views.login.phToken")' @submit='() => password ? submit() : $refs.password.focus()' ref='token' />
							<PasswordInput ref='password' v-model='password' :placeholder='$t("views.login.phPassword")' @submit='submit' />
						</form>

						<div class='loginButton textButton primary' @click='submit'>
							<Loading v-if='loading' />
							<span v-else class='label'>{{ $t('views.login.login') }}</span>
						</div>

						<template v-if="scolibalance">

							<a class="madeWith" href="https://simpleset.net">
								<AnimatedLogo :text="false" />
								<span>Made with SimpleSet</span>
							</a>

						</template>

						<template v-else>
							<div class='or'>&mdash; {{ $t('views.login.or') }} &mdash;</div>

							<div class='demoButton textButton' @click='demo'>
								<span class='label'>{{ $t('views.login.demo') }}</span>
							</div>
						</template>	
						
					</div>

				</transition>
			</div>

	</div></div>

	<div v-if="!scolibalance" class='website'>Visit our website: <a href='https://simpleset.net'>https://simpleset.net</a></div>

	<form @click='resetPassword=true' class='forgotPassword' :class='{ shake: shakeReset }' action='' onsubmit='return false'>
		<transition name='fade' mode='out-in'>
			<ResetPassword v-if='resetPassword' @success='resetPassword=false' @err='onResetErr' />
			<template v-else><div>{{$t('views.login.forgotPassword')}}</div></template>
		</transition>
	</form>

</div>
</template>

<script>
import { authApi } from '@/services/api/modules/auth'
import { fill } from '@/utilities'
import { smallModalMixin } from '@/components/common/mixins/modal'
import AnimatedLogo from '@/components/common/AnimatedLogo'
import TextInput from '@/components/common/TextInput'
import PasswordInput from '@/components/common/PasswordInput'
import Loading from '@/components/common/Loading'
import AlertModal from '@/components/common/modals/Alert'
import ResetPassword from '@/components/login/ResetPassword'
import TextInputModal from '@/components/common/modals/TextInput'

export default {
	name: 'Login',
	mixins: [smallModalMixin],
	components: { AnimatedLogo, TextInput, PasswordInput, Loading, ResetPassword },
	data() {
		return {
			fill,
			token: '',
			password: '',
			shakeLogin: false,
			shakeReset: false,
			resetPassword: false,
			loading: false,
			secondaryAuth: false,
			secondaryAuthUsername: null,
			secondaryAuthEmail: null,
			secondaryAuthCode: null,
			authCode: null,
		}
	},
	computed: {
		scolibalance() {
			return this.$store.state.main.scolibalance
		}
	},
	methods: {
		async submit() {
			if (this.loading) return
			this.loading = true
			this.$refs.token.emitInput()
			const [err] = await authApi.touch('login', { token: this.token, password: this.password })
			if (err) {
				this.loading = false
				this.resetFields()
				return this.onLoginErr() 
			} else {
				const profile = await this.$store.dispatch('profile/fetch')
				if (profile.user.closed && !profile.user.manager) {
					this.loading = false
					this.resetFields()					
					if (profile.checkoutUri) {
						window.location.replace(profile.checkoutUri) 
					} else if (profile.user.clinicianType==='student') {
						this.showStudentClosed()
					} else if (!profile.user.orgId) {
						if (window.location.hostname === 'simpleset.dev') {
							return window.location.replace("https://boarding.simpleset.dev/#/billing")
						} else {
							return window.location.replace("https://boarding.simpleset.app/#/billing")
						}					
					} else {
						this.showStaffClosed()
					}
				} else if (profile.organization && profile.organization.closed && !profile.user.manager) {
					this.loading = false
					this.resetFields()					
					this.onLoginErr() 
				} else if (profile.secondaryAuthRequired) {
					this.loading = false
					this.resetFields()					
					this.secondaryAuth = true
					this.secondaryAuthUsername = profile.user.username
					this.secondaryAuthEmail = profile.user.email 
				} else {
					this.$store.commit('auth/login')
				}
			}
		},
		demo() {
			if (this.loading) return
			const fn = async email => {
				this.$emit('close')
				this.loading = true
				const [err] = await authApi.touch('loginDemo', { email })
				if (err) {
					this.loading = false
					this.onLoginErr()
				} else {
					console.log(await this.$store.dispatch('profile/fetch'))
					this.$store.commit('auth/login')
				}
			}
			this.showSmallModal(TextInputModal, {
				title: 'Email Required',
				placeholder: 'Email...',
				maxlength: 100,
				value: '',
				button: 'Login',
				hideValue: true,
				fn
			})	
		},
		async onCodeInput() {
			const code = this.authCode
			if(/\d{6}/.test(code)) {
				this.loading = true			
				const [err] = await authApi.touch('loginSecondary', { code })
				if (err) {
					this.loading = false		
					this.authCode = null					
					this.secondaryAuth = false					
					this.onLoginErr()	
				} else {
					this.$store.commit('auth/login')
				}
			}
		},
		resetFields() {
			this.token = this.password = ''
		},
		showStaffClosed() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.login.staffClosed.title'),
				message: this.$t('views.login.staffClosed.p1')
			})
		},
		showStudentClosed() {
			this.showSmallModal(AlertModal, {
				title: this.$t('views.login.studentClosed.title'),
				message: [
					this.$t('views.login.studentClosed.p1'), 
					this.$t('views.login.studentClosed.p2'), 
					this.$t('views.login.studentClosed.p3')
				]
			})
		},
		onLoginErr() {
			this.shakeLogin = true
			setTimeout(() => {
				this.shakeLogin = false				
			}, 1000)
		},
		onResetErr() {
			this.shakeReset = true
			setTimeout(() => this.shakeReset = false, 1000)	
		}
	},
	mounted() {
		if (window.location.href.split('?')[1] === 'demo') this.demo()
		else this.$modal.hideAll()
	}
}
</script>

<style lang='scss'>
.loginWrapper {
	@include absTopLeft;
	@include fill;
	background: linear-gradient(to top, $color-white, $color-neutral-accent);
	background: #fff;
	display: grid;
	grid-template-rows: 1fr auto auto;


	.website { font-size: $size-font-standard; text-align: center; padding: $size-gutter * 2; a { color: $color-primary-accent; font-weight: bold; }}

	.backgroundImage {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		transform: scale(1.05);
		animation: loginImage 2s $ease-out-quart;		
	}

	.loginPanel {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: translate3d(0,0,0);

		> div { position: relative; }

		.logoWrapper {
			position: absolute;
			z-index: 1;
			top: $size-gutter * 6.5;
			left: 63px;
			width: 140px;
			height: 140px;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 20px;
			animation: logozoom 2s $ease-out-quint;		

			img.scolibalance {
				position: absolute;
				top: -10px;
				left: -34px;
				width: 207px;
				transform: scale(0.93);
			}
		}

		.inputsWrapper {
			overflow: hidden;
			width: 266px;
			height: 503px;
			border-radius: 7px;
/*
			box-shadow: 0 40px 60px -2px rgba(#000000, 0.2);
*/

			box-shadow: 
				0 -100px 90px -60px #ffe3ab,
				0 100px 90px -60px #ffe3ab,
				90px 0 90px -30px #abd2ff,
				-90px 0 90px -30px #fab3ae;
			background: $color-white;
			position: relative;
			transform: translate3d(0,0,0);
			animation: loginInputs 2s linear;

			&.scolibalance {
				border: 1px solid $color-neutral-shadow;
				box-shadow: 0 40px 60px -2px rgba(#000000, 0.2);
				animation: loginInputsScoliBalance 1.5s linear;
			}

			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				padding-top: 130px + $size-gutter * 12;
				padding-left: $size-gutter * 3;
				padding-right: $size-gutter * 3;
				padding-bottom: $size-gutter * 2;				
			}

			.creds {
				display: grid;
				background: $color-neutral-shadow;
				grid-gap: 1px;
				border: 1px solid $color-neutral-shadow;
				border-radius: 3px;
				overflow: hidden;
			}

			.primaryAuth {

				.loginButton {
					position: relative;
					margin-top: $size-gutter * 3;
					font-weight: bold;
					height: $size-control-height * 2;
					border-radius: 3px;			
					.loading { background: none; }
					.spinner { background: none; border: none; box-shadow: none; > div:after { background: #fff; } }
				}

				.or {
					display: flex;
					align-items: center;
					justify-content: center;
					height: $size-control-height;
					font-size: $size-font-standard;
				}

				.madeWith {
					display: flex;
					align-items: center;
					justify-content: center;
					height: $size-control-height;
					font-size: $size-font-large;	
					margin-top: 30px;
					text-decoration: none; 
 					color: $color-black;
					transform: scale(0.75);
					&:hover { text-decoration: underline; cursor: pointer; }
					.animatedLogo { flex: 0; min-width: 40px; margin-right: $size-gutter * 2; }				
				}

				.demoButton {
					box-sizing: content-box;
					font-weight: bold;
					border-radius: 3px;
					border: 1px solid $color-neutral-shadow;
					&:hover { background: $color-white; }
				}

			}

			.secondaryAuth {

				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;

				.secondaryAuthText {
					padding: 0 $size-gutter * 2;
					font-size: $size-font-standard;
					line-height: $size-lineHeight-standard;
					b { color: $color-primary-accent; }
					> div { margin-bottom: $size-gutter * 2; }
					text-align: center;
				}

				.creds { 
					margin: 0 $size-gutter * 2; 
					grid-template-columns: 1fr;
					input { 
						box-sizing: border-box;
						padding-left: $size-gutter * 4.2; 
						font-size: $size-font-large; 
						letter-spacing: $size-gutter * 2.55; 
					}
				}

				>div:last-child { position: relative; height: $size-control-height; width: 100%; }

			}
		}

		.loading {
		}

	}

	.forgotPassword {
		position: relative;
		border-radius: 3px;
		border: 1px solid $color-neutral-shadow;
		overflow: hidden;
		top: -1px;
		height: $size-control-height;
		width: 220px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $size-gutter * 2 - 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-standard;
		text-decoration: none;
		background: $color-white;
		cursor: pointer;
		box-sizing: content-box; 
		animation: loginForgotPw 2000ms $ease-out-quart;
	}

}

@keyframes zoomout {
	0% { transform: scale(2) translate3d(0,200px,0); }
	100% { transform: scale(1) translate3d(0,0,0); }
}

@keyframes logozoom {
	0% { transform: scale(2); }
	100% { transform: scale(1); }
}


@keyframes loginImage {
	0% { transform: scale(1); opacity: 0; }
	100% { transform: scale(1.05); opacity: 1; }
}

@keyframes loginInputs {
	0% { 
		opacity: 0; 
		box-shadow: 
				0 0 90px -60px #ffe3ab,
				0 0 90px -60px #ffe3ab,
				0 0 90px -30px #abd2ff,
				0 0 90px -30px #fab3ae;
	}
	25% { 
		opacity: 0; 
		box-shadow: 
				0 0 90px -60px #ffe3ab,
				0 0 90px -60px #ffe3ab,
				0 0 90px -30px #abd2ff,
				0 0 90px -30px #fab3ae;
	}
	100% { 
		opacity: 1;
		box-shadow: 
				0 -100px 90px -60px #ffe3ab,
				0 100px 90px -60px #ffe3ab,
				90px 0 90px -30px #abd2ff,
				-90px 0 90px -30px #fab3ae;
	}
}

@keyframes loginInputsScoliBalance {
	0% { 
		opacity: 0; 
	}
	100% { 
		opacity: 1;
	}
}

@keyframes loginForgotPw {
	0% { opacity: 0; transform: translateY(20px); }
	30% { opacity: 0; transform: translateY(20px); }
	100% { opacity: 1; }
}
</style>