import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchAll: '/',
		fetchFavourite: '/favourite',
		fetchCustom: '/custom',



		fetchUser: '/user',
		fetchTeam: '/team',
		//fetchCategory: '/category/${id}',
		fetchStock: '/stock',
		fetchIds: '/${ids}',

		fetchHistoryList: '/${id}/history',
		fetchHistoryPoint: '/history/${id}'
	},
	post: {
		save: '/',
		saveFavourites: '/?favourite=true'
	},
	put: {
		update: '/${id}',	
		updateFavourite: '/${id}/favourite',
		moveToLeftOf: '/${id}/toLeftOf/${targetId}',
		moveToRightOf: '/${id}/toRightOf/${targetId}',
		moveFavouriteToLeftOf: '/favourite/${id}/toLeftOf/${targetId}',
		moveFavouriteToRightOf: '/favourite/${id}/toRightOf/${targetId}',

		moveElementsToFolder: '/${ids}/folder/${folderId}',
		moveElementsToStack: '/${ids}/stack/${stackId}'
	},
	delete: {
		destroy: '/${ids}'
	}
}

export const elementsApi = buildApi('elements', routes, '/clinician/exercises')

