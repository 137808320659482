import Vue from 'vue'
import { spread, merge, reset, setByKey, mapToMutation, accumulate } from '@/store/helpers'

/* state */

const state = () => ({})

/* getters */

const getters = {}

/* mutations */

const mutations = {
	spread: spread(),
	merge: merge(),
	remove: (state, keys) => keys.forEach(key => Vue.delete(state, key)),
	reset: reset(state()),
	setClinician: setByKey(null, 'clinicianName'),
	resetPortal: (state, key) => Vue.delete(state[key], 'username'),
	incOutcomeCount: (state, key) => state[key].outcomeCount++
}

/* actions */

const actions = {

	parseOver({ commit }, clients) {
		if (!clients) return
		if (!Array.isArray(clients)) clients = [clients]
		const parsed = accumulate(clients)
		commit('spread', parsed)
		return clients.map(client => client.key)
	},

	parseMerge({ commit }, clients) {
		if (!clients) return
		if (!Array.isArray(clients)) clients = [clients]
		const parsed = accumulate(clients)
		commit('merge', parsed)
	},

	updateClinician({ rootState, commit }, key) {
		const value = rootState.profile.user.firstname +' '+ rootState.profile.user.lastname
		commit('setClinician', { key, value })
	},

	resetPortal({ commit }, key) {
		commit('resetPortal', key)
	},

	incOutcomeCount: mapToMutation('incOutcomeCount'),
	remove: mapToMutation('remove'),
	reset: mapToMutation('reset')

}

export const clients = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})