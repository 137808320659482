<template>
<input
	type='password'
	class='passwordInput'
	ref='passwordInput'
	@input='onInput'
	@keyup.enter='onSubmit'
	:value='value'
	:placeholder='placeholder'
	:autocomplete='stopFill ? `new-password` : ``'
/>
</template>

<script>
export default {
	name: 'PasswordInput',
	props: ['placeholder', 'value', 'stopFill'],
	methods: {
		onSubmit(e) {
			this.emitInput(e)
			this.$emit('submit')
		},
		onInput(e) {
			this.emitInput(e)
		},
		emitInput(e) {
			this.$emit('input', e.target.value)
		},
		focus() {
			this.$refs.passwordInput.focus()
		},
		reset() {
			this.$refs.passwordInput.value = ''
		}
	}
}
</script>

<style lang='scss'>
.passwordInput {
	width: 100%;
	height: $size-control-height;
	background: $color-white;
	padding-left: $size-gutter * 2;
	padding-right: $size-gutter * 2;
}
</style>

