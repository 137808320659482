import Vue from 'vue'
import { pick } from 'lodash'
import { spread, merge, reset, mapToMutation, accumulate, setByKey } from '@/store/helpers'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { staffApi } from '@/services/api/modules/manager/staff'

const callApi = buildApiCaller(staffApi)

/* state */

const state = {
	...apiState,
	staff: {}
}

/* getters */

const getters = {}

/* mutations */

const mutations = {
	...apiMutations,
	spreadInStaff: spread('staff'),
	reset: state=>state.staff={}
}

const actions = {

	parseOver({ commit }, { items }) {
		const parsed = accumulate(items)
		commit('spreadInStaff', parsed)
	},

	async fetch({ commit, dispatch }, payload) {
		const [err, result] = await callApi({ commit, route: 'fetch', payload })
		if (!err){
			const parsed = accumulate(result.items)
			commit('spreadInStaff', parsed)
		}
		return [err, result]
	},


	async save({ state, commit, dispatch }, { staffId, teamId, payload }) {
		const staffAttrs = pick(payload, [`closed`,`orgExercises`,`orgTemplates`,`orgEducation`,`orgOutcomes`,`allowPHI`,`secondaryAuth`,`manager`,`clinician`])
		const teamAttrs = pick(payload, [`teamExercises`,`teamTemplates`,`teamEducation`,`teamOutcomes`,`teamClients`,`teamSwap`])
		if (Object.keys(staffAttrs).length) {
			const [err, result] = await callApi({ commit, route: 'saveStaff', payload: { staffId, ...staffAttrs } })
			if (!err) {
				if (
					(payload.closed===false && state.staff[staffId].closed) || 
					(payload.clinician===true && !state.staff[staffId].clinician)
				) {
					commit('profile/decOrgInvitations', 1, { root: true })
				} else if (payload.closed===true && !state.staff[staffId].closed) {
					commit('profile/incOrgInvitations', 1, { root: true })
				}		
				Vue.set(state.staff, staffId, result)
			}
		}
		if (Object.keys(teamAttrs).length) {
			const [err, result] = await callApi({ commit, route: 'saveStaffTeam', payload: { staffId, teamId, ...teamAttrs } })
			if (!err) Vue.set(state.staff[staffId].teams, state.staff[staffId].teams.findIndex(t=>t.teamId===teamId), result)
		}
	},

	async joinTeam({ state, commit }, { staffId, teamId }) {
		const [err, result] = await callApi({ commit, route: 'joinStaffTeam', payload: { staffId, teamId }})
		if (!err) {
			Vue.set(state.staff, staffId, result)			
		}
	},

	async leaveTeam({ state, commit }, { staffId, teamId }) {
		const [err, result] = await callApi({ commit, route: 'leaveStaffTeam', payload: { staffId, teamId }})
		if (!err) {
			Vue.set(state.staff, staffId, result)			
		}
	},	

/*

	async create({ commit, dispatch }, title ) {
		const payload = { title, teamExercises: 'readWrite', teamFolders: 'readWrite', teamEducation: 'readWrite', teamOutcomes: 'readWrite', teamClients: true }
		const [err] = await callApi({ commit, route: 'create', payload })
		if (!err) dispatch('fetchTop')
	},

	async destroy({ commit, dispatch }, id) {
		const [err] = await callApi({ commit, route: 'destroy', payload: { id: id }})
		if (!err) {
			commit('removeTeam', id)
		}
	},

	toggleTeamExercises({ state, dispatch }, key) {
		if (state.teams[key].teamExercises === 'readWrite') dispatch('save', { teamExercises: false, key })
		else if (state.teams[key].teamExercises === 'readOnly') dispatch('save', { teamExercises: 'readWrite', key })
		else if (state.teams[key].teamExercises === false) dispatch('save', { teamExercises: 'readOnly', key })
	},

	toggleTeamFolders({ state, dispatch }, key) {
		if (state.teams[key].teamFolders === 'readWrite') dispatch('save', { teamFolders: false, key })
		else if (state.teams[key].teamFolders === 'readOnly') dispatch('save', { teamFolders: 'readWrite', key })
		else if (state.teams[key].teamFolders === false) dispatch('save', { teamFolders: 'readOnly', key })
	},

	toggleTeamEducation({ state, dispatch }, key) {
		if (state.teams[key].teamEducation === 'readWrite') dispatch('save', { teamEducation: false, key })
		else if (state.teams[key].teamEducation === 'readOnly') dispatch('save', { teamEducation: 'readWrite', key })
		else if (state.teams[key].teamEducation === false) dispatch('save', { teamEducation: 'readOnly', key })
	},

	toggleTeamOutcomes({ state, dispatch }, key) {
		if (state.teams[key].teamOutcomes === 'readWrite') dispatch('save', { teamOutcomes: false, key })
		else if (state.teams[key].teamOutcomes === 'readOnly') dispatch('save', { teamOutcomes: 'readWrite', key })
		else if (state.teams[key].teamOutcomes === false) dispatch('save', { teamOutcomes: 'readOnly', key })
	},

	toggleOrgExercises({ state, dispatch }, key) {
		if (state.teams[key].orgExercises === 'readWrite') dispatch('save', { orgExercises: false, key })
		else if (state.teams[key].orgExercises === 'readOnly') dispatch('save', { orgExercises: 'readWrite', key })
		else if (state.teams[key].orgExercises === false) dispatch('save', { orgExercises: 'readOnly', key })
	},

	toggleOrgFolders({ state, dispatch }, key) {
		if (state.teams[key].orgFolders === 'readWrite') dispatch('save', { orgFolders: false, key })
		else if (state.teams[key].orgFolders === 'readOnly') dispatch('save', { orgFolders: 'readWrite', key })
		else if (state.teams[key].orgFolders === false) dispatch('save', { orgFolders: 'readOnly', key })
	},

	toggleOrgEducation({ state, dispatch }, key) {
		if (state.teams[key].orgEducation === 'readWrite') dispatch('save', { orgEducation: false, key })
		else if (state.teams[key].orgEducation === 'readOnly') dispatch('save', { orgEducation: 'readWrite', key })
		else if (state.teams[key].orgEducation === false) dispatch('save', { orgEducation: 'readOnly', key })
	},

	toggleOrgOutcomes({ state, dispatch }, key) {
		if (state.teams[key].orgOutcomes === 'readWrite') dispatch('save', { orgOutcomes: false, key })
		else if (state.teams[key].orgOutcomes === 'readOnly') dispatch('save', { orgOutcomes: 'readWrite', key })
		else if (state.teams[key].orgOutcomes === false) dispatch('save', { orgOutcomes: 'readOnly', key })
	},

	toggleTeamClients({ state, dispatch }, key) {
		dispatch('save', { teamClients: !state.teams[key].teamClients, key })
	},

	toggleTeamSwap({ state, dispatch }, key) {
		dispatch('save', { teamSwap: !state.teams[key].teamSwap, key })
	},

	toggleRestrictPhi({ state, dispatch }, key) {
		dispatch('save', { restrictPhi: !state.teams[key].restrictPhi, key })
	},

	toggle2FA({ state, dispatch }, key) {
		dispatch('save', { secondaryAuth: !state.teams[key].secondaryAuth, key })
	},	

	teamHeaderFooter({ state, dispatch }, key) {
		dispatch('save', { teamHeaderFooter: !state.teams[key].teamHeaderFooter, key })
	},

	setTitle({ dispatch }, { key, value }) {
		dispatch('save', { title: value, key })
	},

*/

}

export const staff = () => ({
	namespaced: true,
	state,
	getters,
	mutations,
	actions
})