import { isPlainObject, pick, mapValues, isObject, transform, get, set } from 'lodash'

export const replaceKeysDeep = (obj, keysMap) =>
	transform(obj, (result, value, key) => {
		const currKey = keysMap[key] || key
		result[currKey] = isObject(value) ? replaceKeysDeep(value, keysMap) : value
	})

// Distance
// Execute fn when dragging a certain distance
export class Distance {
	constructor(options) {
		this._cx = options.cx
		this._startX = options.e.pageX
		this._startY = options.e.pageY
		this._distance = options.distance || 6
		this._onStart = options.onStart
		this._onStop = options.onStop
		this._onPull = options.onPull
		this._start()
	}
	_start() {
		if (typeof this._onStart === 'function') this._onStart()
		document.addEventListener('pointermove', this._onMouseMove)
		document.addEventListener('pointerup', this._onMouseUp)
	}
	_stop() {
		if (typeof this._onStop === 'function') this._onStop()
		document.removeEventListener('pointermove', this._onMouseMove)
		document.removeEventListener('pointerup', this._onMouseUp)
	}
	_onMouseMove = e => {
		if (
			Math.abs(this._startX - e.pageX) > this._distance ||
			Math.abs(this._startY - e.pageY) > this._distance
		) {
			this._stop()
			this._onPull()
		}
	}
	_onMouseUp = () => {
		this._stop()
		if (typeof this._cx === 'function') this._cx()
	}
}


// UI Prefs
export class UI {
	constructor(userId) {
		this._userId = userId 
		const prefs = localStorage.getItem(userId)
		this._prefs = prefs ? JSON.parse(prefs) : {}
	}
	get(path) {
		return get(this._prefs, path)
	}
	set(path, val) {
		set(this._prefs, path, val)
		localStorage.setItem(this._userId, JSON.stringify(this._prefs)) 
	}
}

// Extension
// Return extension from filename
export const extension = filename => filename.split('.').pop().toLowerCase()

// Fill
// Simple es6 template literals token replacement
// eg:
// const template = 'The ${cat} chased the ${dog}'
// const data = { cat: 'cat', dog: 'dog' }
// fill(template, data) => 'The cat chased the dog'
export const fill = (templateString, templateVariables) => {
	const keys = Object.keys(templateVariables)
	const values = Object.values(templateVariables)
	const templateFunction = new Function(...keys, `return \`${templateString}\``)
	return templateFunction(...values)
}

// DataURIToBlob
// convert base64/URLEncoded data component to raw binary data held in a string
export const dataURItoBlob = (dataURI) => {
	var byteString
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1])
	else
		byteString = unescape(dataURI.split(',')[1])
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
	const ia = new Uint8Array(byteString.length)
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i)
	}
	return new Blob([ia], { type: mimeString })
}

// Deep map
// Given a deeply nested object
// Runs fn on each node
export const deepMap = (obj, fn) =>
	fn(mapValues(obj, (v) => {
		if (Array.isArray(v)) return v.map(item => deepMap(item, fn))
		else if (isPlainObject(v)) return deepMap(v, fn)
		else return v
	}))

// Difference
// Given two arrays a & b, return all values present in a that are not present in b
// eg:  [1,2,3] [3,4] => [1,2]
export const diff = (a, b) => a.filter(c => !b.includes(c))

// Convert html entities
// Include conversion of space to nbsp
export const htmlEntities = str => String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/\s/g, '&nbsp;')

// Validate email
export const isEmail = str => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str)

// Mobile
export const isMobile = () => 'ontouchend' in document

// Pluck
// Given an array of objects
// Returns an array of picked object
// Given an object, behaves like pick
export const pluck = (data, attrs) => {
	if (Array.isArray(data)) return data.map(obj => pick(obj, attrs))
	else return pick(data, attrs)
}

// Repeat
// eg const tick = new Repeat(fn, 1000); tick.start();
export class Repeat {
	constructor(fn, delay) {
		this._running = false
		this._fn = fn
		this._delay = delay
	}
	start() {
		this._running = true
		this._repeat()
	}
	stop() {
		this._running = false
	}
	_repeat() {
		this._fn()
		if (this._running) setTimeout(() => this._repeat(), this._delay)
	}
}

// Strip html
export const stripHtml = html =>{
	const doc = new DOMParser().parseFromString(html, 'text/html')
	return doc.body.textContent || ''
}

export const setAuthToken = token => {
	const authToken = encodeURIComponent(token.toLowerCase().trim())
	const domain = window.location.hostname.includes('dev') ? '.simpleset.dev' : '.simpleset.app'
	document.cookie = 'authToken='+authToken+'; max-age=86400; path=/; domain='+domain	
}

export const arrToKeyedObj = (items, key = "id") =>
	(Array.isArray(items) ? items : [items]).reduce((acc, curr) => {
		acc[curr[key]] = curr
		return acc
	}, {})


