import { uniq } from 'lodash'
import { set, reset, mapToMutation } from '@/store/helpers'

/* state */

const state = () => ({
	tab: 'all',
	folderId: null,
//	stockRootId: null,
//	userRootId: null,
//	teamRootId: null,
//	orgRootId: null,
	selectedIds: [],
	search: '',
	searched: '',
	sortColumn: null,
	sortDirection: null,
})

/* getters */

const getters = {}

/* mutations */

const mutations = {
	setTab: set('tab'),
	setFolderId: set('folderId'),
//	setStockRootId: set('stockRootId'),
//	setUserRootId: set('userRootId'),
//	setTeamRootId: set('teamRootId'),
//	setOrgRootId: set('orgRootId'),
	setSelectedIds: (state, value) => value.length > 12 ? state.selectedIds = value.slice(0,12) : state.selectedIds = value, //set('selectedIds'),
	addSelectedIds: (state, value) => {
		const ids = uniq([...state.selectedIds, ...value])
		ids.length > 12 ? state.selectedIds = ids.slice(0,12) : state.selectedIds = ids
	},
	toggleSelectedId: (state, value) => !state.selectedIds.includes(value) && state.selectedIds.length < 12 ? 
		state.selectedIds.push(value) : state.selectedIds.includes(value) ?
		state.selectedIds.splice(state.selectedIds.indexOf(value), 1) : null,
	setSearch: set('search'),
	setSearched: set('searched'),
    setSortColumn: set('sortColumn'),
    setSortDirection: set('sortDirection'),	
	reset: reset(state())
}

/* actions */

const actions = {
	setTab: mapToMutation('setTab'),
	setFolderId: mapToMutation('setFolderId'),
//	setStockRootId: mapToMutation('setStockRootId'),
//	setUserRootId: mapToMutation('setUserRootId'),
//	setTeamRootId: mapToMutation('setTeamRootId'),
//	setOrgRootId: mapToMutation('setOrgRootId'),
	setSelectedIds: mapToMutation('setSelectedIds'),
	addSelectedIds: mapToMutation('addSelectedIds'),
	toggleSelectedId: mapToMutation('toggleSelectedId'),
	setSearch: mapToMutation('setSearch'),
	setSearched: mapToMutation('setSearched'),
	reset: mapToMutation('reset'),
    toggleColumn({ state, commit }, column) {
        if (state.sortColumn !== column) {
            commit('setSortColumn', column)
            commit('setSortDirection', 'asc')
        } else if (state.sortColumn === column && state.sortDirection === 'asc') {
            commit('setSortDirection', 'desc')
        } else if (state.sortColumn === column && state.sortDirection === 'desc') {
            commit('setSortColumn', null)
            commit('setSortDirection', null)
        }
    },
}

export const manageTemplates = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})