import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchClients: '/',
		fetchClientSetsByClient: '/${id}/sets',
		fetchClientOutcomesByClient: '/${id}/outcomes',
		fetchClient: '/${id}',
		fetchClientFeedback: '/${id}/feedback',
		fetchClientLogs: '/${id}/logs',
		fetchClientLogDays: '/${id}/logs/days'
	},
	post: {
		save: '/'
	},
	put: {
		update: '/${id}',
		emailExists: '/emailExists',
		archive: '/${ids}/archived?value=${state}',
		resetPortal: '/${id}/reset',
		share: '/share'
	},
	delete: {
		destroy: '/${ids}'
	}
}

export const clientsApi = buildApi('clients', routes, '/clinician/clients')

