import store from '@/store'
import { profileApi } from '@/services/api/modules/profile'
import { authApi } from '@/services/api/modules/auth'
import { reset, set, mapToMutation } from '@/store/helpers'
import { Repeat, setAuthToken } from '@/utilities'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'

const callApi = buildApiCaller(authApi)

const state = {
	...apiState,
	authenticated: false,
	updated: null,
	created: null,
	swapping: false
}

const getters = {}

const mutations = {
	...apiMutations,
	login: state => {
		state.authenticated = true
		state.updated = Date.now()
		state.created = Date.now()
	},
	touch: state => {
		state.updated = Date.now()
	},
	setSwapping: set('swapping'),
	reset: reset({
		...apiState,
		authenticated: false,
		updated: null,
		created: null
	})
}

const actions = {
	/*
	login: async ({ commit, dispatch, rootState }, credentials) => {
		const { token } = credentials
		const [err, { result }] = await callApi({ commit, payload: credentials, route: 'login' })
		if (err) {
			return false
		}
		const [err, profile] = await profileApi.touch('fetch')
		const profile = await dispatch('profile/fetch', { root: true })

	},
	loginSecondary: async ({ commit }, payload) => {
		const [err, result] = await callApi({ commit, payload, route: 'loginSecondary' })
		if (err) {
			return false
		} else {
			commit('main/setBootstrap', result, {root: true})			
			commit('login', result)
			//setTimeout(resetAll, 0)
			return 'success'
		}
	},	
	*/

	bootstrap: async ({ commit, dispatch }) => {
		const [err, profile] = await profileApi.touch('fetch')
		if (
			err || 
			!profile.user.enabled || 
			(!profile.user.manager && profile.user.closed) || 
			profile.secondaryAuthRequired
		) {
			return false
		} else {
			await dispatch('profile/init', profile, { root: true })
			commit('login')	
			return true		
		}
	},
	loginDemo: async ({ commit, rootState }, email) => {
		const [err, bootstrap] = await callApi({ commit, payload: { lang: rootState.main.lang }, route: 'loginDemo' })
		if (!err) {
			setAuthToken('demo')
			commit('main/setBootstrap', bootstrap, {root: true})			
			commit('login', bootstrap)
			//setTimeout(resetAll, 0)
			return 'success'
		}
	},
	logout: async ({ commit, state }, logoutServer = true) => {
		if (state.authenticated) {
			if (logoutServer) await callApi({ route: 'logout' })
			commit('reset')
		}
	},
	ping: async ({ commit }) => {
		if (state.authenticated) {
			await callApi({ commit, route: 'ping', silent: true })
			commit('touch')
		}
	},
	setSwapping: mapToMutation('setSwapping')
}

export const auth = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}

export const initializeAuth = () => {
	const resetRequired = () => {
		if (!store.state.auth.authenticated || !store.state.profile) return false
		const timeout = store.state.profile.user.timeout * 60 * 1000
		return Date.now() - store.state.auth.updated > timeout
	}
	const result = resetRequired()
	new Repeat(() => {
		if (resetRequired()) store.dispatch('auth/logout')
	}, 1000).start()
	return result
}