import { accumulate, collapse, combine, setKey } from '@/store/helpers'

export const setPresetKeyOnParameters = presets => {
	presets.map(preset => preset.parameters.map(parameter => parameter.presetKey = preset.key))
	return presets
}

export const parseNestedPresets = nestedPresets => {
	setKey(nestedPresets)
	setKey(nestedPresets, ['parameters'])
	const presets = collapse(nestedPresets, ['parameters'])
	const parameters = combine(setPresetKeyOnParameters(nestedPresets), 'parameters')
	
	return [
		accumulate(presets),
		accumulate(parameters)
	]
}
