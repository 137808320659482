import Vue from 'vue'
import { cloneDeep, compact, flatten, isPlainObject, uniq, without, xor, isEqual, difference } from 'lodash'
import uniqid from 'uniqid'

export const EventBus = new Vue()

export const simpleState = {}
export const resetSimpleState = () => {
	Object.keys(simpleState).forEach(key => {
		if (key !== 'swap') delete simpleState[key]
	})
}

/*
* Mutations
*/

export const set = (path, defaultValue) => (state, value) => {
	if (defaultValue === undefined) {
		if (!isEqual(state[path], value)) state[path] = value
	} else {
		if (!isEqual(state[path], defaultValue)) state[path] = cloneDeep(defaultValue)
	}
}

export const unset = path => (state, keys) => {
	if (!Array.isArray(keys)) keys = [keys]
	keys.forEach(k => Vue.delete(path ? state[path] : state, k))
}

export const reset = initialState => state =>
	Object.keys(initialState).forEach(key => {
		Vue.set(state, key, cloneDeep(initialState[key]))
	})

export const spread = path => (state, obj) => {
	Object.keys(obj).forEach(key => Vue.set(path ? state[path] : state, key, isPlainObject(obj[key]) ? Object.assign({}, obj[key]) : obj[key] ))
}

export const merge = path => (state, obj) => {
	Object.keys(obj).forEach(key => {
		if (path) Vue.set(state[path], key, Object.assign({}, state[path][key], obj[key]))
		else Vue.set(state, key, Object.assign({}, state[key], obj[key]))
	})
}

export const push = path => (state, value) => {
	if (!Array.isArray(value)) value = [value]
	state[path].push(...difference(uniq(value), state[path]))
	//state[path] = uniq(state[path].concat(value))
}

export const unshift = path => (state, value) => {
	if (!Array.isArray(value)) value = [value]
	state[path].unshift(...difference(uniq(value), state[path]))
	//state[path] = uniq(value.concat(state[path]))
}

export const pop = path => (state, value) => {
	if (!Array.isArray(value)) value = [value]
	state[path] = without(state[path], ...value)
}

export const toggle = path => state => {
	state[path] = !state[path]
}

export const toggleList = path => (state, value) => {
	state[path] = xor(state[path], [value]) 
}

export const setByKey = (path, property) => (state, { key, value }) => {
	const target = path ? state[path] : state
	if (property) {
		if (!isEqual(target[key][property], value)) Vue.set(target[key], property, value)
	} else {
		if (!isEqual(target[key], value)) Vue.set(target, key, value)
	}
}

export const pushByKey = (path, property) => (state, { key, value, index }) => {
	if (!Array.isArray(value)) value = [value]
	const orig = state[path][key][property]
	if (index || index === 0) {
		orig.splice(index, 0, ...difference(uniq(value), orig))
		//Vue.set(state[path][key], property, uniq(orig))
	} else {
		orig.push(...difference(uniq(value), orig))
		//Vue.set(state[path][key], property, uniq(state[path][key][property].concat(value)))
	}
}

export const popByKey = (path, property) => (state, { key, value }) => {
	if (!Array.isArray(value)) value = [value]
	Vue.set(state[path][key], property, without(state[path][key][property], ...value))
}

export const unshiftByKey = (path, property) => (state, { key, value }) => {
	if (!Array.isArray(value)) value = [value]
	const orig = state[path][key][property]
	orig.splice(0, 0, ...difference(uniq(value), orig))
	//Vue.set(state[path][key], property, uniq(value.concat(state[path][key][property])))
}

export const toggleByKey = (path, property) => (state, key) => {
	Vue.set(state[path][key], property, !state[path][key][property])
}

/*
* Actions
*/

export const mapToMutation = mutation => ({ commit }, payload) => commit(mutation, payload)

/*
* Parsers
* For massaging data on its way in or out of the store
*/

// Accumulate
// Preserves input
// Given an object or array of objects
// return an object, with key or id as the key for each object in the array
export const accumulate = collection => (Array.isArray(collection) ? collection : [collection]).reduce((result, obj) => {
	const key = obj.key || obj.id
	if (!obj.key) obj.key = key
	result[key] = obj
	return result
}, {})

// Collapse
// Preserves input
// Given an object or collection of objects that contain collections
// convert the subcollections into an array of keys -- key before id
// eg collapse(exercises, ['resources', 'parameters'])
export const collapse = (data, properties) => {
	const fn = (a, properties) => {
		if (!Array.isArray(properties)) properties = [properties]
		const b = Object.assign({}, a)
		properties.forEach(property => {
			if (Array.isArray(b[property])) b[property] = b[property].map(obj => obj.key || obj.id)
		})
		return b
	}
	if (Array.isArray(data)) return data.map(item => fn(item, properties))
	else return fn(data, properties)
}

// Combine
// Preserves input
// Given a collection of objects that contain collections
// combine the subcollections on all objects into a single collection
export const combine = (collection, property) => compact(flatten(collection.map(obj => obj[property])))


// setKey
// Mutates input
// Can assign to a single object, to a collection, or to subcollections
// Only assigns a key if not already present
// eg setKey(elements, ['parameters', 'resources'])
export const getKey = () => uniqid()
export const setKey = (data, properties) => {
	const fn = obj => { if (isPlainObject(obj) && !obj.key) obj.key = getKey() }
	if (properties && !Array.isArray(properties)) properties = [properties]
	if (Array.isArray(data) && properties) properties.forEach(property => data.forEach(obj => setKey(obj[property])))
	else if (Array.isArray(data)) data.forEach(obj => fn(obj))
	else if (properties) properties.forEach(property => setKey(data[property]))
	else fn(data)
	return data
}