<template>
<div class='animatedLogo' :class='{ noText: !text }'>
	<svg class='logoTop'>
		<use xlink:href='#logoTop' />
	</svg>
	<svg class='logoLeft'>
		<use xlink:href='#logoLeft' />
	</svg>
	<svg class='logoRight'>
		<use xlink:href='#logoRight' />
	</svg>
	<svg v-if='text' class='logoText'>
		<use xlink:href='#logoText' />
	</svg>
	<div class='flare' />
	<div class='flare' />
</div>
</template>

<script>
export default {
	name: 'animatedLogo',
	props: {
		text: {
			type: Boolean,
			default: true
		}
	}
}
</script>

<style lang='scss'>
.animatedLogo {
	@include fill;
	animation: logo 1.5s linear;

	&.noText {
		transform: translateY(27%) scale(1.6);

	}

	svg {
		@include absTopLeft;
		@include fill;
	}

	.logoTop {
		z-index: 2;
		animation: logoTop 1.5s $ease-out-quint;
	}

	.logoLeft {
		z-index: 2;
		animation: logoLeft 1.5s $ease-out-quint;
	}

	.logoRight {
		z-index: 2;
		animation: logoRight 1.5s $ease-out-quint;
	}

	.logoText {
		z-index: 2;
		animation: logoText 1.5s $ease-out-quint;
	}

	.flare {
		position: absolute;
		top: -50%;
		left: -33%;
		width: 150%;
		height: 150%;
		z-index: 3;
		border-radius: 50%;
		background: radial-gradient(
			at center, 
			rgba(245,255,255,1) 3%, 
			rgba(245,255,255,.3) 25%,  
			rgba(250,255,255,.1) 40%,  
			rgba(255,255,255,0) 55%
		);
		opacity: .3;
		animation: logoFlare 1s linear;

		& + .flare {
			z-index: 1;
		}
	}
}

@keyframes logoTop {
	0% { transform: scale(1.2) translateY(-20px); }
	100% { transform: scale(1) translateY(0); }
}

@keyframes logoLeft {
	0% { transform: scale(1.2) translateX(-10px); }
	100% { transform: scale(1) translateX(0); }
}

@keyframes logoRight {
	0% { transform: scale(1.2) translateX(10px); }
	100% { transform: scale(1) translateX(0); }
}

@keyframes logoText {
	0% { transform: scale(.9) translateY(5px); }
	100% { transform: scale(1) translateY(0); }
}

@keyframes logoFlare {
	0% { opacity: 1; }
	100% { opacity: .3; }
}

@keyframes logo {
	0% { opacity: 0; }
	100% { opacity: 1; }
}
</style>