import Vue from 'vue'
import { spread, merge, reset, mapToMutation, accumulate, setByKey } from '@/store/helpers'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { teamsApi } from '@/services/api/modules/manager/teams'

const callApi = buildApiCaller(teamsApi)

/* state */

const state = (teams) => ({
	...apiState,
	teams: teams ? accumulate(teams) : {},
	list: []
})

/* getters */

const getters = { }

/* mutations */

const mutations = {
	...apiMutations,
	spreadInTeams: spread('teams'),
	mergeInTeams: merge('teams'),
	setLogo: setByKey('teams', 'logo'),
	setList: (state, val) => state.list = val,
	removeTeam: (state, key) => {
		state.list = state.list.filter(id=>id!==key)
		Vue.delete(state.teams, key)
	},
	reset: reset(state())
}

const actions = {

	parseOver({ commit }, { items }) {
		const colours = ['#1988de', '#31b320', '#ed8e00', '#cc1f1f', '#e918ce', '#a20de7']
		let colourIndex = 0
		const getColour = () => {
			const colour = colours[colourIndex]
			if (colourIndex===colours.length-1) colourIndex=0
			else colourIndex++
			return colour
		}
		items.forEach(item=>item.colour=getColour())
		const parsed = accumulate(items)
		commit('spreadInTeams', parsed)
	},

	parseMerge({ commit }, team) {
		const parsed = accumulate([team])
		commit('mergeInTeams', parsed)
	},

	async fetch({ commit, dispatch }) {
		const [err, result] = await callApi({ commit, route: 'fetch' })
		if (!err) await dispatch('parseOver', result)
		commit('setList', result.items.map(i=>i.id))
	},

	async save({ commit, dispatch }, payload) {
		const [err, result] = await callApi({ commit, route: payload.id ? 'update' : 'create', payload })
		if (!err) dispatch('parseMerge', result)
	},

/*

	async saveHeader({ commit, dispatch }, payload) {
		payload.id = payload.key
		delete payload.key
		const [err, result] = await callApi({ commit, route: 'update', payload })
		if (!err) dispatch('parseMerge', result)
	},

	async unsetLogo({ commit }, key) {
		commit('setLogo', { key, value: null })
		await callApi({
			commit,
			route: 'unsetLogo',
			payload: { id: key },
			silent: true 
		})
	},

	toggleTeamExercises({ state, dispatch }, key) {
		if (state.teams[key].teamExercises === 'readWrite') dispatch('save', { teamExercises: false, key })
		else if (state.teams[key].teamExercises === 'readOnly') dispatch('save', { teamExercises: 'readWrite', key })
		else if (state.teams[key].teamExercises === false) dispatch('save', { teamExercises: 'readOnly', key })
	},

	toggleTeamFolders({ state, dispatch }, key) {
		if (state.teams[key].teamFolders === 'readWrite') dispatch('save', { teamFolders: false, key })
		else if (state.teams[key].teamFolders === 'readOnly') dispatch('save', { teamFolders: 'readWrite', key })
		else if (state.teams[key].teamFolders === false) dispatch('save', { teamFolders: 'readOnly', key })
	},

	toggleTeamEducation({ state, dispatch }, key) {
		if (state.teams[key].teamEducation === 'readWrite') dispatch('save', { teamEducation: false, key })
		else if (state.teams[key].teamEducation === 'readOnly') dispatch('save', { teamEducation: 'readWrite', key })
		else if (state.teams[key].teamEducation === false) dispatch('save', { teamEducation: 'readOnly', key })
	},

	toggleTeamOutcomes({ state, dispatch }, key) {
		if (state.teams[key].teamOutcomes === 'readWrite') dispatch('save', { teamOutcomes: false, key })
		else if (state.teams[key].teamOutcomes === 'readOnly') dispatch('save', { teamOutcomes: 'readWrite', key })
		else if (state.teams[key].teamOutcomes === false) dispatch('save', { teamOutcomes: 'readOnly', key })
	},

	toggleOrgExercises({ state, dispatch }, key) {
		if (state.teams[key].orgExercises === 'readWrite') dispatch('save', { orgExercises: false, key })
		else if (state.teams[key].orgExercises === 'readOnly') dispatch('save', { orgExercises: 'readWrite', key })
		else if (state.teams[key].orgExercises === false) dispatch('save', { orgExercises: 'readOnly', key })
	},

	toggleOrgFolders({ state, dispatch }, key) {
		if (state.teams[key].orgFolders === 'readWrite') dispatch('save', { orgFolders: false, key })
		else if (state.teams[key].orgFolders === 'readOnly') dispatch('save', { orgFolders: 'readWrite', key })
		else if (state.teams[key].orgFolders === false) dispatch('save', { orgFolders: 'readOnly', key })
	},

	toggleOrgEducation({ state, dispatch }, key) {
		if (state.teams[key].orgEducation === 'readWrite') dispatch('save', { orgEducation: false, key })
		else if (state.teams[key].orgEducation === 'readOnly') dispatch('save', { orgEducation: 'readWrite', key })
		else if (state.teams[key].orgEducation === false) dispatch('save', { orgEducation: 'readOnly', key })
	},

	toggleOrgOutcomes({ state, dispatch }, key) {
		if (state.teams[key].orgOutcomes === 'readWrite') dispatch('save', { orgOutcomes: false, key })
		else if (state.teams[key].orgOutcomes === 'readOnly') dispatch('save', { orgOutcomes: 'readWrite', key })
		else if (state.teams[key].orgOutcomes === false) dispatch('save', { orgOutcomes: 'readOnly', key })
	},

	toggleTeamClients({ state, dispatch }, key) {
		dispatch('save', { teamClients: !state.teams[key].teamClients, key })
	},

	toggleTeamSwap({ state, dispatch }, key) {
		dispatch('save', { teamSwap: !state.teams[key].teamSwap, key })
	},

	toggleRestrictPhi({ state, dispatch }, key) {
		dispatch('save', { restrictPhi: !state.teams[key].restrictPhi, key })
	},

	toggle2FA({ state, dispatch }, key) {
		dispatch('save', { secondaryAuth: !state.teams[key].secondaryAuth, key })
	},	

	teamHeaderFooter({ state, dispatch }, key) {
		dispatch('save', { teamHeaderFooter: !state.teams[key].teamHeaderFooter, key })
	},

	setTitle({ dispatch }, { key, value }) {
		dispatch('save', { title: value, key })
	},

*/

	setLogo: mapToMutation('setLogo'),



	reset: mapToMutation('reset')

}

export const teams = (teams) => ({
	namespaced: true,
	state: state(teams),
	getters,
	mutations,
	actions
})