import Vue from 'vue'
import { notificationsApi } from '@/services/api/modules/clinician/notifications'
import { set, push, reset, accumulate, spread } from '@/store/helpers'

/* state */

const state = () => ({
	notifications: {},
	list: []
})

/* getters */

const getters = {}

/* mutations */

const mutations = {
	spreadNotifications: spread('notifications'),
	setList: set('list'),
	addList: push('list'),
	reset: reset(state()),
	markRead: (state, grep) => {
		const notifications = Object.values(state.notifications)
		let matches
		if (grep) {
			matches = Object.values(state.notifications).filter(n => {
				return Object.entries(grep).every(([key, value])=>n[key]===value)
			})
		} else {
			matches = notifications
		}
		matches.forEach(m=>Vue.set(m, 'viewed', true))
	}
}

/* actions */

const actions = {
	async fetch({ state, commit }) {
		const [err, result] = await notificationsApi.touch('fetch', { skip: state.list.length })
		commit('spreadNotifications', accumulate(result.items))
		commit('addList', result.items.map(i=>i.id))
	},
	async markRead({ commit }) {
		commit('markRead')
		await notificationsApi.touch('markRead')
	}
	/*
	markViewed({ state, commit }, id) {

	}
	*/
}

export const notifications = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})