import Cookies from 'js-cookie'
import { compact } from 'lodash'
import { set, push, pop, unshift, reset, toggle, mapToMutation } from '@/store/helpers'
import { selectState, selectGetters, selectMutations, selectActions } from '@/store/modules/mixins/select'

/* state */

const state = profileId => ({
	...selectState,
	list: [],
	focus: null,
	pin: null,
	tabIndex: 0,
	columns: Cookies.getJSON(`${profileId}-setColumns`) || false
})

/* getters */

const getters = { 
	...selectGetters,
	router: (state, getters, rootState) => {
		return compact(state.list.map(key => 
			rootState.sets.root[key].draftId || rootState.sets.root[key].id || null
		)).join(',') || null
	}
}

/* mutations */

const mutations = {
	...selectMutations,
	setList: set('list'),
	addList: push('list'),
	prependList: unshift('list'),
	appendList: push('list'),
	popList: pop('list'),
	clearList: set('list', []),
	setFocus: set('focus'),
	setPin: set('pin'),
	setTabIndex: set('tabIndex'),
	toggleColumns: toggle('columns'),
	reset: reset(state())
}

/* actions */

const actions = {
	...selectActions,

	fetch({ state, rootState, rootGetters, commit, dispatch }, ids) {
		if (rootState.sets.apiPending) return
		if (!Array.isArray(ids)) ids = [ids]
		const filtered = ids.filter(id => !rootGetters['sets/getIds'].includes(id) && !rootGetters['sets/getDraftIds'].includes(id))

		return new Promise((resolve, reject) => {

			if (filtered.length) {

				dispatch('sets/fetch', filtered, { root: true })
					.then(([ err, keys ]) => {
						if (!err) {
							commit('prependList', keys)
							commit('setFocus', keys[0])
							commit('setTabIndex', 0)
							return resolve(true)
						} else {
							return reject('Invalid fetch')
						}
					})

			} else {

				const needle = rootGetters['sets/getKeyById'](ids[0])
				//if (!needle) return resolve(false)
				const keys = state.list.filter(key => key !== needle)
				keys.unshift(needle)
				commit('setList', keys)
				commit('setFocus', needle)
				commit('setTabIndex', 0)
				return resolve(true)

			}

		})
	},

	fetchHistory({ commit, dispatch }, id) {
		dispatch('sets/fetchHistory', id, { root: true })
			.then(([ err, key ]) => {
				if (!err) {
					commit('prependList', key)
					commit('setFocus', key)
					commit('setTabIndex', 0)
				}
			})
	},

	createSet({ commit, dispatch }, toRight) {
		return dispatch('sets/createSet', null, { root: true }).then(key => {
			commit(toRight ? 'addList' : 'prependList', key)
			commit('setFocus', key)
		})
	},

	duplicateSet({ commit, dispatch }, setKey) {
		return dispatch('sets/duplicateSet', setKey, { root: true }).then(key => {
			commit('appendList', key)
			commit('setFocus', key)
			return key
		})
	},

	closeSet({ state, commit, dispatch, rootState }, key) {
		if (state.pin === key) commit('setPin', null)
		commit('popSelected', rootState.sets.root[key].elements)
		commit('popList', key)
		return dispatch('sets/removeSet', key, { root: true })
	},

	setSelected({ commit, state, rootState }, selected) {
		const newKeys = selected.filter(key => !state.selected.includes(key))
		if (
			state.selected.length && 
			newKeys.length &&
			rootState.sets.elements[state.selected[0]] &&
			rootState.sets.elements[state.selected[0]].setKey !== rootState.sets.elements[newKeys[0]].setKey 
		) {
			commit('setSelected', newKeys)
		} else {
			commit('setSelected', selected)
		}
	},

	toggleSelected({ commit, state, rootState, dispatch }, elementKey) {
		if (
			state.selected.length && 
			rootState.sets.elements[state.selected[0]] &&
			rootState.sets.elements[state.selected[0]].setKey !== rootState.sets.elements[elementKey].setKey 
		) {
			commit('clearSelected')
		}
		commit('toggleSelected', elementKey)
		dispatch('setFocus', rootState.sets.elements[elementKey].setKey)
	},

	removeElements({ dispatch, commit }, { elementKeys }) {
		commit('popSelected', elementKeys)
		dispatch('sets/removeElements', { elementKeys }, { root: true })
	},

	setFocus({ state, commit }, setKey) {
		if (state.focus !== setKey) commit('setFocus', setKey)
	},

	toggleColumns({ state, commit, rootState }) { 
		commit('toggleColumns')
		Cookies.set(`${rootState.profile.user.id}-setColumns`, state.columns)
	},

	togglePin({ state, commit }, setKey) {
		commit('setPin', state.pin === setKey ? null : setKey)
	},

	setList: mapToMutation('setList'),
	setTabIndex: mapToMutation('setTabIndex')
}

export const setBuilder = profileId => ({
	namespaced: true,
	state: state(profileId),
	getters,
	mutations,
	actions
})