<template>
<SmallModal class='textInputModal' @close='$emit("close")' @doDefault='doFn'>
	<template v-slot:title>{{title}}</template>
	<template v-slot:message>
		<p v-if='message'>{{message}}</p>
		<div class='inputWrapper'>
			<TextInput 
				v-model='textInput' 
				ref='textInput'
				:placeholder='placeholder' 
				:maxlength='maxlength || 100' 
				:class='{ err }'
				@input='err = false'
			/>
		</div>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='doFn'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'><span>{{button}} <template v-if='!hideValue'>{{textInput}}</template></span></span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import SmallModal from './SmallModal'
import TextInput from '@/components/common/TextInput'

export default {
	components: {
		SmallModal,
		TextInput
	},
	props: ['title', 'message', 'placeholder', 'value', 'button', 'maxlength', 'fn', 'hideValue'],
	data() { return { textInput: this.value, err: false, fnDone: false }},
	methods: {
		validate() {
			return this.textInput.length > 0
		},
		showErr() {
			this.err = true
		},
		doFn() {
			if (this.validate() && !this.fnDone) {
				this.fnDone = true
				this.$emit('close')
				if (typeof this.fn === 'function') this.fn(this.textInput)
			} else this.showErr()
		},
	},
	mounted() {
	//	if (this.value) this.textInput = this.value
//		if (!this.$store.state.main.mobile) this.$refs.textInput.focus()
		setTimeout(() => this.$refs.textInput.focus())
	}
}
</script>

<style lang='scss'>
.textInputModal {
	overflow: hidden;
	.inputWrapper {
		padding-bottom: $size-gutter;
		.textInput {
			transition: border-color .2s, background .2s;
			border: 1px solid $color-neutral-shadow;
			background: $color-white;
			&:focus { border-color: $color-neutral-shadow !important; }
			&.err { 
				background: rgba($color-alert, 0.2) !important; 
				border-color: $color-alert !important;
				&::placeholder { color: $color-alert !important; }
			}
		}
	}
	.iconTextButton {
		overflow: hidden;
	}
	.iconTextButton .label {
		overflow: hidden;

		span { 
			overflow: hidden;
			text-overflow: ellipsis;
			min-width: 0;
			white-space: nowrap;
			margin-right: $size-gutter * 2;
		}
	}
}
</style>