export const smallModalMixin = {
	methods: {
		showSmallModal(Component, params, width = '500px', height = 'auto') {
			const fn = () => this.$modal.show(Component, params, { width, height, classes: 'smallModal', transition: 'smallModalFade', clickToClose: !(params || {}).sticky })
			if (this.$store.state.main.mobile) setTimeout(fn, 100)
			else fn()
		}
	}
}

export const largeModalMixin = {
	methods: {
		showMediumModal(Component, params, width = '85%', events) {
			const fn = () => this.$modal.show(Component, params, { width, height: "85%", classes: 'smallModal', transition: 'largeModalFade', clickToClose: !(params || {}).sticky }, events)
			if (this.$store.state.main.mobile) setTimeout(fn, 100)
			else fn()
		},

		showLargeModal(Component, params, width = '100%', events) {
			const fn = () => this.$modal.show(Component, params, { width, height: "100%", classes: 'largeModal', transition: 'largeModalFade', clickToClose: !(params || {}).sticky }, events)
			if (this.$store.state.main.mobile) setTimeout(fn, 100)
			else fn()
		}
	}
}

export const fullModalMixin = {
	methods: {
		showFullModal(Component, params) {
			const fn = () => this.$modal.show(Component, params, { width: '100%', height: '100%', classes: 'fullModal', transition: 'fullModalFade' })
			if (this.$store.state.main.mobile) setTimeout(fn, 100)
			else fn()
		}
	}
}

export const defaultModalAction = {
	props: ['doDefaultOnEnter'],
	methods: {
		onKeyUp(e) {
			if (e.which === 13 && this.isTop() && this.doDefaultOnEnter !== false) this.doDefault()
		},
		isTop() {
			return !this.$el.closest('.vm--container').nextSibling	
		},
		doDefault() {
			this.$emit('doDefault')
		}
	},
	mounted() {
		window.addEventListener('keyup', this.onKeyUp)
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.onKeyUp)
	}
}