import Cookies from 'js-cookie'
import Vue from 'vue'
import { set, push, pop, toggle, getKey, setKey, setByKey, unset, reset, mapToMutation, accumulate } from '@/store/helpers'

const state = profileId => ({
	on: Cookies.getJSON(`${profileId}-bulkParametersOn`) || false,
	parameters: {},
	parametersList: [],
	fresh: false
})

const getters = {
	getParameters: state => state.parametersList.map(key => Object.assign({}, state.parameters[key])),
	getParametersForCopy: state => {
		const parameters = []
		state.parametersList.forEach(key => {
			const { title, value } = state.parameters[key]
			if (title.length && value.length) parameters.push({ title, value })
		})
		return parameters
	}
}

const mutations = {
	toggleOn: toggle('on'),
	setParametersList: set('parametersList'),
	addParameterToList: push('parametersList'),
	removeParameterFromList: pop('parametersList'),

	setParameter: setByKey('parameters'),
	setParameterTitle: setByKey('parameters', 'title'),
	setParameterValue: setByKey('parameters', 'value'),
	unsetParameter: unset('parameters'),
	setParameters: set('parameters'),

	setFresh: set('fresh'),
	reset: reset(state()),

	clearParameters: state => Object.keys(state.parameters).forEach(key => Vue.set(state.parameters[key], 'value', ''))
}

const actions = {
	/* api */
	async savePreset({ state, commit, getters, dispatch }) {
		if (state.fresh) {
			await dispatch('bulkParameterPresets/save', getters.getParametersForCopy, { root: true })
			commit('setFresh', false)
		}
	},

	/* modifiers */
	resetParameters({ commit }, parameters) {
		const parsed = accumulate(setKey(parameters))
		commit('setParametersList', [])
		commit('setParameters', parsed)
		commit('setParametersList', Object.keys(parsed))
		commit('setFresh', false)
	},
	createParameter({ commit }) {
		const key = getKey()
		const parameter = {
			key,
			title: '',
			value: ''
		}
		commit('setParameter', {
			key,
			value: parameter
		})
		commit('addParameterToList', key)
	},
	deleteParameter({ commit, dispatch }, parameterKey) {
		commit('removeParameterFromList', parameterKey)
		commit('unsetParameter', parameterKey)
		dispatch('setFresh')
	},
	clearParameters({ commit }) {
		commit('clearParameters')
		commit('setFresh', false)
	},
	setParameterTitle({ commit, dispatch }, payload) {
		commit('setParameterTitle', payload)
		dispatch('setFresh')
	},
	setParameterValue({ commit, dispatch }, payload) {
		commit('setParameterValue', payload)
		dispatch('setFresh')
	},
	setFresh({ commit, getters }) {
		if (getters.getParametersForCopy.length) commit('setFresh', true)
		else commit('setFresh', false)
	},
	toggleOn({ state, commit, rootState }) { 
		commit('toggleOn')
		Cookies.set(`${rootState.profile.user.id}-bulkParametersOn`, state.on)
	},
	setParametersList: mapToMutation('setParametersList')
}

export const bulkParameters = profileId => ({
	namespaced: true,
	state: state(profileId),
	getters,
	mutations,
	actions
})