import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/'
	},
	post: {
		save: '/'
	},
	put: {
		moveToLeftOf: '/${id}/toLeftOf/${targetId}',
		moveToRightOf: '/${id}/toRightOf/${targetId}',
	},
	delete: {
		destroy: '/${id}'
	}
}

export const bulkParametersApi = buildApi('bulkParameters', routes, '/clinician/parameters')

