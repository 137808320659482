const wm = new WeakMap()

const onTouchstart = e => {
	const { clientX, clientY } = e.touches[0] 
	const data = wm.get(e.currentTarget)
	data.x1 = clientX 
	data.y1 = clientY
	data.timer = setTimeout(() => {
		if (
			Math.abs(data.x1 - (data.x2 || data.x1)) <= 5 &&
			Math.abs(data.y1 - (data.y2 || data.y1)) <= 5
		) data.fn(e)		
	}, 250)
}

const onTouchmove = e => {
	const { clientX, clientY } = e.touches[0] 
	const data = wm.get(e.currentTarget)
	data.x2 = clientX
	data.y2 = clientY
}

const reset = e => {
	const data = wm.get(e.currentTarget)
	clearTimeout(data.timer)
	data.x1 = null
	data.x2 = null
	data.y1 = null
	data.y2 = null
}

const onTouchend = e => reset(e)

const onTouchcancel = e => reset(e)

const bind = (el, fn) => {
	const data = { fn }
	wm.set(el, data)
	el.addEventListener('touchstart', onTouchstart, { passive: true })
	el.addEventListener('touchmove', onTouchmove, { passive: true })
	el.addEventListener('touchend', onTouchend)
	el.addEventListener('touchcancel', onTouchcancel)
}

const unbind = el => {
	el.removeEventListener('touchstart', onTouchstart)
	el.removeEventListener('touchmove', onTouchmove)
	el.removeEventListener('touchend', onTouchend)
	el.removeEventListener('touchcancel', onTouchcancel)	
	wm.delete(el)
}

export const touchHold = {
	bind: function(el, binding) {
		unbind(el)
		bind(el, binding.value)
	},
	unbind: function(el) {
		unbind(el)
	}
}
