import axios from 'axios'
import { isPlainObject } from 'lodash'
import { fill } from '@/utilities'
import { diff, toAsync } from './helpers'
import { apiPath } from '@/configuration/settings'
import store from '@/store'

const CancelToken = axios.CancelToken

const getAxiosInstance = (baseUrl = '') => {
	return axios.create({
		baseURL: apiPath + baseUrl,
		responseType: 'json',
		withCredentials: true,
		transitional: {
			silentJSONParsing: true,
			forcedJSONParsing: true
		},	
		/*
		transformResponse: res => {
			if (res && res.status === 'success') return res.body
			else if (res && res.status === 'error') return res.error
			else return res
		}
	*/
	})
}

export class Api {
	constructor(name, routes, baseUrl) {
		this._axios = getAxiosInstance(baseUrl)
		this._routes = routes
		this._name = name
		this._cancelTokens = {}
	}
	isCancel(err) {
		return axios.isCancel(err)
	}
	async touch(handle, payload, race, timeout, silent) {
		const apiCall = this._name +'-'+ handle
		const options = { timeout }
		const [method, template] = this._getRoute(handle)
		if (!template) return false
		let route = template
		//console.log(handle)
		if (payload) {
			if (isPlainObject(payload)) {
				route = fill(template, payload).replace('/null', '')
				payload = diff(template, payload)
			}
			if (method === 'get') options.params = payload
		}
		if (race) {
			if (this._cancelTokens[handle]) this._cancelTokens[handle].cancel('Cancel Error')
			this._cancelTokens[handle] = CancelToken.source()
			options.cancelToken = this._cancelTokens[handle].token
		}
		let args 
		if (method === 'get' || method === 'delete') args = [route, options]
		else if (method === 'post' || method === 'put') args = [route, payload, options]

		const [err, result] = await toAsync(this._axios, method, args)



		//const [err, result] = await toAsync(this._axios[method](...args))
		if (err && (!silent || err.statusCode === 401)) store.dispatch('main/apiError', { apiCall, err }) 
		else if (!silent) store.dispatch('main/apiSuccess', { apiCall })
		return [err, result]
	}
	_getRoute(handle) {
		for (const method of Object.keys(this._routes)) {
			const route = this._routes[method][handle]
			if (route) return [method, route]
		}
		return [false, false]
	}
	_getName() {
		return this._name
	}
}

export const buildApi = (name, routes, baseUrl) => new Api(name, routes, baseUrl)


