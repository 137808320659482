import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/clinician/exercises/categories'
	}
}

export const categoriesApi = buildApi('categories', routes)

