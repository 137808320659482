import { isMobile } from '@/utilities'

const wm = new WeakMap()

const onTouchstart = e => {
	const el = e.currentTarget
	const { clientX, clientY } = e.touches[0] 
	const data = wm.get(el)
	data.alive = true
	data.x1 = clientX 
	data.y1 = clientY
	data.timer = setTimeout(() => data.alive = false, 200)
	el.classList.remove('ripple')
	setTimeout(() => el.classList.add('ripple'), 0)
}

const onTouchmove = e => {
	const { clientX, clientY } = e.touches[0] 
	const data = wm.get(e.currentTarget)
	data.x2 = clientX
	data.y2 = clientY
}

const reset = e => {
	const data = wm.get(e.currentTarget)
	data.alive = false
	data.x1 = null
	data.x2 = null
	data.y1 = null
	data.y2 = null
	clearTimeout(data.timer)
}

const onTouchend = e => {
	const data = wm.get(e.currentTarget)
	if (
		data.alive && 
		Math.abs(data.x1 - (data.x2 || data.x1)) <= 5 &&
		Math.abs(data.y1 - (data.y2 || data.y1)) <= 5 
	) data.fn(e)
	reset(e)
}

const onTouchcancel = e => reset(e)

const bind = (el, fn) => {
	if (isMobile()) {
		const data = { fn }
		wm.set(el, data)			
		el.addEventListener('touchstart', onTouchstart, { passive: true })
		el.addEventListener('touchmove', onTouchmove, { passive: true })
		el.addEventListener('touchend', onTouchend)
		el.addEventListener('touchcancel', onTouchcancel)
	} else {
		el.addEventListener('click', fn)
	}
}

const unbind = (el, fn) => {
	if (isMobile()) {
		el.removeEventListener('touchstart', onTouchstart)
		el.removeEventListener('touchmove', onTouchmove)
		el.removeEventListener('touchend', onTouchend)
		el.removeEventListener('touchcancel', onTouchcancel)
		wm.delete(el)
	} else {
		el.removeEventListener('click', fn)
	}
}

export const tap = {
	bind: function(el, binding) {
		unbind(el)
		bind(el, binding.value)
	},
	unbind: function(el, binding) {
		unbind(el, binding.value)
	}
}
