<template>
<div class='alertModal'>
	<div class='formContent'>
		<header>{{title}}</header>
		<div v-html='body' />
	</div>
	<div class='modalControls'>
		<div v-if='cancelLabel' class='textButton neutral'  @click.stop='$emit("close")'>{{this.cancelLabel}}</div>
		<div class='textButton' :class='cls || "neutral"' @click.stop='close'>{{this.label || $t("elements.buttons.ok")}}</div>
	</div>
</div>
</template>

<script>
export default {
	props: ['title', 'message', 'fn', 'sticky', 'label', 'cls', 'cancelLabel'],
	computed: {
		body() {
			if (Array.isArray(this.message)) {
				let html = ''
				this.message.forEach(m => html+=`<p>${m}</p>`)
				return html
			} else {
				return `<p>${this.message}</p>`
			}
		}
	},
	methods: {
		onKeyUp(e) {
			if (e.which === 13 && !this.sticky) this.close()
		},
		close() {
			if (typeof this.fn === 'function') this.fn()
			this.$emit("close")
		}
	},
	mounted() {
		window.addEventListener('keyup', this.onKeyUp)
	},
	beforeDestroy() {
		window.removeEventListener('keyup', this.onKeyUp)
	}
}
</script>

<style lang='scss'>
.alertModal {
	.formContent {
		padding: $size-gutter * 4;
		padding-top: $size-gutter * 2;
	}
	header {
		border-bottom: 1px solid $color-primary-accent;
		color: $color-primary-accent;
	}
	.modalControls {
		padding: 14px;
		> div { border-radius: 2px; }
	}
}
</style>