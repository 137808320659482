import { throttle } from 'lodash'

const unwatchers = []

const throttledPing = throttle(store => {
	if (store.getters['auth/isAuthenticated']) store.dispatch('auth/ping') 
}, 1000 * 60 * 2, { leading: true, trailing: false })


export const mountWatchers = store => {

	const watchers = [
		store => store.subscribe(
			({ type }) => {
				if (
					!type.includes('api') && 
					!type.includes('touch')
				) {
					throttledPing(store)
				}
			}
		)
	]

	if (store.state.profile.user.clinician) {

		watchers.push(

			store => store.subscribe(
				({ type, payload }) => {

				/* elements */

					if (
						[
							'elements/setTitle',
							'elements/setInstructions',
							'elements/setThumbnail',
							'elements/setExerciseParametersList',
							'elements/setExerciseResourcesList',
							'elements/addParameterToExercise',
							'elements/removeParameterFromExercise',
							'elements/addResourceToExercise',
							'elements/removeResourceFromExercise',
							'elements/toggleReflect'
						].includes(type)
					){
						store.dispatch('elements/setFresh', payload.key || payload)
					} else if (
						[
							'elements/setParameterTitle',
							'elements/setParameterValue'
						].includes(type)
					){
						const key = store.state.elements.parameters[payload.key].exerciseKey
						store.dispatch('elements/setFresh', key)
					} else if (
						[
							'elements/setResourceCaption'
						].includes(type)
					){
						const key = store.state.elements.resources[payload.key].exerciseKey
						store.dispatch('elements/setFresh', key)

				/* exercise browser */

					} else if (
						[
							'exerciseBrowser/setSelected',
							'exerciseBrowser/toggleSelected'
						].includes(type)
					){
						store.dispatch('setBuilder/clearSelected')
	
				/* set builder */

					} else if (
						[
							'setBuilder/setSelected',
							'setBuilder/toggleSelected'
						].includes(type)
					){
						store.dispatch('exerciseBrowser/clearSelected')

				/* sets */

					} else if (
						[
							'sets/setElementsList'
						].includes(type)
					){
						store.dispatch('sets/setFresh', payload.key)
					} else if (
						[
							'sets/setTitle',
							'sets/setEducation',
							'sets/setInstructions',
							'sets/removeElementsFromSet'
						].includes(type)
					){
						store.dispatch('sets/setFresh', payload.key)
					} else if (
						[
							'sets/pushElementsList'
						].includes(type)
					){
						store.dispatch('sets/setFresh', payload.key)
					//	store.dispatch('setBuilder/setFocus', payload.key)
					} else if (
						[
							'sets/setElementTitle',
							'sets/setElementInstructions',
							'sets/setExerciseParametersList',
							'sets/addParameterToExercise',
							'sets/removeParameterFromExercise',
							'sets/setExerciseResourcesList',
							'sets/addResourceToExercise',
							'sets/removeResourceFromExercise',
							'sets/toggleReflect',
							'sets/setThumbnail'
						].includes(type)
					){
						store.dispatch('sets/setFresh', store.state.sets.elements[payload.key || payload].setKey)
					} else if (
						[
							'sets/setParameterTitle',
							'sets/setParameterValue'
						].includes(type)
					){
						const exerciseKey = store.state.sets.parameters[payload.key].exerciseKey
						const setKey = store.state.sets.elements[exerciseKey].setKey
						store.dispatch('sets/setFresh', setKey)
					} else if (
						[
							'sets/setResourceCaption',
							'sets/setResourceId'
						].includes(type)
					){
						const exerciseKey = store.state.sets.resources[payload.key].exerciseKey
						const setKey = store.state.sets.elements[exerciseKey].setKey
						store.dispatch('sets/setFresh', setKey)

				/* clientSets */

					} else if (
						[
							'manageClientSets/setSavename'
						].includes(type)
					){
						const key = store.getters['sets/getKeyById'](payload.key)
						if (key) store.dispatch('sets/setSavename', { key, value: payload.value })

				/* headerFooters */

					} else if (
						[
							'headerFooters/setHeaderLine1',
							'headerFooters/setHeaderLine2',
							'headerFooters/setHeaderLine3',
							'headerFooters/setFooter'
						].includes(type)
					){
						store.dispatch('headerFooters/setFresh', payload.key)

				/* bus */

					} else if (
						[
							'bus/setContentType'
						].includes(type)
					){
						store.dispatch('setBuilder/clearSelected')
					}
				}
			),

			store => store.watch(
				state => state.profile.user.lang,
				() => {
					store.dispatch('exerciseBrowser/fetchTop')
				}
			)
			
		)

	}

	watchers.forEach(watcher => unwatchers.push(watcher(store)))

}

export const unmountWatchers = store => {
	unwatchers.forEach(unwatcher => unwatcher(store))
	unwatchers.length = 0
}



/*

import authenticatedWatchers from './watchers/authenticated'
import clinicianWatchers from './watchers/clinician'
import managerWatchers from './watchers/manager'
import adminWatchers from './watchers/admin'

const unwatchers = []

const getWatchers = store => {
	if (store.getters['auth/isClinician']) return clinicianWatchers.concat(authenticatedWatchers)
	else if (store.getters['auth/isManager']) return managerWatchers.concat(authenticatedWatchers)
	else if (store.getters['auth/isAdmin']) return adminWatchers.concat(authenticatedWatchers)
	else return false
}

export const mountWatchers = store => {
	const watchers = getWatchers(store)
	if (!watchers || unwatchers.length) return false
	watchers.forEach(watcher => unwatchers.push(watcher(store)))
}

export const unmountWatchers = store => {
	unwatchers.forEach(unwatcher => unwatcher(store))
	unwatchers.length = 0
}

*/