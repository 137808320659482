import { groupBy } from 'lodash'
import { pluck } from '@/utilities'
import { accumulate, collapse, combine, setKey } from '@/store/helpers'

export const isStack = type => ['userStack', 'teamStack', 'orgStack', 'linkedStack'].includes(type)
export const isExercise = type => ['stockExercise', 'userExercise', 'teamExercise', 'stackExercise', 'orgExercise', 'linkedExercise'].includes(type)

/* Parsers */

export const groupElementsByType = elements => {
	return Object.assign({
		stockExercise: [],
		userExercise: [],
		teamExercise: [],
		orgExercise: [],
		stackExercise: [],
		linkedExercise: [],
		userStack: [],
		teamStack: [],
		orgStack: [],
		linkedStack: []
	}, groupBy(elements, 'type'))
}

export const combineStacks = elements => {
	const groups = groupElementsByType(elements)
	return [
		...groups.userStack,
		...groups.teamStack,
		...groups.orgStack,
		...groups.linkedStack
	]
}

export const setStackKeyOnStackExercises = stacks => {
	stacks.map(stack => {
		if (stack.exercises) stack.exercises.map(exercise => exercise.stackKey = stack.id)
	})
	return stacks
}

export const setExerciseKeyOnParameters = exercises => {
	exercises.map(exercise => {
		if (exercise.parameters) exercise.parameters.map(parameter => parameter.exerciseKey = exercise.id)
	})
	return exercises
}

export const setExerciseKeyOnResources = exercises => {
	exercises.map(exercise => {
		if (exercise.resources) exercise.resources.map(resource => resource.exerciseKey = exercise.id)
	})
	return exercises
}

export const combineStackExercises = stacks => {
	return combine(setStackKeyOnStackExercises(stacks), 'exercises').map(exercise => {
		exercise.type = 'stackExercise'
		return exercise
	})
}

export const combineExercises = elements => {
	const groups = groupElementsByType(elements)
	return [
		...groups.stockExercise,
		...groups.userExercise,
		...groups.teamExercise,
		...groups.orgExercise,
		...groups.linkedExercise,
		...(groups.stackExercise.map(exercise => {
			if (exercise.stackId) exercise.stackKey = exercise.stackId
			return exercise
		})),
		...combineStackExercises(groups.userStack.concat(groups.teamStack).concat(groups.orgStack).concat(groups.linkedStack))
	]
}

export const markComplete = elements => {
	elements.forEach(element => element.complete = !!element.updated)
	return elements
}

export const parseNestedElements = elements => {
	if (!Array.isArray(elements)) elements = [elements]
	const stacks = combineStacks(elements)
	const exercises = setKey(combineExercises(elements), ['parameters', 'resources'])
	const root = markComplete(collapse(stacks, 'exercises').concat(collapse(exercises, ['resources', 'parameters'])))
	const resources = combine(setExerciseKeyOnResources(exercises), 'resources')
	const parameters = combine(setExerciseKeyOnParameters(exercises), 'parameters')
	return [
		accumulate(root),
		accumulate(resources),
		accumulate(parameters)
	]
}

/* Pickers */

const existingStackAttrs = payload => pluck(payload, ['id', 'title'])
const existingExerciseAttrs = payload => pluck(payload, ['id', 'title', 'instructions', 'reflect', 'thumbnailId'])
const existingResourceAttrs = payload => pluck(payload, ['id', 'resourceId', 'caption'])
const newStackAttrs = payload => pluck(payload, ['title'])
const newExerciseAttrs = payload => pluck(payload, ['title', 'instructions', 'reflect', 'thumbnailId'])
const newResourceAttrs = payload => pluck(payload, ['resourceId', 'caption'])
const parameterAttrs = payload => pluck(payload, ['title', 'value'])

const existingStack = {
	root: existingStackAttrs
}

const existingExercise = {
	root: existingExerciseAttrs,
	resources: existingResourceAttrs,
	parameters: parameterAttrs
}

const newStack = {
	root: newStackAttrs
}

const newExercise = {
	root: newExerciseAttrs,
	resources: newResourceAttrs,
	parameters: parameterAttrs
}

export const savePickers = {
	userExercise: existingExercise,
	teamExercise: existingExercise,
	orgExercise: existingExercise,
	stackExercise: existingExercise,
	stockExercise: newExercise,
	userStack: existingStack,
	teamStack: existingStack,
	orgStack: existingStack
}

export const copyPickers = {
	userExercise: newExercise,
	teamExercise: newExercise,
	orgExercise: newExercise,
	stackExercise: newExercise,
	stockExercise: newExercise,
	linkedExercise: newExercise,	
	userStack: newStack,
	teamStack: newStack,
	orgStack: newStack,
	linkedStack: newStack
}

const setExercise = {
	root: payload => {
		const o = pluck(payload, ['title', 'instructions', 'reflect', 'thumbnailId']) 
		if (payload.type==='stockExercise') o.stockId = payload.id 
		else if (!payload.fresh) o.customId = payload.lastHistoryId
		return o
	},
	resources: newResourceAttrs,
	parameters: parameterAttrs	
}

export const copyToSetPickers = {
	userExercise: setExercise,
	teamExercise: setExercise,
	orgExercise: setExercise,
	stackExercise: setExercise,
	stockExercise: setExercise,
	linkedExercise: setExercise
}
