import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetch: '/',
		uploadLogo: '/upload/logo'
	},
	post: {
		create: '/',
		saveHeader: '/header'
	},
	put: {
		update: '/${id}',
		moveLeft: '/${teamId}/toLeftOf/${targetId}',
		moveRight: '/${teamId}/toRightOf/${targetId}'		
	},
	delete: {
		destroy: '/${id}',
		unsetLogo: '/${id}/logo'
	}
}

export const teamsApi = buildApi('teams', routes, '/manager/teams')

