<template>
<input
	type='text'
	class='textInput'
	ref='textInput'
	autocomplete='nopefish'
	autocapitalize='none'
	autocorrect='off'		
	@blur='onBlur'
	@focus='onFocus'
	@input='onInput'
	@keydown='onKeyDown'
	@keyup.enter='onSubmit'
	@keyup='v=>$emit("keyup", v)'
	@paste='onPaste'
	:placeholder='placeholder'
	:maxlength='maxlength'
	:disabled='disabled'
	v-model='innerValue'
/>
</template>

<script>
import { escape, unescape } from 'lodash'
import { isMobile } from '@/utilities'

export default {
	name: 'TextInput',
	props: ['value', 'placeholder', 'maxlength', 'disabled', 'htmlEntities', 'numeric'],
	data() { return {
		innerValue: this.htmlEntities ? unescape(this.value) : this.value,
		focussed: false
	}},
	methods: {
		onBlur() {
			this.focussed = false
			if (isMobile() && 'activeElement' in document) document.activeElement.blur()
			this.$emit('blur')
		},
		onFocus() {
			this.focussed = true
			this.$emit('focus')
		},
		onKeyDown(e) {
			if (
				!e.metaKey && !e.ctrlKey && (
					(!this.htmlEntities && ['<', '>'].includes(e.key)) ||
					(this.numeric && e.key != +e.key && !['ArrowLeft','ArrowRight','Backspace','Tab'].includes(e.key))
				)
			) {
				e.preventDefault()
				return false
			}
			this.$emit('keydown', e)
		},
		onPaste(e) {
			//console.log('oh hai!')
			const text = e.clipboardData.getData('text')
			if (
				(!this.htmlEntities && (text.includes('<') || text.includes('>'))) ||
				(this.numeric && text != +text)
			) {
				e.preventDefault()
				return false				
			}
			
		},
/*
		onInput: throttle(function() {
			if (this.active) this.emitInput()
		}, 250, { leading: false, trailing: true }),
*/
		onInput() {
			if (this.active) this.emitInput()
		},
		onSubmit(e) {
			this.emitInput()
			this.$emit('submit', e)
		},
		focus() {
			this.$refs.textInput.focus()
			this.onFocus()
		},
		select() {
			this.$refs.textInput.select()
		},		
		blur() {
			this.$refs.textInput.blur()
			this.onBlur()
		},
		emitInput() {
			let v
			if (this.numeric) {
				v = this.innerValue ? +this.innerValue : this.innerValue
			} else if (this.htmlEntities) {
				v = escape(this.innerValue.trim())
			} else {
				v = this.innerValue.trim()
			}
			this.$emit('input', v)
		}
	},
	watch: {
		value(value) {
			if (!this.focussed) this.innerValue = this.htmlEntities ? unescape(value) : value
		}
	},
	mounted() { this.active = true },
	beforeDestroy() { this.active = false }
}
</script>

<style lang='scss'>
.textInput {
	width: 100%;
	height: $size-control-height;
	background: $color-white;
	padding-left: $size-gutter * 2;
	padding-right: $size-gutter * 2;
	text-overflow: ellipsis;
}
</style>

