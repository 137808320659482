import { set, reset, mapToMutation } from '@/store/helpers'
import { listState, listGetters, listMutations, listActions } from '@/store/modules/mixins/list'
import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { clientsApi } from '@/services/api/modules/clinician/clients'

const callClientsApi = buildApiCaller(clientsApi)

/* state */

const state = () => ({
	...listState,
	...apiState,
	shared: false,	
	detail: null,
	teamId: null,
	max: 0
})

/* getters */

const getters = {
	...listGetters,
	topPageMeta: (state, getters, rootState) => {
		const o = {
			skip: 0,
			limit: state.limit,
			search: state.search,
			sort: state.sortColumn,
			sortDir: state.sortDirection,
			archived: false,
			//teamIds: state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients) ? Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",") : undefined
			//teamIds: state.teamId || undefined //state.teamId.length ? state.teamIds.join(',') : undefined
		}
		if (state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients)) {
			const teamIds = Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",")
			if (teamIds) o.teamIds = teamIds
		}
		return o
	},
	nextPageMeta: (state, getters, rootState) => {
		const o = {
			skip: state.list.length,
			limit: state.limit,
			search: state.search,
			sort: state.sortColumn,
			sortDir: state.sortDirection,
			archived: false,
		//	teamIds: state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients) ? Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",") : undefined
		}
		if (state.search && rootState.profile.teams && Object.values(rootState.profile.teams).filter(t=>t.teamClients)) {
			const teamIds = Object.values(rootState.profile.teams).filter(t=>t.teamClients).map(t=>t.id).join(",")
			if (teamIds) o.teamIds = teamIds
		}
		return o
	},
	detail: (state, getters, rootState) => rootState.clients[state.detail],
	selected: state => key => state.detail === key
}

/* mutations */

const mutations = {
	...listMutations,
	...apiMutations,
	setDetail: set('detail'),
	setShared: set('shared'),	
	setTeamId: set('teamId'),
	setMax: (state, value) => state.max = value,
	toggleTeamId: (state, teamId) => {
		if (state.teamId === teamId) state.teamId = null
		else state.teamId = teamId
	},
	reset: reset(state())
}

/* actions */

const fetch = async (state, commit, payload) => await callClientsApi({ commit, route: 'fetchClients', payload, race: true })

const fetchTop = async ({ state, commit, dispatch }, payload) => {
	const [err, result] = await fetch(state, commit, payload)
	if (!err) {
		commit('resetList')
		commit('setMax', result.max)
		const keys = await dispatch('clients/parseOver', result.items, { root: true })
		const meta = { total: result.total, search: state.search }
		return [null, { meta, keys }]
	} else {
		return [err]
	}
}

const fetchNext = async ({ state, commit, dispatch }, payload) => {
	const [err, result] = await fetch(state, commit, payload)
	if (!err) {
		const keys = await dispatch('clients/parseOver', result.items, { root: true })
		return [null, { keys }]
	} else {
		return [err]
	}
}

const actions = {
	...listActions(fetchTop, fetchNext),

	setSelected({ commit }, key) {
		commit('setDetail', key)
	},	

	setShared: mapToMutation('setShared'),
	reset: mapToMutation('reset')
}

export const saveClients = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})