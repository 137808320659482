import Vue from 'vue'
import Router from 'vue-router'
import multiguard from 'vue-router-multiguard'
import store from '@/store'
import { simpleState } from '@/store/helpers'
import { profileApi } from '@/services/api/modules/profile'
import { UI } from '@/utilities'

import Login from '@/views/Login'

// Clinician
const Clinician = () => import( /* webpackChunkName: "group-clinician" */ '@/views/Clinician')
const Designer = () => import( /* webpackChunkName: "group-clinician-designer" */ '@/components/clinician/designer/Designer')
const ExerciseBrowser = () => import( /* webpackChunkName: "group-clinician-designer" */ '@/components/clinician/designer/exerciseBrowser/ExerciseBrowser')
const SetBuilder = () => import( /* webpackChunkName: "group-clinician-designer" */ '@/components/clinician/designer/setBuilder/SetBuilder')
const ClientsManager = () => import( /* webpackChunkName: "group-clinician-clients" */ '@/components/clinician/clients/ClientsManager')
const ClinicianSettings = () => import( /* webpackChunkName: "group-clinician-settings" */ '@/components/clinician/settings/Settings')
const SettingsSecurity = () => import( /* webpackChunkName: "group-clinician-settings" */ '@/components/clinician/settings/Security')
const SettingsHeaderFooters = () => import( /* webpackChunkName: "group-clinician-settings" */ '@/components/clinician/settings/HeaderFooters')
const SettingsEmail = () => import( /* webpackChunkName: "group-clinician-settings" */ '@/components/clinician/settings/Email')
const SettingsIntegrations = () => import( /* webpackChunkName: "group-clinician-settings" */ '@/components/clinician/settings/Integrations')
const ClinicianSettingsBilling = () => import( /* webpackChunkName: "group-clinician-settings" */ '@/components/clinician/settings/Billing')
const EducationManager = () => import( /* webpackChunkName: "group-clinician-education" */ '@/components/clinician/education/Education')
const OutcomesManager = () => import( /* webpackChunkName: "group-clinician-outcomes" */ '@/components/clinician/outcomes/Outcomes')
const TemplatesManager = () => import( /* webpackChunkName: "group-clinician-templates" */ '@/components/clinician/templates/Templates')

// Manager
const Manager = () => import( /* webpackChunkName: "group-manager" */ '@/views/Manager')
const TeamsManager = () => import( /* webpackChunkName: "group-manager-teams" */ '@/components/manager/teams/TeamsManager')
const StaffManager = () => import( /* webpackChunkName: "group-manager-staff" */ '@/components/manager/staff/StaffManager')
//const ApiManager = () => import( /* webpackChunkName: "group-manager-api" */ '@/components/manager/api/ApiManager')
const UamManager = () => import( /* webpackChunkName: "group-manager-uam" */ '@/components/manager/uam/UamManager')
const ManagerSettings = () => import( /* webpackChunkName: "group-manager-settings" */ '@/components/manager/settings/Settings')
const ManagerSettingsBilling = () => import( /* webpackChunkName: "group-manager-settings" */ '@/components/manager/settings/Billing')
const LinksManager = () => import( /* webpackChunkName: "group-manager-links" */ '@/components/manager/links/LinksManager')

// Shared
const SettingsPersonal = () => import( /* webpackChunkName: "group-settings" */ '@/components/clinician/settings/Personal')
const SettingsClinic = () => import( /* webpackChunkName: "group-settings" */ '@/components/clinician/settings/Clinic')
const SettingsPassword = () => import( /* webpackChunkName: "group-settings" */ '@/components/clinician/settings/Password')

Vue.use(Router)

let errCount = 0

const isClinician = async (to, from, next) => {
	if (store.state.profile.user.manager && !store.state.profile.user.clinician) return next({ name: 'getLanding', replace: true })		
	const [err, result] = await profileApi.touch('fetch')
	if (
		!err &&
		store.state.profile && 
		result.user.id === store.state.profile.user.id && 
		store.state.profile.user.clinician
	) {
		errCount = 0
		await store.dispatch('profile/init', result)
		if (store.state.profile.user.manager) {
			if (result.organization.closed && result.organization.selfServe) {
				return next({ name: 'teams', replace: true })		
			}
			const ui = new UI(store.state.profile.user.id)
			ui.set('lastIn', 'clinician')
		}
		next()
	} else {
		store.commit('auth/reset')
		store.commit('profile/reset')
		store.commit('main/reset')
		redirect = errCount >= 3 ? false : window.location.hash.substring(1) // to.path // don't trust to.path, it isn't always right
		errCount++
		next({ name: 'getLanding', replace: true })		
	}	
}
const isManager = async (to, from, next) => {
	if (!store.state.profile.user.manager && store.state.profile.user.clinician) return next({ name: 'getLanding', replace: true })			
	const [err, result] = await profileApi.touch('fetch')
	if (
		!err &&
		store.state.profile && 
		result.user.id === store.state.profile.user.id && 
		store.state.profile.user.manager
	) {
		errCount = 0
		await store.dispatch('profile/init', result)
		if (store.state.profile.user.clinician) {
			const ui = new UI(store.state.profile.user.id)
			ui.set('lastIn', 'manager')
		}
		next()
	} else {
		store.commit('auth/reset')
		store.commit('profile/reset')		
		store.commit('main/reset')		
		redirect = errCount >= 3 ? false : window.location.hash.substring(1) // to.path
		errCount++
		next({ name: 'getLanding', replace: true })		
	}		
}

const isLoggedOut = (to, from, next) => !store.state.auth.authenticated ? next() : next({ name: 'getLanding', replace: true })

const setLang = (to, from, next) => {
	if (to.params.lang) store.dispatch('main/setLang', to.params.lang).then(next)
	else return next()
}

const tryBootstrap = (to, from, next) => {
	if (from.fullPath === '/' && !store.state.auth.swapping) { 
		store.dispatch('auth/bootstrap').then(result => {
			if (!result) return next()
			else return next(false)
		})
	} else {
		return next()
	}
}

let redirect
const bookmarkRedirect = (to, from, next) => {
	//console.log('from', from)
	//console.log('to', to)
	//console.log(window.location.hash)
	if (!store.state.auth.authenticated) redirect = window.location.hash.substring(1)  //to.path
	return next()
}

const getLanding = (args) => {
	if (args && args.path && args.path.includes('scolibalance')) {
		store.commit('main/markScolibalance')
	}
	if (store.state.auth.authenticated && redirect && redirect!=='/login') {
		const path = { path: redirect }
		redirect = false
		return path
	} else if (store.state.auth.authenticated) {
		if (store.state.profile.user.resetPassword) {
			if (store.state.profile.user.clinician) return { name: 'clinicianSettingsPassword' }
			else if (store.state.profile.user.manager) return { name: 'managerSettingsPassword' }
		} else if (store.state.profile.user.manager && store.state.profile.user.clinician) {
			const ui = new UI(store.state.profile.user.id)
			const lastIn = ui.get('lastIn')
			if (
				lastIn && 
				!store.state.profile.user.closed
			) return { name: lastIn }
			else if (
				store.state.profile.user.closed ||
				!Object.keys(store.state.profile.teams).length
			) return { name: 'manager' }
			else return { name: 'clinician' }
		} else if (store.state.profile.user.clinician) {
			return { name: 'clinician' }
		} else if (store.state.profile.user.manager) {
			return { name: 'manager' }
		} else {
			return { name: 'login' }
		}
	} else {
		return { name: 'login' }
	}
}

const router = new Router({
	routes: [{
		name: 'login',
		path: '/login/:lang?',
		component: Login,
		beforeEnter: multiguard([isLoggedOut, tryBootstrap, setLang])
	}, {
		path: '/manager',
		component: Manager,
		beforeEnter: multiguard([bookmarkRedirect, isManager]),
		children: [{
			name: 'teams',
			path: 'teams',
			component: TeamsManager
		}, {
			name: 'staff',
			path: 'staff',
			component: StaffManager
/*
		}, {
			name: 'api',
			path: 'api',
			component: ApiManager
*/
		}, {
			name: 'uam',
			path: 'uam',
			component: UamManager
		}, {
			name: 'links',
			path: 'links',
			component: LinksManager
		}, {
			path: 'settings',
			component: ManagerSettings,
			children: [{
				name: 'managerSettingsPersonal',
				path: 'personal',
				component: SettingsPersonal
			}, {
				name: 'managerSettingsClinic',
				path: 'clinic',
				component: SettingsClinic
			}, {
				name: 'managerSettingsPassword',
				path: 'password',
				component: SettingsPassword
			}, {
				name: 'managerSettingsSecurity',
				path: 'security',
				component: SettingsSecurity
			}, {
				name: 'managerSettingsBilling',
				path: 'billing',
				component: ManagerSettingsBilling
			}, {
				name: 'managerSettings',
				path: '',
				redirect: () => simpleState.settingsPath || 'personal'					
			}]
		}, {
			name: 'manager',
			path: '',
			redirect: 'teams'
		}]
	}, {
		path: '/clinician',
		component: Clinician,
		beforeEnter: multiguard([bookmarkRedirect, isClinician]),
		children: [{
			path: 'designer',
			component: Designer,
			children: [{
				path: 'exerciseBrowser/:tab/:folderId?/setBuilder/:setIds?',
				components: {
					exerciseBrowser: ExerciseBrowser,
					setBuilder: SetBuilder
				},
			}, {
				name: 'designer',
				path: '',
				redirect: () => {
					const tab = store.state.exerciseBrowser.tab || 'stock'
					const setIds = store.getters['setBuilder/router']
					return `exerciseBrowser/${tab}/setBuilder/${setIds || ''}`
				}
			}]
		}, {
			name: 'templates',
			path: 'templates',
			component: TemplatesManager
		}, {			
			name: 'clients',
			path: 'clients/:clientId?/:linked?',
			component: ClientsManager
		}, {
			name: 'education',
			path: 'education/:educationId?',
			component: EducationManager
		}, {
			name: 'outcomes',
			path: 'outcomes/:outcomeId?',
			component: OutcomesManager
		}, {		
			path: 'settings',
			component: ClinicianSettings,
			children: [{
				name: 'clinicianSettingsPersonal',
				path: 'personal',
				component: SettingsPersonal
			}, {
				name: 'clinicianSettingsClinic',
				path: 'clinic',
				component: SettingsClinic
			}, {
				name: 'clinicianSettingsPassword',
				path: 'password',
				component: SettingsPassword
			}, {
				name: 'clinicianSettingsSecurity',
				path: 'security',
				component: SettingsSecurity
			}, {
				name: 'clinicianSettingsHeaders',
				path: 'headers',
				component: SettingsHeaderFooters
			}, {
				name: 'clinicianSettingsEmail',
				path: 'email',
				component: SettingsEmail
			}, {
				name: 'clinicianSettingsBilling',
				path: 'billing',
				component: ClinicianSettingsBilling
			}, {
				name: 'clinicianSettingsIntegrations',
				path: 'integrations',
				component: SettingsIntegrations
			},{
				name: 'clinicianSettings',
				path: '',
				redirect: () => simpleState.settingsPath || 'personal'					
			}]
		}, {
			name: 'clinician',
			path: '',
			redirect: 'designer'
		}]
	}, {
		name: 'getLanding',
		path: '',
		redirect: getLanding
	}, {
		path: '*',
		redirect: { name: 'getLanding' }
	}],
	linkActiveClass: 'selected'
})

export default router