import { pluck } from '@/utilities'
import { accumulate, collapse, combine, setKey } from '@/store/helpers'

const key2Cid = payload => {
	if (Array.isArray(payload)) {
		return payload.map(obj => key2Cid(obj))
	} else {
		if (payload.key) payload.cid = payload.key
		delete payload.key
		return payload
	}
}

const cid2Key = payload => {
	if (Array.isArray(payload)) payload.map(cid2Key)
	else if (typeof payload === 'object' && payload !== null) Object.keys(payload).map(key => {
		if (key === 'cid') {
			payload.key = payload.cid
			delete payload.cid
		} else if (typeof payload[key] === 'object') {
			cid2Key(payload[key])
		}
	})
}

const getOrig = e => {
	const o = {
		thumbnailId: e.thumbnailId,
		title: e.title,
		instructions: e.instructions,
		resources: e.resources.map(r=>({ resourceId: r.resourceId, caption: r.caption }))
	}
	return JSON.stringify(o)
}

/* Parsers */

export const setRootKeyOnElements = sets => {
	sets.map(set => set.elements.map(element => element.setKey = set.key))
	return sets
}

export const setExerciseKeyOnParameters = exercises => {
	exercises.map(exercise => exercise.parameters.map(parameter => parameter.exerciseKey = exercise.key))
	return exercises
}

export const setExerciseKeyOnResources = exercises => {
	exercises.map(exercise => exercise.resources.map(resource => resource.exerciseKey = exercise.key))
	return exercises
}

export const setRestored = sets => sets.map(set => {
	set.restored = !!set.draftId
	set.fresh = set.fresh || set.restored
	return set
})

export const anonymizeSet = set => {
	delete set.id
	delete set.draftId  
	delete set.folderId
	delete set.clientId
	delete set.type
	delete set.updated	
	set.elements.forEach(element => {
		delete element.id
		delete element.draftId  
		if (element.type === 'setExercise') {
			element.resources.forEach(resource => {
				delete resource.id 
				delete resource.draftId
			})
		}
	})	
}

export const parseNestedSets = nestedSets => {
	nestedSets.forEach(set => {
		if (set.readOnly) {
			anonymizeSet(set)
			set.fresh = false
		} else if (set.draftId) {
			set.fresh = true
		} else {
			set.fresh = false
		}
		delete set.readOnly
	})

	cid2Key(nestedSets)
	setKey(nestedSets)
	setKey(nestedSets, ['elements'])
	//setRestored(nestedSets)
	const sets = collapse(nestedSets, ['elements'])
	const nestedElements = combine(setRootKeyOnElements(nestedSets), ['elements'])
	setKey(nestedElements, ['resources', 'parameters'])
	const nestedExercises = nestedElements.filter(element => (element.type === 'setExercise' || element.type === 'setExerciseRef' ))
	nestedExercises
		.forEach(element => {
			if (!element.orig && (element.stockId || element.customId)) {
				element.orig = getOrig(element)
			}
			if (element.type==='setExerciseRef') element.type='setExercise'
		})


	const resources = combine(setExerciseKeyOnResources(nestedExercises), 'resources')
	const parameters = combine(setExerciseKeyOnParameters(nestedExercises), 'parameters')
	const dividers = nestedElements.filter(element => element.type === 'setDivider')
	const elements = collapse(nestedExercises, ['resources', 'parameters']).concat(dividers)

	return [
		accumulate(sets),
		accumulate(elements),
		accumulate(resources),
		accumulate(parameters)
	]
}

export const parseFetchedDrafts = sets => {
	sets.forEach(set => {
		if (set.type === 'draftSet') {
			set.draftId = set.id 
			set.restored = true
			set.fresh = true
			if (set.originalId) {
				set.id = set.originalId 
				set.type = set.originalType 
				delete set.originalId 
				delete set.originalType
			} else {
				delete set.id
			}
			set.elements.forEach(element => {
				element.draftId = element.id 
				delete element.id 
				if (element.type === 'setExercise') {
					element.resources.forEach(resource => {
						resource.draftId = resource.id
						delete resource.id
					})
				}
			})
		}
	})
}

export const parseSetForSaveDraft = set => {
	if (set.draftId) {
		set.id = set.draftId 
		delete set.draftId
	} else if (set.id) {
		set.originalId = set.id 
		delete set.id
	}
	set.elements.forEach(element => {
		if (element.draftId) {
			element.id = element.draftId 
			delete element.draftId
		}
		if (element.type === 'setExercise') {
			element.resources.forEach(resource => {
				if (resource.draftId) {
					resource.id = resource.draftId 
					delete resource.draftId
				}
			})
		}
	})
}

export const parseSetForSave = set => {
	set.elements.forEach(element => {
		if (
			element.type === 'setExercise' &&
			(element.stockId || element.customId)
		) {
			if (element.orig === getOrig(element)) {
				element.type = 'setExerciseRef'
				delete element.thumbnailId 
				delete element.title 
				delete element.instructions
				delete element.resources
				if (element.id) {
					delete element.stockId
					delete element.customId 				
				}
			} else { // was a ref, now an exercise
				delete element.id 
				delete element.stockId
				delete element.customId 					
			}
		} else if (element.type === 'setExercise') {
			delete element.stockId
			delete element.customId 
		}
		delete element.orig
	})
}

export const parseSavedDraft = draft => {
	draft.draftId = draft.id 
	delete draft.id 
	draft.elements.forEach(element => {
		element.draftId = element.id 
		delete element.id 
		if (element.type === 'setExercise') {
			element.resources.forEach(resource => {
				resource.draftId = resource.id 
				delete resource.id 
			})
		}
	})
	cid2Key(draft)
	const set = pluck(collapse(draft, ['elements']), ['key', 'draftId'])
	set.draftSaved = Date.now()
	const nestedExercises = draft.elements.filter(element => (element.type === 'setExercise' || element.type === 'setExerciseRef' ))
	nestedExercises.forEach(e=> {
		if (e.type==='setExerciseRef') {
			e.type='setExercise'
			e.draftType='exerciseRef'
		} else {
			e.draftType='exercise'
		}
	})

	const resources = pluck(combine(nestedExercises, 'resources'), ['key', 'draftId'])
	const dividers = pluck(draft.elements.filter(element => element.type === 'setDivider'), ['key', 'draftId'])
	const elements = pluck(collapse(nestedExercises, ['resources', 'parameters']).concat(dividers), ['key', 'draftId', 'type', 'draftType'])

	return [
		accumulate(set),
		accumulate(elements),
		accumulate(resources)
	]
}

export const parseNestedElements = (nestedElements, key) => { // gets used when copying elements between exercise sets
	nestedElements.map(element => {
		element.setKey = key
		if (!element.created) element.created = Date.now()
	})
	setKey(nestedElements)
	setKey(nestedElements, ['resources', 'parameters'])
	const nestedExercises = nestedElements.filter(element => element.type === 'setExercise')
	nestedExercises
		.forEach(element => {
			if (!element.orig && (element.stockId || element.customId)) {
				element.orig = getOrig(element)
			}
		})



	const resources = combine(setExerciseKeyOnResources(nestedExercises), 'resources')
	const parameters = combine(setExerciseKeyOnParameters(nestedExercises), 'parameters')
	const dividers = nestedElements.filter(element => element.type === 'setDivider')
	const exercises = collapse(nestedExercises, ['resources', 'parameters'])
	const elements = nestedElements.map(element => {
		if (element.type === 'setExercise') return exercises.find(exercise => exercise.key === element.key)
		else if (element.type === 'setDivider') return dividers.find(divider => divider.key === element.key)
	})
	return [
		accumulate(elements),
		accumulate(resources),
		accumulate(parameters)
	]
}

const markReadOnlyFolderSets = (folders, sets, type) => {
	const folderIds = folders.filter(folder => folder.type === type).map(folder => folder.id)
	sets.map(set => {
		if (set.folderId && folderIds.includes(set.folderId)) set.readOnly = true
		return set
	})
	return sets	
}

export const markReadOnlyTeamFolderSets = (folders, sets) => markReadOnlyFolderSets(folders, sets, 'teamFolder')

export const markReadOnlyOrgFolderSets = (folders, sets) => markReadOnlyFolderSets(folders, sets, 'orgFolder')

export const markReadOnlyClientSets = (clients, sets) => {
	const archivedClientIds = clients.filter(client => client.archived).map(client => client.id)
	sets.map(set => {
		if (set.clientId) set.readOnly = archivedClientIds.includes(set.clientId)
		return set
	})
	return sets
}

/* Pickers */

const copyExerciseAttrs = payload => pluck(payload, ['type', 'stockId', 'customId', 'orig', 'title', 'instructions', 'reflect', 'thumbnailId'])
const copyResourceAttrs = payload => pluck(payload, ['resourceId', 'caption'])
const copyParameterAttrs = payload => pluck(payload, ['title', 'value'])
const copyDividerAttrs = payload => pluck(payload, ['type', 'title', 'instructions'])

const copyExercise = {
	exercise: copyExerciseAttrs,
	resource: copyResourceAttrs,
	parameter: copyParameterAttrs,
}

export const copyPickers = {
	exercise: copyExercise,
	divider: copyDividerAttrs
}

const saveParameterAttrs = payload => pluck(payload, ['title', 'value'])
const saveResourceAttrs = payload => key2Cid(pluck(payload, ['id', 'key', 'resourceId', 'caption'] ))
const saveExerciseAttrs = payload => key2Cid(pluck(payload, ['id', 'key', 'type', 'stockId', 'customId', 'orig', 'title', 'instructions', 'reflect', 'thumbnailId'] ))
const saveDividerAttrs = payload => key2Cid(pluck(payload, ['id', 'key', 'type', 'title', 'instructions'] ))
const saveSetAttrs = payload => {
	let attrs
	if (payload.id) attrs = ['id', 'key', 'title', 'instructions', 'savename']
	else attrs = ['id', 'key', 'draftId', 'title', 'instructions', 'savename']
	return key2Cid(pluck(payload, attrs))
}

export const savePickers = {
	set: saveSetAttrs,
	element: {
		exercise: {
			exercise: saveExerciseAttrs,
			resource: saveResourceAttrs,
			parameter: saveParameterAttrs
		},
		divider: saveDividerAttrs
	}
}

const draftSaveParameterAttrs = payload => pluck(payload, ['title', 'value'])
const draftSaveResourceAttrs = payload => key2Cid(pluck(payload, ['key', 'draftId', 'resourceId', 'caption'] ))
const draftSaveExerciseAttrs = payload => key2Cid(pluck(payload, ['key', 'draftId', 'type', 'stockId', 'customId', 'draftType', 'orig', 'title', 'instructions', 'reflect', 'thumbnailId'] ))
const draftSaveDividerAttrs = payload => key2Cid(pluck(payload, ['key', 'draftId', 'type', 'title', 'instructions'] ))
const draftSaveSetAttrs = payload => key2Cid(pluck(payload, ['id', 'key', 'draftId', 'title', 'instructions', 'savename']))

export const draftSavePickers = {
	set: draftSaveSetAttrs,
	element: {
		exercise: {
			exercise: draftSaveExerciseAttrs,
			resource: draftSaveResourceAttrs,
			parameter: draftSaveParameterAttrs
		},
		divider: draftSaveDividerAttrs
	}
}

const exportParameterAttrs = payload => pluck(payload, ['title', 'value']) //.filter(parameter => parameter.title && parameter.value)
const exportResourceAttrs = payload => pluck(payload, ['resourceId', 'caption'])
const exportExerciseAttrs = payload => pluck(payload, ['type', 'title', 'instructions', 'reflect', 'thumbnailId'])
const exportDividerAttrs = payload => pluck(payload, ['type', 'title', 'instructions'])
const exportSetAttrs = payload => pluck(payload, ['id', 'title', 'instructions'])

export const exportPickers = {
	set: exportSetAttrs,
	element: {
		exercise: {
			exercise: exportExerciseAttrs,
			resource: exportResourceAttrs,
			parameter: exportParameterAttrs
		},
		divider: exportDividerAttrs
	}
}

const dupExerciseAttrs = payload => pluck(payload, ['type', 'stockId', 'customId', 'orig', 'title', 'instructions', 'reflect', 'thumbnailId'])

export const dupPickers = {
	set: exportSetAttrs,
	element: {
		exercise: {
			exercise: dupExerciseAttrs,
			resource: exportResourceAttrs,
			parameter: exportParameterAttrs
		},
		divider: exportDividerAttrs
	}
}

const undoAttrs = payload => {
	if (Array.isArray(payload)) return payload.map(undoAttrs)
	else {
		/*eslint-disable */
		// having ids in the undo levels can cause problems
		// the alternative to this would be to manually remove them from the undo levels after a save is made
		// i don't know that it's worth it to bother worth that, simpler just to remove them here
		const { id, draftId, ...keepAttrs } = payload 
		/*eslint-enable */
		return keepAttrs
	}
}

export const undoPickers = {
	exercise: {
		exercise: undoAttrs,
		resource: undoAttrs
	},
	divider: undoAttrs
}
