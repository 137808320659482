import { accumulate, setKey } from '@/store/helpers'

export const parsePoints = ({ saveHistory, emailHistory }) => {
	if (!saveHistory) saveHistory = []
	if (!emailHistory) emailHistory = []
	saveHistory.forEach(point => point.type = 'save')
	emailHistory.forEach(point => point.type = 'email')
	const points = saveHistory
		.concat(emailHistory)
		.sort((a,b) => new Date(b.created) - new Date(a.created))
	return accumulate(setKey(points))
}

