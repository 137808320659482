import { apiState, apiMutations, buildApiCaller } from '@/store/modules/mixins/api'
import { setsApi } from '@/services/api/modules/clinician/sets'
import { set, spread, reset, mapToMutation, accumulate } from '@/store/helpers'

const callApi = buildApiCaller(setsApi)

const state = () => ({
	...apiState,
	list: [],
	sets: {},
	resources: {}
})

const getters = {
	previews: state => {
		return state.list.map(key => {
			const elements = state.sets[key] ? state.sets[key].elements.map(element => {
				const parsed = { type: element.type, title: element.title }
				if (element.type === 'setExercise' || element.type === 'setExerciseRef') {
					if (element.thumbnailId) parsed.thumbnail = state.resources[element.thumbnailId].url
					else if (element.resources.length && state.resources[element.resources[0].resourceId]) parsed.thumbnail = state.resources[element.resources[0].resourceId].url				
					parsed.parameters = element.parameters.filter(parameter => parameter.title && parameter.value)
				}
				return parsed
			}) : null
			return {
				key,
				elements
			}
		}).reverse()
	}
}

const mutations = {
	...apiMutations,
	setList: set('list'),
	resetList: set('list', []),
	spreadInSets: spread('sets'),
	spreadInResources: spread('resources'),
	reset: reset(state())
}

const actions = {
	
	async fetch({ commit, rootState }, keys) {
		const [err, result] = await callApi({
			commit,
			route: 'fetchIds',
			payload: { ids: keys, imgWidth: rootState.main.hiRes ? 120 : 60 }
		})
		if (!err) {
			const { items, resources } = result
		//	items.map(set => {
		//		console.log(set)
		//		if (set.draftId) set.key = set.draftId
		//	})			
			commit('spreadInSets', accumulate(items))
			commit('spreadInResources', accumulate(resources))
		}
	},

	async setList({ state, commit, dispatch }, keys) {
		if (keys && keys.length) {
			const diff = keys.filter(key => !state.sets[key])
			if (diff.length) await dispatch('fetch', diff)
			commit('setList', keys)
		} else {
			commit('resetList')
		}
	},

	reset: mapToMutation('reset')
}

export const setsPreview = () => ({
	namespaced: true,
	state: state(),
	getters,
	mutations,
	actions
})