<template>
<div class='smallModalInner' @click.stop>
	<div class='formContent' :style='`max-height: ${maxHeight}px`'><div>
		<header>
			<slot name='title' />
		</header>
		<slot name='message' />
	</div></div>
	<div class='modalControls'>
		<CancelButton v-if='showCancel' @click='$emit("close")' />
		<slot name='buttons' />
	</div>
</div>
</template>

<script>
import { defaultModalAction } from '@/components/common/mixins/modal'
import CancelButton from '@/components/common/buttons/Cancel'

export default {
	mixins: [defaultModalAction],
	props: {
		showCancel: { type: Boolean, default: true }
	},
	components: { CancelButton },
	data: () => ({
		maxHeight: 'auto'
	}),
	mounted() {
		this.maxHeight = window.innerHeight - 60
	}
}
</script>

<style lang='scss'>
.smallModal {

	.smallModalInner {
		display: grid;
		height: 100%;
		grid-template-rows: 1fr auto;
	}

	&::-webkit-scrollbar {
		display: block;
		width: $size-gutter * 2;
		cursor: move;
	}

	&::-webkit-scrollbar-track {}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px darken($color-neutral, 13%);
		border: solid 3px transparent;
		border-radius: $size-gutter;
	}	

	.formContent {
		padding: $size-gutter * 4;
		padding-top: $size-gutter * 2;
		overflow-y: scroll;
		overflow-x: hidden;

		> div > header {
			border-bottom: 1px solid $color-primary-accent;
			color: $color-primary-accent;
			margin-top: $size-gutter;
			margin-bottom: $size-gutter * 4;
		}

		p, ul {
			padding-bottom: $size-gutter * 2;
		}
	}

	.modalControls {
	}

}
</style>