import { reset, set, mapToMutation } from '@/store/helpers'
import { isMobile } from '@/utilities'

const validateLang = lang => ['en', 'fr'].includes(lang)

const state = {
	apiReturned: null,
	apiCall: null,
	apiSuccess: null,
	apiError: null,
	lang: 'en',
	bootstrap: {},
	hiRes: false,
	mobile: isMobile(),
	scolibalance: false
}

const getters = {
	activeLang: (state, getters, rootState) => rootState.profile ? rootState.profile.user.lang : state.lang
}

const mutations = {
	setBootstrap: set('bootstrap'),
	resetBootstrap: set('bootstrap', {}),
	setLang: set('lang'),
	setHiRes: set('hiRes'),
	reset: reset({
		apiReturned: null,
		apiCall: null,
		apiSuccess: null,
		apiError: null,
		lang: 'en',
		bootstrap: {},
		hiRes: false,
		mobile: isMobile()
	}),

	apiSuccess(state, { apiCall }) {
		state.apiCall = apiCall
		state.apiReturned = Date.now()
		state.apiSuccess = true
		state.apiError = null
	},

	apiError(state, { apiCall, err }) {
		state.apiCall = apiCall
		state.apiReturned = Date.now()
		state.apiSuccess = false
		//console.log(err)
		state.apiError = {
			status: err.status || err.statusCode || null,
			code: err.code || err.message || null,
			details: err.details || null
		}
	},

	markScolibalance(state) {
		state.scolibalance = true
	}
}

const actions = {
	setLang({ commit }, lang) {
		if (validateLang(lang)) commit('setLang', lang)
	},
	apiSuccess: mapToMutation('apiSuccess'),
	apiError: mapToMutation('apiError'),
	setBootstrap: mapToMutation('setBootstrap'),
	resetBootstrap: mapToMutation('resetBootstrap'),
	setHiRes: mapToMutation('setHiRes'),
	reset: mapToMutation('reset')
}

export const main = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}